import React from "react";
import { useNavigate } from "react-router-dom";

import {Box,Typography,Button} from '@mui/material'
import MapIcon from '@mui/icons-material/Map';

type ICarouselBox = {
    record: any,
    image: any
}

function CarouselLocationBox(props : ICarouselBox) {

    const { record, image } = props
    const navigate = useNavigate();

    const geometry = record.geom.getGeometry()
    const coordinates = geometry.getCoordinates()

    const wasteType = "Tip otpada: električni uređaji (metal, plastika)" // TEMPORARY
    const url = ""

    return (
        <Box className="unselectable" fontSize={18} fontWeight={300} display={"flex"} flexDirection={"column"}  sx={
            {
                color: 'white',
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundColor: "rgb(110, 110, 110)",
                backgroundPosition: 'center center',
                backgroundBlendMode: 'multiply',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
                height: "300px",
                marginX: 1,
                gap: "20px",
                minWidth:{
                    xs:"15rem",
                    sm:"20rem",
                    md:"26rem",
                    lg:"34rem",
                },
                marginTop:{
                    xs:1,
                    sm:2,
                    md:3,
                    lg:4,
                },
                padding:{
                    xs:2,
                    sm:3,
                    md:4,
                    lg:5,
                },
            }
        }>
            <Typography fontFamily={"Roboto slab"} fontWeight={700} fontSize={40} variant={"h3"} sx={{
                 fontSize: {
                    xs:"1.2rem",
                    sm:"1.2rem",
                    md:"1.3rem",
                    lg:"1.5rem",
                    xl:"1.5rem"
                    }
            }}>
                {record.lokacija}
            </Typography>
            <Box sx={{
                fontSize: {
                    xs:"0.9rem",
                    sm:"1rem",
                    md:"1.2rem",
                    lg:"1.2rem",
                },
                whiteSpace: "pre-wrap",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "100%",
            }}>
           
                <Typography fontWeight={400} variant="inherit" style={{marginTop:5}}>{record.tipovi_otpada ? `Tipovi otpada: ${record.tipovi_otpada}` : null}</Typography>
                <Button  variant="contained"
                        onClick={() => navigate(window.innerWidth <= 768 ? "/karta" : "/karta#legend", {
                            state: coordinates
                        })}
                        sx={{
                            backgroundColor:"#63D0CA",
                            color:"black",
                            marginTop: "auto",
                            "&:hover":{
                                backgroundColor:"#63D0CA",
                                color:"black",
                            },
                            width:"fit-content",
                            alignSelf:"center",
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.8rem",
                                md:"0.9rem",
                                lg:"1rem",
                                xl:"1.1rem"
                            },
                            fontFamily:"Roboto slab",
                        }}>
                        <MapIcon sx={{marginRight:2}} fontSize='large'   ></MapIcon>
                        Pogledaj na karti
                </Button>
            </Box>
        </Box>)
}

export default CarouselLocationBox;