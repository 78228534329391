import { IModel } from "../@types/models/model";
import { coreFields } from "./helpers/coreFields";

const DumpsiteReport: IModel = {
  title: "DumpsiteReport",
  apiPath: "maps/1/divlja-odlagalista",
  forms : {
    "default": {
      fields: ["author", "email", "note", "photos", "coords"]
    },
    "edit": {
      fields: ["author", "email", "note", "photos", "status_prijave", "datum_sanacije"],
    },
    "infoCard": {
      fields: ["author", "email"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "author", "email", "note", "status_prijave" ,"datum_prijave", "datum_sanacije"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Autor",
      source: "author",
      ttoken: "dump_report.author",
      type: "text",
      sort: true,
      filter: true,
      validation: {
        required: true,
        maxLength: 50
      },
    },
    {
      title: "Email",
      source: "email",
      ttoken: "dump_report.email",
      type: "text",
      validation: {
        required: true,
        maxLength: 50
      },
      sort: true,
      filter: true,
      // validation: {
      //   required: true
      // }
    },
    {
      title: "Biljeska",
      source: "note",
      ttoken: "dump_report.note",
      type: "multiline",
      rows: 7,
      maxRows: 10,
      validation: {
        required: true,
        maxLength: 2000
      }
    },
    {
      title: "geometry",
      source: "geom",
      ttoken: "common.objectid",
      type: "wkt",
      readonly: true
    },
    {
      title: "extent",
      source: "extent",
      ttoken: "common.objectid",
      type: "text",
      readonly: true
    },
    {
      title: "Photos",
      source: "photos",
      ttoken: "dump_report.photos",
      type: "images",
      maxFiles: 3
    },
    {
      title: "Coordinates",
      source: "coords",
      ttoken: "dump_report.coords",
      type: "text",
      readonly: true,
      validation: {
        required: true,
        maxLength: 100
      }
    },
    {
      title: "Datum prijave",
      source: "datum_prijave",
      ttoken: "dump_report.datum_prijave",
      type: "date",
      filter: true,
      // readonly: true
      validation: {
        required: true
      }
    },
    {
      title: "Datum sanacije",
      source: "datum_sanacije",
      ttoken: "dump_report.datum_sanacije",
      type: "date",
      filter: true,
      // readonly: true
      validation: {
        required: true
      }
    },
    {
      title: "Status prijave",
      source: "status_prijave",
      ttoken: "dump_report.status_prijave",
      type: "picker",
      filter: true,
      items: {
        labels: ["Prijavljeno", "U Obradi", "Sanirano", "Arhivirano"],
        values: ["prijavljeno", "u_obradi", "sanirano", "arhivirano"],
      }
      // readonly: true
    },
  ]
}

export default DumpsiteReport;

