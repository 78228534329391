import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// d.ts
import { RecordsData, ErrorData } from "@/@types/services/testService";
import { DataResponse, DCRecord } from "@/@types/lib/dataController";
import { Record } from "@/@types/lib/api/api";

// Material UI Core
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Custom Components
import ModelTable from "@/ui/Table/ModelTable";
import DialogContext from "@/ui/DialogContext/DialogContext";
import model from "@/models/news";
import dataController from "@/lib/dataController";
import NewsDialog from "./NewsDialog";
import TableButtonEdit from "@/ui/Table/ActionButtons/TableButtonEdit";
import TableButtonDelete from "@/ui/Table/ActionButtons/TableButtonDelete";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import { testService } from "@/services/testService";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import { ClosingDetails } from "@/@types/components/formController";

//Types
import { TestViewRedirectState } from "@/@types/views/TestPage/test";

function NewsTable() {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [records, setRecords] = useState<Array<DCRecord>>([]);

  const { t } = useTranslation();

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = () => {
    dialogContext.showDialog(NewsDialog, {
      dc: dc,
      mode: "insert",
      form: "default",
      onClose: handleDialogClose
    });
  };

  const handleEdit = (mouseClick:SyntheticEvent<Element, Event>, sel: string[]) => {
    const selId: number = parseInt(sel[0], 10);
    const record: DCRecord = records[selId];
    console.log(record);
    dialogContext.showDialog(NewsDialog, {
      dc: dc,
      mode: "update",
      form: "default",
      recordId: record.id,
      onClose: handleDialogClose
    });
  };
  
  const handleDialogClose = (result: ClosingDetails) => {
    if (result.dataChanged) {
      snackbarContext.showNotification("Uspješne izmjene.", "success");
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext?.toggleLoading(true);
    dc.GetData("news/admin")
      .then((resp: DataResponse) => {
        if (resp.success && resp.data && Array.isArray(resp.data)) {
          setRecords(resp.data);
        }
      })
      .finally(() => {
        loaderContext?.toggleLoading(false);
      });
  };

  const handleRowAction = function (action: string, id: number) {
    if (action === "edit") {
      navigate(`/news/${id}`, { state: { id: id } as TestViewRedirectState });
    }
  };

  const allowRowAction = function (action: string, id: number) {
    return true;
  };

  // const dummyRecords = () => {
  //   testService.getAll().then((resp: RecordsData) => {
  //     setRecords(resp as Array<DCRecord>);
  //   }).catch((err: ErrorData) => {
  //     console.error(err);
  //   })
  // }

  return (
    <Card>
      <CardContent>
        <ModelTable
          title={t("titles.news_table")}
          records={records}
          dc={dc}
          allowSelection="one"
          allowFilter={true}
          allowExport={true}
          allowAdd={true}
          addLabel={t("buttons.new_f")}
          handleAdd={handleAdd}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
        >
          <TableButtonEdit variant="contained" onClick={handleEdit} />
        </ModelTable>
      </CardContent>
    </Card>
  );
}

export default NewsTable;
