import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import { Button, Typography, Divider } from '@mui/material';

// Custom components
import { GridContainer, GridItem } from "@/ui/Grid";
import HtmlParser from "@/components/HtmlParser/HtmlParser"

function NewsArticlePreview(props: any) {
  const { record } = props
  const { t } = useTranslation();
  const navigate = useNavigate();

  const image = record.photos.length > 0 ? record.photos[0].content : null

  //RETURN
  //---------------------------------------------------------------------------------------------------

  return (
      <>
        <GridContainer style={{ marginBottom: "10px" }}>
          <GridItem lg={4}>
            <img
              src={`data:image/jpeg;base64, ${image ? image : ""}`}
              alt={image ? image[0].name : ""}
              style={{ maxHeight: "200px", maxWidth: "100%"}}
            />
          </GridItem>
          <GridItem lg={8}>
            {/* <Typography variant="subtitle1">
              {moment.utc(record.datum_i_vrijeme).format(formats.date)}
            </Typography> */}
            <Typography component="h2" variant="h3" color="primary" sx={{fontSize: "1.5rem", mb: 2}}>
              <HtmlParser htmlString={record.title} title={true} />
            </Typography>
            <Typography variant="body1" paragraph={true}>
              {
                record.content ? record.content.replace("&nbsp;", ' ').replace(/<(".*?"|'.*?'|[^'">])*?>/g, ' ').substring(0, 250) + "..." : ""
              }
            </Typography>
            <Button color="primary" variant="contained" onClick={() => navigate("/novosti/" + record.id)}>
              {t("buttons.read_more")}
            </Button>
          </GridItem>
        </GridContainer>
        <Divider sx={{ mb: 1}} />
      </>
    );
}

export default NewsArticlePreview