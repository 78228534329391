import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, Divider, Pagination } from '@mui/material';

// Contexts
import LoaderContext from "@/components/LoaderContext/LoaderContext";

// Custom components
import dataController from "@/lib/dataController";
import NewsArticlePreview from "@/views/NewsPage/NewsArticlePreview"
import PublicPage from "@/ui/PublicPage/PublicPage";
import api from "@/lib/api";

// Models
import modelNews from "@/models/news";

// Types
import { DataResponse, DCRecord } from "@/@types/lib/dataController";
import { Moment } from 'moment';


const LIMIT = 3;
function NewsPage() {
  const { t } = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const dc = new dataController(modelNews);
  const apiInstance = new api();

  // STATES
  //---------------------------------------------------------------------------------------------------

  const [records, setRecords] = useState<Array<DCRecord>>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  // FUNCTIONS
  //---------------------------------------------------------------------------------------------------

  function getTotalActiveNews() {
    return apiInstance.Call("news/total", "get")
      .then(resp => {
        // @ts-ignore
        const total = resp.data.news_count
        setCount(Math.ceil(total / LIMIT)); 
      });
  }

  const refreshRecords = (pageNumber: number) => {
  const offset = (pageNumber - 1) * LIMIT;

  loaderContext?.toggleLoading(true);
  dc.GetData(`news/?limit=${LIMIT}&offset=${offset}`)
    .then((resp: DataResponse) => {
      if (resp.success && resp.data && Array.isArray(resp.data)) {
        console.log(resp.data);
        const publishedNews = resp.data.filter(news => (news.date_time as Moment).toDate() < new Date() )
        console.log(publishedNews);
        setRecords(publishedNews);
      }
    })
    .finally(() => {
      loaderContext?.toggleLoading(false);
    });
};

  // EFFECTS
  //---------------------------------------------------------------------------------------------------

  useEffect(() => {
    getTotalActiveNews()
  }, [])

  useEffect(() => {
    refreshRecords(page);
  }, []);

  //RETURN
  //---------------------------------------------------------------------------------------------------

  return (
    <PublicPage contained>
      <Box sx={{maxWidth: "1280px", mx: { xs: 3, sm: 0 }}}>
        <Typography style={{fontWeight: 700, fontSize: "2.25rem", color: "#4B4C4D", margin: "16px 0px"}} variant="h2">{t("titles.news")}</Typography>
        <Divider sx={{mb :1}}/>
        {records.map((record, i) => {
          if (!record.active) return null
          return (
            <NewsArticlePreview 
            key={record.id}
            record={record}
            />
            )
          })}
          <Pagination 
            count={count} 
            page={page}
            onChange={(event, value) => {
              setPage(value);
              refreshRecords(value);
        }} 
      />
      </Box>
    </PublicPage>
  )
}

export default NewsPage