import React, { useRef, useState, useEffect } from 'react';
import { Box, Link, LinearProgress, Button } from '@mui/material';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

function Carousel({ children,progressColor,snap }: any): JSX.Element {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const scrollDirection = useRef('right');

  let isMouseDown = false;
  let startX = 0;
  let scrollLeft = 0;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>): void => {
    isMouseDown = true;
    startX = e.pageX - (carouselRef.current?.offsetLeft || 0);
    scrollLeft = carouselRef.current?.scrollLeft || 0;
  };


  const handleMouseUp = (): void => {
    isMouseDown = false;

    if (snap) {
      // if scroll direction is left, floor the scroll left / clientwicth to get the current slide
      // if scroll direction is right, ceil the scroll left / clientwicth to get the current slide

      const { scrollLeft, clientWidth } = carouselRef.current || { scrollLeft: 0, clientWidth: 0 };
      
      const currentSlide = scrollDirection.current === 'left' ? Math.floor(scrollLeft / clientWidth) : Math.ceil(scrollLeft / clientWidth);


      // scroll to currentslide
      carouselRef.current?.scrollTo({
        left: currentSlide * clientWidth,
        behavior: 'smooth',
      });
    }

    handleScroll(); // Trigger handleScroll manually
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!isMouseDown || !carouselRef.current) return;
    e.preventDefault();
    const x = e.pageX - (carouselRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 1.5; // Adjust scroll speed if needed
    carouselRef.current.scrollLeft = scrollLeft - walk;

    // Set scroll direction
    if (isMouseDown && snap){
      if (walk < 0) {
        scrollDirection.current = 'right';
      }
      if (walk > 0) {
        scrollDirection.current = 'left';
      }
    }

  };

  const handleScroll = (): void => {
    if (!carouselRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
    const progress = (scrollLeft / (scrollWidth - clientWidth)) * 100;
    if (isMouseDown) return;

    if (progress > 99.9) {
      setScrollProgress(100);
      return;
    }
    setScrollProgress(progress);
  };

  // Touch event handling
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
    isMouseDown = true;
    startX = e.touches[0].pageX - (carouselRef.current?.offsetLeft || 0);
    scrollLeft = carouselRef.current?.scrollLeft || 0;
  };

  const handleTouchEnd = (): void => {
    isMouseDown = false;

    if (snap) {
      // if scroll direction is left, floor the scroll left / clientwicth to get the current slide
      // if scroll direction is right, ceil the scroll left / clientwicth to get the current slide

      const { scrollLeft, clientWidth } = carouselRef.current || { scrollLeft: 0, clientWidth: 0 };
      
      const currentSlide = scrollDirection.current === 'left' ? Math.floor(scrollLeft / clientWidth) : Math.ceil(scrollLeft / clientWidth);


      // scroll to currentslide
      carouselRef.current?.scrollTo({
        left: currentSlide * clientWidth,
        behavior: 'smooth',
      });
    }

    handleScroll(); 
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
    if (!isMouseDown || !carouselRef.current) return;
    const x = e.touches[0].pageX - (carouselRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 1.5; // Adjust scroll speed if needed
    carouselRef.current.scrollLeft = scrollLeft - walk;

     // Set scroll direction
    
     if (isMouseDown && snap){
      if (walk < 0) {
        scrollDirection.current = 'right';
      }
      if (walk > 0) {
        scrollDirection.current = 'left';
      }
    }

    handleScroll(); 
  };

  const handleButtonScroll = (direction: string): void => {
    if (!carouselRef.current) return;
    const { scrollLeft, scrollWidth } = carouselRef.current;

    const scrollAmount = direction === 'left' ? -scrollWidth/children.length : scrollWidth/children.length;

    carouselRef.current.scrollTo({
      left: scrollLeft+scrollAmount,
      behavior: 'smooth',
    });
    handleScroll(); 
  };

  return (
    <Box>
      <Box position={"relative"}>
        <Box
          mt={2}
          display="flex"
          justifyContent={'space-between'}
          ref={carouselRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart} 
          onTouchEnd={handleTouchEnd} 
          onTouchMove={handleTouchMove} 
          onScroll={handleScroll}
          sx={{
            overflowX: 'hidden',
          }}
        >
          {children}
        </Box>
        <Button

          onClick={
            () => {
              handleButtonScroll('left');
            }
          }
          sx={{
            visibility: scrollProgress > 0 ? "visible" : "hidden",
            borderRadius:"50%",
            position:"absolute",
            top:"50%",
            left:{
              xs:1,
              lg:3
            },
            p:0,
            width: {
              xs:"2rem",
              lg:"3rem"
            },
            minWidth:{
              xs:"2rem",
              lg:"3rem"
            },
            height:{
              xs:"2rem",
              lg:"3rem"
            },
            backgroundColor: "rgb(255,255,255,0.7)",
            "&:hover":{
              backgroundColor:"rgb(255,255,255,0.5)",
              borderColor: "rgb(0,0,0,0.7)"
            },
            border:" 1px solid black"
            }}>
          <ArrowLeftIcon fontSize='large' sx={{
             color:"black",
             "&:hover":{
              borderColor: "rgb(0,0,0,0.7)"
             },
             transform:{
               lg:"scale(1.5)"
             }
          }} />
        </Button>
        <Button 
          onClick={
            () => {
              handleButtonScroll('right');
            }
          }
          sx={{
            visibility: scrollProgress < 100
            ? "visible" : "hidden",
            borderRadius:"50%",
            position:"absolute",
            top:"50%",
            right:{
              xs:1,
              lg:3
            },
            width: {
              xs:"2rem",
              lg:"3rem"
            },
            minWidth:{
              xs:"2rem",
              lg:"3rem"
            },
            height:{
              xs:"2rem",
              lg:"3rem"
            },
            p:0,
            backgroundColor: "rgb(255,255,255,0.7)",
            "&:hover":{
              backgroundColor:"rgb(255,255,255,0.9)"
            }  ,
            border:" 1px solid black"
            }}>
          <ArrowRightIcon fontSize='large' sx={{
            color:"black",
            transform:{
              lg:"scale(1.5)"
            }
          }} />
        </Button>
      </Box>
      <Box  width={"100%"} >
        <LinearProgress  variant="determinate" value={scrollProgress} sx={{height:"7px",
        "& .MuiLinearProgress-bar": {
          backgroundColor: progressColor,
        },
        backgroundColor: progressColor.replace("rgb","rgba").replace(")",",0.3)"),
      }} />
      </Box>
    </Box>
  );
}

export default Carousel;
