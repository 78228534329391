import React, { useContext, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import UserContext from "@/components/UserContext/UserContext";
import Table from "@/ui/Table/Table";
import tableGenerator from "@/lib/tableGenerator";
import dataControllerSubModel from "@/lib/dataControllerSubModel";
import dataController from "@/lib/dataController";

import radio from "@/models/submodels/radio";
import checkbox from "@/models/submodels/checkbox";
import picker from "@/models/submodels/picker";

import {
  // DateColumnFilter
  ElementPropertyColumnFilter
, MultiColumnFilter
, NoFilter
, SelectColumnFilter
, TextColumnFilter
, NumberRangeFilter
} from "@/ui/Table/Filters";
import { dateColumnFilter } from "@/ui/Table/Filters/DateColumnFilter"
import {
  sortDateTime
, sortBasic
, sortElementProperty
, sortFavorite
, sortLink
, sortMulti
} from "@/lib/Table/sort";
import {
  filterCustom
, filterDate
, filterElementProperty
, filterMulti
, filterRange
} from "@/lib/Table/filter";

import {
  IModelTableProps,
  SetFilterFn,
  ISubModelsWithData,
  IColumnInfoState,
  RowField
} from "@/@types/ui/Table";
import { FieldAny, IFieldPickerModel } from "@/@types/models/model";
import { Column, Row } from "react-table";

function ModelTable(props: React.PropsWithChildren<IModelTableProps>) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [subModelsWithData, setSubModelsWithData] = useState<ISubModelsWithData>({});
  const [columnInfo, setColumnInfo] = useState<IColumnInfoState>({columns: [], hiddenColumnNames: []});
  const [data, setData] = useState([]);

  const {
    dc,
    viewName,
    records,
    children,
    allowAdd,
    allowColumnPicker,
    allowExport,
    allowFilter,
    allowSelection,
    allowRowAction,
    onRowAction,
    onRowClick,
    onDoubleClick,
    handleRefresh,
    selectedRowId,
    // topAlignedSearch,
    tableName,
    // pagination = true,
    search = true,
    addLabel,
    handleAdd,
    title
  } = props;

  const viewFields = dc.getViewFields(viewName);
  const src = dc.getSource();
  const identifier = src + viewName;

  const tg = new tableGenerator(t, onRowAction, allowRowAction);

  function setFilterLocalStorage<ValueType> (id: string, filter: ValueType | null | undefined, setFilter: SetFilterFn<ValueType>): void {
    if (userContext) {
      userContext.setRecentFilters(identifier, id, filter ? filter : "");
    }
    // Imamo neke vrijednosti za filter koji su 0, a to ne prolazi na filter || undefined
    // uvijek će to bacit na undefined, pa nek provjeri if statement da li je jedan od ovih vrijednosti osim 0
    if (filter === undefined || filter === null || (typeof filter === "string" && filter === "")) {
      setFilter(undefined);
    } else {
      setFilter(filter);
    }
  }

  useEffect(() => {
    const fieldsWithModel = viewFields.filter(
      (f) => ["picker", "checkbox", "radio", "boolean", "active", "iconStatus"].indexOf(f.type) !== -1 && f.hasOwnProperty('subModel')
    ) as Array<IFieldPickerModel>;
    const subModels: ISubModelsWithData = {};

    fieldsWithModel.forEach((f) => {
      const modelType =
        f.type === "picker"
          ? picker
          : f.type === "radio"
          ? radio
          : f.type === "checkbox"
          ? checkbox
          // not existing for now
          // : f.type === "link"
          // ? require(`Models/${f.subModel.name}`)
          : null;

      if (modelType !== null) {
        Object.assign(subModels, {
          [f.source]: new dataControllerSubModel(modelType, f)
        });
        // not existing for now
        // if (f.type === "link") {
        //   Object.assign(subModels, {
        //     [f.source]: new dataController(modelType, f)
        //   });
        // } else {
        // }
      }
    });

    const getDataPromises = Object.keys(subModels).map((f) => subModels[f].GetData());
    Promise.all(getDataPromises).then((data) => {
      setSubModelsWithData(subModels);
    });
  }, []);

  const getFilterControl = (f: FieldAny) => {
    switch (f.type) {
      case "text":
      case "mail":
      case "link":
        return TextColumnFilter(setFilterLocalStorage)
      case "radio":
      case "boolean":
      case "iconStatus":
      case "active":
      case "picker":
        return SelectColumnFilter(setFilterLocalStorage, subModelsWithData, f.items)
      case "date":
      case "datetime":
        return dateColumnFilter(setFilterLocalStorage)
      case "checkbox":
      case "array":
        // return SelectColumnFilter(setFilterLocalStorage, subModelsWithData, f.items)
        return MultiColumnFilter(setFilterLocalStorage, subModelsWithData, f.items)
      case "currency":
      case "wholenum":
      case "numeric":
        return NumberRangeFilter(setFilterLocalStorage);
      default:
        return NoFilter;
    }
  }

  const getFilterCompareFn = (f: FieldAny) => {
    return (arrValues: Array<Row>, filterField: Array<string>, currValue: any) => {
      const [fField] = filterField;
      return arrValues.filter((field) => {
        const { original } = (field as RowField);
        switch (f.type) {
          case "radio":
          case "boolean":
          case "iconStatus":
            return original[fField] === currValue || original[fField].value === currValue;
          case "picker":
            if (f.multi) {
              return filterMulti(original[fField], currValue);
            } else {
              return original[fField] === currValue || original[fField].value === currValue;
            }
          case "active":
            return (currValue === "all" || original[fField] === currValue);
          case "checkbox":
            return filterMulti(original[fField], currValue);
          case "date":
          case "datetime":
            return filterDate(original[fField], currValue);
          case "currency":
          case "wholenum":
          case "numeric":
            return filterRange(original[fField]?.props?.unformatedvalue ? original[fField].props.unformatedvalue : original[fField], currValue);
          default:
            return (original[fField] + "").toUpperCase().indexOf(currValue.toUpperCase()) >= 0;
        }
      });
    }
  }


  const getSortType = (f: FieldAny) => {
    switch (f.type) {
      case "date":
      case "datetime":
        return sortDateTime;
      case "link":
        return sortLink;
      default:
        return "alphanumeric";
        return sortBasic;
    }
  }

  useEffect(() => {
    const viewFields = dc.getViewFields(viewName);
    const columns = viewFields.map((f) => {
      const canSort = f.hasOwnProperty("sort") && f.sort === false ? false : true;
      const sortType = getSortType(f);
      let obj: Column = {
        Header: f.ttoken ? t(f.ttoken) : t(f.title),
        accessor: f.source,
        //@ts-ignore
        Cell: tg.generateRenderFunction(f, subModelsWithData),
        disableSortBy: !canSort,
        sortType: sortType,
        disableFilters: !f.filter,
        Filter: getFilterControl(f),
        filter: getFilterCompareFn(f),
        // padding: f.type === "button" && f.padding ? f.padding : "default"
      };

      return obj;
    }, );

    const hiddenColumnNames = dc.getHiddenViewFieldsNames(viewName);

    setColumnInfo({
      columns: columns,
      hiddenColumnNames: hiddenColumnNames
    })

  }, [viewName, t, subModelsWithData]);

  return (
    <Table
      title={title}
      data={records}
      // topAlignedSearch={topAlignedSearch}
      records={records}
      columns={columnInfo.columns}
      hiddenColumnNames={columnInfo.hiddenColumnNames}
      allowColumnPicker={allowColumnPicker}
      allowSelection={allowSelection}
      allowFilter={allowFilter}
      allowExport={allowExport}
      allowAdd={allowAdd}
      allowSearch={search}
      addLabel={addLabel}
      handleAdd={handleAdd}
      dc={dc}
      tableName={tableName}
      onRowClick={onRowClick}
      onDoubleClick={onDoubleClick}
      onRefresh={handleRefresh}
      selectedRowId={selectedRowId}
      identifier={identifier}
      // pagination={pagination}
    >
      {children}
    </Table>
  );
}

ModelTable.defaultProps = {
  viewName: "default",
  allowColumnPicker: true,
  allowSelection: "one",
  allowFilter: false,
  allowExport: false
};

ModelTable.propTypes = {
  records: PropTypes.array,
  dc: PropTypes.object,
  viewName: PropTypes.string,
  allowSelection: PropTypes.oneOf(["none", "one", "many"]),
  allowFilter: PropTypes.bool,
  allowExport: PropTypes.bool
};
export default ModelTable;
