import React from 'react';
import { Box,Typography } from '@mui/material';

import footerBg from '../../images/footerbgt.png';
import opcinaLogo from '../../images/opcinaLogo.png';

function HomePageFooter()
{
    return (
        <Box  sx={{
            
        }}>
            <Box position={"relative"} sx={{
                backgroundImage: `url(${footerBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "cover",
                height: {
                    xs: 200,
                    sm: 200,
                    md: 300,
                    lg: 300,
                },
                marginBottom:
                {
                    xs: "44px",
                    sm: "64px",
                    md: "0",
                }
            }}>
                
            <Box sx={{
                display:"flex",
                position:"absolute",
                bottom:{xs:10,sm:10,md:20,},
                left:{xs:10,sm:10,md:20,},
            }}>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-end"} sx={
                        {
                            width: {
                                xs: 90,
                                sm: 90,
                                md: 130,
                                lg: 130,
                            },
                        }
                    }>
                        <img src={opcinaLogo} width={"100%"} alt="opcinaLogo"/>
                    </Box>
                    <Box>
                        <Box color={"white"} display={"flex"} fontSize={20}flexDirection={"column"} ml={5}>
                            <Typography mb={3} fontFamily={"Roboto slab"} variant='h4'
                            sx={{
                                fontSize: {
                                    xs: 15,
                                    sm: 15,
                                    md: 25,
                                    lg: 25,
                                }

                            }}>Općina Jakovlje</Typography>
                            <Box sx={{
                                fontSize: {
                                    xs: 12,
                                    sm: 12,
                                    md: 17,
                                    lg: 17,
                                }
                            }}>
                                <Typography variant='inherit'>Adresa: Ulica Adele Sixta 2, 10297 Jakovlje</Typography>
                                <Typography variant='inherit'>Tel: +385 (0)1 33 - 51 - 205</Typography>
                                <Typography variant='inherit'>E-mail: opcina@jakovlje.hr</Typography>
                            </Box>
                        </Box>
                    </Box>
            
                </Box>

            </Box>
        </Box>
    )
}


export default HomePageFooter;