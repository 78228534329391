import * as React from "react";
import { Fragment, FunctionComponent } from "react";

//MUI
import {Toolbar, Box} from "@mui/material";

//Custom Components
import PublicHeader from "@/ui/Header/PublicHeader";
import Footer from "@/ui/Footer/Footer";

//Background Image
import Image from "@/images/bkg/prehnit_gis_unlimited.jpg";

//Types
import { IPublicPage } from "@/@types/ui/PublicPage";

const PublicPage: FunctionComponent<any> = (props) => {
  const { contained, hasBackground } = props;

  return (
    <Fragment>
      <PublicHeader />
      <main id="content-wrapper" style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <Toolbar variant="dense" />
        <Box
          id="content"
          style={{
            backgroundImage: hasBackground ? `url(${Image})` : "none",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            maxWidth: contained ? "1280px" : "none"
          }}
        >
          {props.children}
        </Box>
      </main>
    </Fragment>
  );
};

export default PublicPage;
