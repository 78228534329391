import { IModel } from "@/@types/models/model";
import { buttonFields } from "./helpers/buttonFields";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { addressFields } from "./helpers/addressFields";
import { alarmFields } from "./helpers/alarmFields";
import { userFields } from "./helpers/userFields";

const News: IModel = {
  title: "News Controls",
  exportFileName: "news_page",
  apiPath: "news/",
  forms: {
    default: {
      fields: [
        "title",
        "content",
        "active",
        "photos",
        "date_time"
      ]
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "title",
        "content",
        "active",
        "date_time"
      ]
    }
  },
  fields: [
    {
      title: "AuthorID",
      source: "id",
      ttoken: "",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
        title: "Title",
        source: "title",
        ttoken: "news.title",
        type: "richtext",
        normal: true,
        styles: ["bold", "italic", "underline"],
        validation: {
            "maxLength": 200,
            "required": true
        }
    },
    {
        title: "Content",
        source: "content",
        ttoken: "news.content",
        type: "richtext",
        normal: true,
        styles: [["bold", "italic", "underline"], ["blockquote", "link"], [{ "list": "ordered"}, { "list": "bullet" }], [{ "indent": "-1"}, { "indent": "+1" }], [{ "color": [] }]],
        validation: {
            "maxLength": 6000,
            "required": true
        }
    },
    {
      title: "Date and Time",
      source: "date_time",
      ttoken: "news.date_time",
      type: "datetime",
      filter: true,
      // readonly: true
      validation: {
        required: true
      }
    },
    {
      title: "Aktivna",
      source: "active",
      ttoken: "",
      type: "boolean",
      items: {
        labels: ["common.no", "common.yes"],
        values: [false, true],
        default: false
      },
      filter: true
    },
    {
      title: "Photos",
      source: "photos",
      ttoken: "news.photos",
      type: "images",
      maxFiles: 1
    },
  ]
};

export default News;
