import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { Typography } from "@mui/material";

//Material-UI Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"
import DirectionsIcon from "@mui/icons-material/Directions";

//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import FormContent from "@/components/FormContent";
import modelDumpsiteReports from "@/models/dumpsiteReport"
import ImageSlider from "@/components/ImageSlider"
import { ConfirmResult } from "@/@types/lib/confirmDialog";
import { DoDeleteResponse } from "@/@types/components/formController";

import { get as OlGetProjection, transform as OlTransform } from "ol/proj";
// import CommentDialog from "./CommentDialog"

//Types
// import { CommentInfoCardProps } from "@/@types/views/GIS/map";
import { DCRecord } from "@/@types/lib/dataController";
import { CircularProgress } from "@mui/material";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import DumpsiteReportDialog from "./DumpsiteReportDialog";

function ReportInfoCard(props: any) {
  const { t } = useTranslation();
  
  const initialRecod = props.featureData.record ? props.featureData.record : props.featureData.feature.properties;

  const [record, setRecord] = useState(initialRecod);
  const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  const [dumpsiteReport, setDumpsiteReport] = useState<any>(null);

  const dialogContext = useContext(DialogContext);

  const dc = new dataController(modelDumpsiteReports);

  const { featureData, onClose, onDelete, refreshLayers, handleGoogleNavigate } = props;

  const photos = dumpsiteReport?.photos && dumpsiteReport.photos.length > 0 ? dumpsiteReport.photos : [];
  const snackbarContext = useContext(SnackbarContext);
  
  const refreshComment = () => {
    setLoadStatus({ isLoading: true, isLoaded: false });
    dc.GetDataSingle(record.id).then((resp) => {
      if(resp && resp.data) {
        setDumpsiteReport(resp.data)
      } else {
        // setComments(null)
      }
    })
    .finally(() => {
      setLoadStatus({ isLoading: false, isLoaded: true });
    })
  }

  useEffect(() => {
    refreshComment()
  }, [featureData])

  useEffect(() => {
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.feature) {
        setRecord(featureData.feature.properties);
      }
    }
    setRecord;
  }, [featureData]);


  const faIconClass = "fas fa-comment ";

  const confirmDelete = (): Promise<ConfirmResult> => {
    const title = t("cdialogs.are_you_sure");
    const text = t("cdialogs.once_deleted")
    const confirmButtonText = t("cdialogs.yes_delete")
    const cancelButtonText = t("cdialogs.no_cancel")

    return dialogContext.showConfirmDialog({title, text, confirmButtonText, cancelButtonText })
  }

  const doDelete = (): Promise<DoDeleteResponse> => {
    const id = dumpsiteReport?.id;
    if (id === undefined || id === null) {
      return Promise.reject({success: false, error: "Unknonw id"});
    }

    return confirmDelete()
      .then(result => {
        if (result.canceled) {
          return Promise.reject({ success: false, canceled: true });
        }
        if (result.confirmed) {
          return dc.DeleteRecord(id)
            .then(response => {
              // if (onDataUpdate) {
              //   onDataUpdate();
              // }
              return Promise.resolve({ success: true });
            })
            .catch(response => {
              return Promise.reject({ success: false, error: response.error ? response.error : "Greška prilikom brisanja podataka" });
            })

        }
        else {
          return Promise.reject({ success: false });
        }
      })

  }

  const handleDelete = () => {
    doDelete()
    .then((result) => {
        if (result.success) {
          snackbarContext.showNotification(t("dump_report.report_delete_success"), "success");
          refreshComment();
          handleCommentDialogClose({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          snackbarContext.showNotification(t("dump_report.report_delete_error"), "error");
        }
    })
  }

  const handleCommentDialogClose = (result: any) => {
    if (result.dataChanged && result.action !== "delete") {
      refreshComment();
    } else if (result.action == "delete") {
      if (onDelete) {
        onDelete()
      }
      onClose()
    }
  };

  const handleEditReport = () => {
    dialogContext.showDialog(DumpsiteReportDialog, {
      dc: dc,
      mode: "update",
      form: "edit",
      dataId: dumpsiteReport.id,
      onClose: handleCommentDialogClose,
      feature: featureData.feature,
      refreshLayers: refreshLayers
    });
  };

  const form = "infoCard";

  return (
    <Fragment>
      <MapCard
        sx={{
          backgroundColor: "background.default",
          minWidth: "400px"
        }}
      >
        {
          loadStatus.isLoaded ?
        <>
        <MapCardHeader
          title={dumpsiteReport?.author}
          subheader={OlTransform(featureData.position, 'EPSG:3857', 'EPSG:4326')[0].toString().substring(0,9) +", "+OlTransform(featureData.position, 'EPSG:3857', 'EPSG:4326')[1].toString().substring(0,9) }
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
                <GridContainer spacing={0}>
                <FormContent
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={dumpsiteReport as DCRecord}
                />
                
                <GridItem
                  xs={3}
                  sm={10}
                  fontSize={"0.8rem"}
                >
                  {t("dump_report.note")}:
                </GridItem>

                <GridItem
                  xs={3}
                  sm={10}
                  fontSize={"0.8rem"}
                  marginLeft={"10px"}
                  marginBottom={"10px"}
                >
                  {
                    dumpsiteReport?.note.length < 201 ? dumpsiteReport?.note : dumpsiteReport?.note.substring(0, 200) + "..."
                  }
                </GridItem>
                </GridContainer>
                
                {photos && photos.length > 0 ? (
                    <ImageSlider images={photos} />
                  ) : (
                  <Typography variant="caption">{t("common.no_photos")}</Typography>
                )}
            </GridItem>
          </GridContainer>
        </MapCardContent>
        <MapCardActions>
          {
            // <MapCardActionButton startIcon={<DeleteIcon />} onClick={handleDelete}>
            //   {t("buttons.delete")}
            // </MapCardActionButton>
          }
          <MapCardActionButton onClick={() => handleGoogleNavigate(featureData.position)} startIcon={<DirectionsIcon/>}>
            {t("buttons.google_map")}
          </MapCardActionButton>
          <MapCardActionButton startIcon={<EditIcon/>} onClick={handleEditReport}>
            {t("buttons.edit")}
          </MapCardActionButton>
        </MapCardActions>
          </>
          :
          <CircularProgress />
        }
      </MapCard>
    </Fragment>
  );
}

export default ReportInfoCard;
