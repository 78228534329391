import React, { Fragment, useState, useEffect, useContext, useRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom'
import moment, { Moment } from "moment";

import AbcIcon from '@mui/icons-material/Abc';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

// d.ts
import { DrawingType, CoordinateSystemType, BoolSettings, ViewOptionsType, IMapView } from "@/@types/common/index";
import {
  IMapProps,
  IMapState,
  CardPosition,
  CardOverlayType,
  ICardOverlay,
  ICardOverlayData
} from "@/@types/views/GIS/map";

// OpenLayers
import Geometry from "ol/geom/Geometry";
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection, transform as OlTransform } from "ol/proj";
import Projection from "ol/proj/Projection";
import OlLayerGroup from "ol/layer/Group";
import OlSourceVector from "ol/source/Vector";
import Map from "@/components/Map/Map";
import MapEvent from "ol/MapEvent";
import { DrawEvent } from "ol/interaction/Draw";
import BaseEvent from "ol/events/Event";
import OlMapBrowserEvent from "ol/MapBrowserEvent";
import OlBaseLayer from "ol/layer/Base";
import OlFeature from "ol/Feature";
import OlCollection from "ol/Collection";

// Custom
import {
  Controls,
  AttributionControl,
  CoordZoomStatusControl,
  DefaultControls,
  FullScreenControl,
  MeasureControls,
  OverviewMapControl,
  RotateControl,
  ScaleLineControl,
  ScaleRatioControl,
  StatusControl,
  ZoomControl,
  ZoomToExtentControl,
  GeoLocateControl,
  ZoomSliderControl,
  CenterMarkerControl,
  LayerSwitcher,
  LayerTree,
  ViewHistoryControl,
  PanningControl,
  BaseLayerSwitcherControl,
  IdentifyToolControl,
  DumpReportControl
  // ButtonControl
} from "@/components/Map/Controls";

import ButtonControl from "@/components/Map/Controls/Custom/Button/ButtonControl";

import {
  SidebarContent,
  SidebarControl,
  SidebarHeading,
  SidebarPane,
  SidebarTabs,
  SidebarTabList,
  SidebarTabListItem
} from "@/components/Map/Controls/Sidebar";

import { DataPane, InfoPane, LayersPane, MeasuresPane, RoutePane, DumpsiteReportPane } from "@/components/MapSidebarPanes";
import { Layers, TileLayer, VectorLayer, GroupLayer } from "@/components/Map/Layers";
import { Overlays, PopupOverlay, MeasureTooltipOverlay } from "@/components/Map/Overlays";
import { Interactions, DefaultInteractions, DrawInteraction } from "@/components/Map/Interactions";
import { drawStyle, measurementsStyle, measuringStyle, selectedStyle, dumpsiteStyle } from "@/components/Map/mapStyles";
import UserConsumer from "@/components/UserContext/UserConsumer";
import UserContext from "@/components/UserContext/UserContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import DialogContext from "@/ui/DialogContext/DialogContext";
import { DCRecord } from "@/@types/lib/dataController";
import { flattenLayers, getCallDirectLayers, getCallGroupedLayers, getOnlyGroupLayers } from "@/lib/olHelpers";
import { GFIFeatureType } from "@/@types/services/gsService";
import DumpsiteReportDialog from "@/views/GIS/MapPage/DumpsiteReportDialog"

import GeoBaseLayerSwitcher from "@/components/Map/Controls/GEO/GeoBaseLayerSwitcher";
import GeoAPILayers from "@/components/Map/Layers/GEO/GeoAPILayers";
import GSInfoCard from "./GSInfoCard";
import ReportInfoCard from "./ReportInfoCard";
import RdInfoCard from "./RdInfoCard";
import LegendPane from "@/components/MapSidebarPanes/LegendPane";

//Services
import mapService from "@/services/mapService";
import gsService from "@/services/gsService";
import { authService } from '@/services/authService';
import dataController from "@/lib/dataController";
// import { Map } from "ol";
import data from "@/models/data"
import modelDumpsiteReports from "@/models/dumpsiteReport"

function MainMap(props: IMapProps) {
  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const { t } = useTranslation();
  const navigate = useNavigate()
  const location = useLocation();
  const snackbarContext = useContext(SnackbarContext)
  const dialogContext = useContext(DialogContext);

  const [mapInitialized, setMapInitialized] = useState(false);

  const [viewOptions, setViewOptions] = useState<ViewOptionsType>({
    center: userContext?.mapSettings ? userContext.mapSettings.initial_view_center : [1764889, 5771455],
    zoom: userContext?.mapSettings ? userContext.mapSettings.initial_view_zoom : 14,
    extent: [1746255.546, 5762784.032, 1781856.092, 5780498.314],
    //projection: wgs84PM,
    minZoom: 8,
    maxZoom: 20,
    constrainResolution: true
  });

  const [drawingSource, setDrawingSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [measurementsSource, setMeasurementsSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [dumpsiteRecords, setDumpsiteRecords] = useState<DCRecord[]>([]);
  const [initialSidebarID, setInitialSidebarId] = useState<string | undefined>(undefined);

  const [chosenCoordinateSystem, setChosenCoordinateSystem] = useState<CoordinateSystemType>("WGS84");
  const [defaultCoordinates, setDefaultCoordinates] = useState<null | number[]>(null)
  const [layersCollection, setLayersCollection] = useState<OlCollection<OlBaseLayer> | undefined>(undefined);
  const [forceRefreshCounter, setForceRefreshCounter] = useState(0);
  const [drawType, setDrawType] = useState<DrawingType | undefined>(undefined);
  const [isDrawingRoute, setIsDrawingRoute] = useState(false);
  const [isIdentifying, setIsIdentifying] = useState(false)
  const [isReporting, setIsReporting] = useState(false)
  const [feature, setFeature] = useState<OlFeature<Geometry> | undefined>(undefined);
  const [measuringFeature, setMeasuringFeature] = useState<OlFeature<Geometry> | undefined>(undefined);
  const [cardOverlay, setCardOverlay] = useState<ICardOverlay | undefined>(undefined);
  const [defaultExtent, setDefaultExtent] = useState(
    userContext?.mapSettings?.default_extent
      ? userContext.mapSettings.default_extent
      : [1755716, 5767026, 1774061, 5775883]
  );

  const [workingRecords, setWorkingRecords] = useState<DCRecord[]>([]);
  const [workingSource, setWorkingSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const [selectedRecords, setSelectedRecords] = useState<DCRecord[]>([]);
  const [selectedSource, setSelectedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [dumpsiteFeature, setDumpsiteFeature] = useState<OlFeature<Geometry> | null>(null);
  const [dumpsiteSource, setDumpsiteSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const zoomToDefaultExtentElementRef = useRef<HTMLLIElement>(null);
  const zoomToSelectedExtentElementRef = useRef<HTMLLIElement>(null);
  const fullScreenElementRef = useRef<HTMLLIElement>(null);
  const geoLocateElementRef = useRef<HTMLLIElement>(null);
  const identifyToolRef = useRef<HTMLLIElement>(null);
  const dumpReportRef = useRef<HTMLLIElement>(null);
  const [centerMarkerElement, setCenterMarkerElement] = useState<HTMLLIElement | null>(null)
  const centerMarkerElementCallback = useCallback((node: HTMLLIElement | null) => {
    setCenterMarkerElement(node)
  }, [])
  const layerSwitcherElementRef = useRef<JSX.Element>();

  //define proj
  proj4.defs(
    "EPSG:3765",
    "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
  proj4.defs(
    "EPSG:31276",
    "+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs"
  );
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection("EPSG:3765");
  const wgs84 = OlGetProjection("EPSG:4326");
  const wgs84PM = OlGetProjection("EPSG:3857");

  // defaultViewCenter = [1731757, 5581737];
  // initialDefaultExtent = [1688674,5501166,1688774,5501266];
  const defaultViewCenter = userContext?.mapSettings?.initial_view_center;
  const initialDefaultExtent = userContext?.mapSettings?.default_extent;

  useEffect(() => {
    getLayers();
    // getDefaultData();
  }, []);


  const getLayers = () => {
    mapService.getLayers().then((coll) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };


  // const getDefaultData = () => {
  //   mapService.getDefaultData().then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       // zoomToExtent: viewData.default_extent
  //       setViewOptions((prevState) => {
  //         return {
  //             ...prevState,
  //             zoom: viewData.initial_view_zoom,
  //             center: viewData.initial_view_center
  //           }
  
  //         }
  //       );
  //     }
  //   });
  // }

  const setViewCenter = (view: IMapView) => {
    setViewOptions((prevState) => {
      return {
        ...prevState,
        center: view.center,
        zoom: view.zoom
      };
    });
  };

  const changeCoordinateSystemDisplay = (type: CoordinateSystemType) => {
    setChosenCoordinateSystem(type);
  };

  const handleClick = useCallback(
    (evt: OlMapBrowserEvent<any>) => {
      let hit = false;

      if (userContext && userContext.expireTime) {
        checkToken(userContext.expireTime)
      }

      if (drawType) {
        return;
      }

      const feature = evt.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        },
        {
          // layerFilter: (layer) => {
          //   const layerId = layer.get("id");
          //   return layerId !== null && layerId !== undefined && layerId.startsWith("spatial-comments");
          // }
          hitTolerance: 10
        }
      );

      const timeBeforeLoader = 300  // time in miliseconds before loader appears while waiting on getFeatureInfo
      let loaderNeeded = true
      let loading = false

      function handleLoader() {
        if (loaderNeeded) {
          loaderContext.toggleLoading(true)
          loading = true
        } else {
          if (loading) {
            loading = false
            loaderContext.toggleLoading(false)
          }
        }
      }

      // if (feature) {
      //   const id = String(feature.getId());
      //   hit = true;

      //   showCardOverlay("reportinfo", {
      //     position: evt.coordinate as CardPosition,
      //     feature: feature,
      //     type: "reportinfo",
      //   }, null);
      // }

      if (!hit) {
        setTimeout(() => {
          handleLoader()
        }, timeBeforeLoader)
        const item = localStorage.getItem("maplayers");
        const visibility = item ? (JSON.parse(item) as BoolSettings) : ({} as BoolSettings);
        const hiddenLayers = visibility ? Object.keys(visibility).filter((key) => visibility[key] === false) : [];
        const allLayers = layersCollection
          ? flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup))
          : [];
        const visibleLayers = allLayers.filter((x) => hiddenLayers.indexOf(x.get("id")) === -1);
        const GSLayerNames = visibleLayers.filter((x) => x.get("query") === true).map((x) => x.get("layer"));

        gsService.getFeatureInfo(evt.map, evt.pixel, GSLayerNames).then((resp) => {
          if (resp && Object.keys(resp).length != 0) {
            const layerKeys = Object.keys(resp);
            const features = resp[layerKeys[0]];
            const firstLayer = resp[layerKeys[0]];
            const firstFeature = firstLayer[0];
            const featureType = "generic";
            const data = {
              position: evt.coordinate as CardPosition,
              feature: firstFeature,
              type: featureType as CardOverlayType,
              record: undefined
            } as ICardOverlayData;
            //TODO: depending on ID, define data type, send different id instead of "gsinfo"
            if (layerKeys.includes('zeleni_otoci') || layerKeys.includes('reciklazna_dvorista')) {
              showCardOverlay("rdinfo", data, null);
            } else if (layerKeys[0] == 'map_dump_reports') {
              showCardOverlay("reportinfo", data, null);
            } else {
              showCardOverlay("gsinfo", data, null);
            }
            // const showCard = () => showCardOverlay("gsinfo", data, null);
            // closeOverlays(showCard);
          } else {
            closeOverlays();
          }
        }).finally(() => {
          loaderNeeded = false
          handleLoader()
        });
      }
    },
    [drawType, layersCollection]
  );

  const showCardOverlay = (type: CardOverlayType, data: ICardOverlayData, ft: OlFeature<any> | null) => {
    //TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
    }

    // if (type === "gsinfo") {
    //   setCardOverlay({
    //     type: type,
    //     data: data
    //   });
    //   if (ft) {
    //     setFeature(ft);
    //   } else {
    //     setFeature(undefined);
    //   }
     if (type === "reportinfo" && userContext?.userType == 1) {
      setCardOverlay({
        type: type,
        data: data
      });
      if (ft) {
        setFeature(ft);
      } else {
        setFeature(undefined);
      }
    } else if (type === "rdinfo") {
      setCardOverlay({
        type: type,
        data: data
      });
      if (ft) {
        setFeature(ft);
      } else {
        setFeature(undefined);
      }
    }
  };

  const closeOverlays = (callback = () => {}) => {
    setCardOverlay(undefined);
    // this.setState(
    //   {
    //     cardOverlay: undefined
    //   },
    //   typeof callback === "function" ? () => callback() : () => {}
    // );
  };

  const handleViewChangeCenter = (evt: MapEvent) => {
    if (evt && evt.map) {
      const newView = {
        center: evt.map.getView().getCenter(),
        zoom: evt.map.getView().getZoom()
      };
      setViewOptions(Object.assign(viewOptions, newView));
    }
  };

  const handleSidebarPaneChange = useCallback(
    (id: string) => {
      if (drawType && id && id != 'measures') {
        setDrawType(undefined);
      }
    },
    [drawType]
  );

  const changeDrawType = useCallback(
    (type: DrawingType) => {
      if (drawType !== type) {
        setDrawType(type);
        setMeasuringFeature(undefined);
      } else {
        setDrawType(undefined);
        setMeasuringFeature(undefined);
      }
    },
    [drawType]
  );

  const handleDrawMeasureStart = (evt: DrawEvent) => {
    if (drawingSource.getFeatures().length > 0) {
      setDrawingSource(new OlSourceVector({}));
    }

    setMeasuringFeature(evt.feature);
  };

  const handleDrawMeasureEnd = (evt: DrawEvent) => {
    setDrawingSource(new OlSourceVector({}));
    setMeasurementsSource(
      (prevState) =>
        new OlSourceVector({
          features: [...prevState.getFeatures(), evt.feature]
        })
    );

    if (isReporting) {
      const newFeature = evt.feature;

      const featureArray = []
      featureArray.push(newFeature)

      setDumpsiteFeature(newFeature)
      const newDumpsiteSource = new OlSourceVector({features: featureArray as OlFeature<Geometry>[]})
      setDumpsiteSource(newDumpsiteSource)
      
      if(window.innerWidth < 768)  navigate("/karta#prijava-divljeg-odlagalista");
    
    }


    setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
  };

  function handleCloseReporting() {
    setIsReporting(false)
    setDrawType(undefined)
  }

  const handleDrawMeasureChange = (evt: BaseEvent) => {
    // console.log("handleDrawChange", evt);
  };

  const handleEraseMeasurements = () => {
    setMeasurementsSource(new OlSourceVector({}));
  };

  const handleLayerVisibilityChange = () => {
    setForceRefreshCounter((prevState) => prevState + 1);
  };

  const handleMarkRecords = (selectedRecords: DCRecord[], map: any) => {

    //console.log(selectedRecords)
    // const wkts = selectedRecords.map(x => x.wkt);
    // const features = wkts.map(wkt => {
    //   return new OlFeature({
    //     geometry: wkt?.readGeometry(wkt, {
    //       dataProjection: htrs96,
    //       featureProjection: wgs84PM
    //     })
    //   })
    // });

    // // selectedFeaturesSource.clear();
    // // selectedFeaturesSource.addFeatures(features);
    // setFeature(features)
  }

  function handleGoogleNavigate(position: CardPosition) {
    const positionLonLat = OlTransform(position, 'EPSG:3857', 'EPSG:4326');
    const googleMapsNavigationUrl = `https://www.google.com/maps/dir/?api=1&destination=${positionLonLat[1]},${positionLonLat[0]}`;
    window.open(googleMapsNavigationUrl, '_blank');
  }

  // console.log("render map");

  const onToggleDrawRoute = (isActive: boolean) => {
    setIsDrawingRoute(isActive);
  }

  function checkToken(expireTime: Moment) {
    const isExpired = expireTime < moment()
    if (isExpired) {
      authService.logout()
      snackbarContext.showNotification("messages.token_expired", "warning");
      navigate('/login')
    }
  }

  useEffect(() => {
    if (drawType) setIsIdentifying(false)

  }, [drawType])

  useEffect(() => {
    if (isIdentifying) setDrawType(undefined)
  }, [isIdentifying])

  useEffect(() => {
    if (location.state != null) {
      const transformedCoordinates = OlTransform(location.state as number[], 'EPSG:3765', 'EPSG:3857');
      setDefaultCoordinates(transformedCoordinates)
    }
  }, [mapInitialized, location.state])

  useEffect(() => {
    const hashValue = window.location.hash.substring(1);
    if(hashValue && hashValue.length > 0 && hashValue !== initialSidebarID){
      setInitialSidebarId(hashValue);
    }else{
      setInitialSidebarId(undefined);
    }
  }, [window.location]);
  

  return (
    <Fragment>
      {userContext && layersCollection ? (
        <Map
          height="800px"
          view={viewOptions}
          onClick={handleClick}
          onMoveend={handleViewChangeCenter}
          defaultCoordinates={defaultCoordinates}
          className="sidebar-map"
          id="main-map"
          initialized={mapInitialized}
          moveTolerance={10}
          maxTilesLoading={10}
          isIdentifying={isIdentifying}
        >
          <Controls>
            {/* <AttributionControl/> */}
            {/* <OverviewMapControl /> */}

            <CoordZoomStatusControl chosenCoordinateSystem={chosenCoordinateSystem} />
            <StatusControl changeCoordinateSystem={changeCoordinateSystemDisplay} />
            <ZoomControl zoomInTipLabel={t("map:controls.zoom_in")} zoomOutTipLabel={t("map:controls.zoom_out")} />
            <ZoomSliderControl />
            {/*<ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} />*/}
            {/* <PanningControl viewOptions={viewOptions} zoomToExtent={defaultExtent} /> */}
            <ScaleRatioControl ppi={96} />
            <ScaleLineControl />
            {/* <FullScreenControl id="full-screen-ctrl" tipLabel={t("map:controls.full_screen")} /> */}
            <RotateControl autoHide={false} tipLabel={t("map:controls.reset_north")}/>
            {/* <ViewHistoryControl /> */}
            {fullScreenElementRef?.current && mapInitialized? (
              <FullScreenControl
                id="full-screen"
                tipLabel={t("map:controls.full_screen")}
                target={fullScreenElementRef?.current}
                className="ol-sidebar-control"
              />
            ) : null}

            {zoomToDefaultExtentElementRef?.current && mapInitialized? (
              <ZoomToExtentControl
                id="zoom-extent-default"
                target={zoomToDefaultExtentElementRef.current}
                extent={defaultExtent}
                tipLabel={t("map:controls.zoom_to_extent")}
                className="ol-sidebar-control"
              />
            ) : null}
            {geoLocateElementRef?.current && mapInitialized? (
              <GeoLocateControl
              id="geo-locate"
              tooltip={t("map:controls.geolocate")}
              target={geoLocateElementRef.current}
              className="ol-sidebar-control"
              />
              ) : null}
            {centerMarkerElement && mapInitialized? (
                <CenterMarkerControl
                id="center-marker"
                tooltip={t("map:controls.center_marker")}
                target={centerMarkerElement}
                className="ol-sidebar-control"
                />
              ) : null}
              {/* {identifyToolRef?.current && mapInitialized? (
              <IdentifyToolControl
              id="identify"
              tooltip={t("map:controls.identify")}
              target={identifyToolRef.current}
              isIdentifying={isIdentifying}
              setIsIdentifying={setIsIdentifying}
              className="ol-sidebar-control"
              closeOverlays={closeOverlays}
              showCardOverlay={showCardOverlay}
              layersCollection={layersCollection}
              />
              ) : null} */}
            <GeoBaseLayerSwitcher allowNoLayer={false}/>
            <SidebarControl initialOpenId={initialSidebarID} onTabChange={handleSidebarPaneChange}>
              <SidebarTabs>
                <SidebarTabList>
                  <SidebarTabListItem
                    id="info"
                    title={t("map:sidebar.info")}
                    icon={<i className="fas fa-info-circle"></i>}
                  />
                  {
                    // <SidebarTabListItem
                    // id="layers"
                    // title={t("map:sidebar.layers")}
                    // icon={<i className="fas fa-layer-group"></i>}
                    // />
                  }
                  <SidebarTabListItem
                    id="legend"
                    title={t("map:sidebar.legend")}
                    icon={<i className="fas fa-list-alt"></i>}
                  />
                  {/* <SidebarTabListItem
                    id="data"
                    title={t("map:sidebar.datapane")}
                    icon={<i className="fas fa-map-pin"></i>}
                  /> */}
                  <SidebarTabListItem
                    id="prijava-divljeg-odlagalista"
                    title={t("map:sidebar.dumpsite-report")}
                    icon={<i className="fas fa-trash"></i>}
                  />
                  {/* <SidebarTabListItem
                    id="route"
                    title={t("map:sidebar.route")}
                    icon={<i className="fas fa-map-signs"></i>}
                  /> */}
                  {/* <SidebarTabListItem
                    id="measures"
                    title={t("map:sidebar.measures")}
                    icon={<i className="fas fa-pencil-ruler"></i>}
                  /> */}
                  <li ref={zoomToDefaultExtentElementRef}></li>
                  <li ref={fullScreenElementRef}></li>
                  <li ref={geoLocateElementRef}></li>
                  {/* <li ref={identifyToolRef}></li> */}
                  <li id="center-marker-container" ref={centerMarkerElementCallback}></li>
                </SidebarTabList>
              </SidebarTabs>
              <SidebarContent>
                <SidebarPane id="info">
                  <SidebarHeading title={t("map:sidebar.info")} />
                  <InfoPane />
                </SidebarPane>
                {

                //   <SidebarPane id="layers">
                //   <SidebarHeading title={t("map:sidebar.layers")} />
                //   <LayerTree
                //     ready={layersCollection ? true : false}
                //     layersCollection={layersCollection}
                //     onLayerVisibilityChange={handleLayerVisibilityChange}
                //     />
                //   <LayerSwitcher ready={layersCollection ? true : false} />
                //   <LayersPane layersGroup={true} />
                // </SidebarPane>
                }
                <SidebarPane id="legend">
                  <SidebarHeading title={t("map:sidebar.legend")} />
                  <LegendPane
                    // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                    layersCollection={layersCollection}
                  />
                </SidebarPane>
                {/* <SidebarPane id="route">
                  <SidebarHeading title={t("map:sidebar.route")} />
                  <RoutePane
                    onToggleDraw={onToggleDrawRoute}
                   />
                </SidebarPane> */}
                {/* <SidebarPane id="measures">
                  <SidebarHeading title={t("map:sidebar.measures")} />
                  <MeasuresPane
                    // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                    changeDrawType={changeDrawType}
                    handleEraseMeasurements={handleEraseMeasurements}
                    drawType={drawType}
                  />
                </SidebarPane> */}
                {/* <SidebarPane id="data">
                  <SidebarHeading title={t("map:sidebar.datapane")} />
                  <DataPane
                    // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                    // changeDrawType={changeDrawType}
                    // handleEraseMeasurements={handleEraseMeasurements}
                    // drawType={drawType}
                    model={data} 
                    tableName={"POI"} 
                    viewName={"sidebar"} 
                    onMarkRecords={handleMarkRecords}
                    proj4={proj4}
                  />
                </SidebarPane> */}
                <SidebarPane id="prijava-divljeg-odlagalista">
                  <SidebarHeading title={t("map:sidebar.dump_report")} />
                  <DumpsiteReportPane 
                    isReporting={isReporting}
                    setIsReporting={setIsReporting}
                    changeDrawType={changeDrawType}
                    feature={dumpsiteFeature}
                    refreshLayers={getLayers}
                  />
                </SidebarPane>
              </SidebarContent>
            </SidebarControl>
            {/* <ButtonControl id="test" sx={{position: "absolute", top:10, left: 600}} onClick={() => {console.log('click');}}>
              <AccessTimeFilledIcon fontSize="small"/>
            </ButtonControl> */}
          </Controls>
          <Layers>
            {/* <Layers id="osm" osm={true} /> */}
            {/* <GeoportalBaseLayer id="osm" zIndex={1} /> */}
            {layersCollection ? (
              <Layers>
                <GeoAPILayers layersCollection={layersCollection} />
                {/* <VectorLayer id="measurements" source={measurementsSource} style={measurementsStyle} zIndex={900} /> */}
                <VectorLayer id="dumpsite-reports" source={dumpsiteSource} style={dumpsiteStyle} zIndex={900} />
              </Layers>
            ) : null}
          </Layers>
          <Overlays>
            <PopupOverlay
              id="feature-overlay"
              position={cardOverlay ? cardOverlay.data.position : undefined}
              autoPan={true}
              onClose={() => {}}
            >
              {cardOverlay ? (
                cardOverlay.type === "gsinfo" ? <GSInfoCard featureData={cardOverlay.data} onClose={closeOverlays} />
                : cardOverlay.type === "rdinfo" ? <RdInfoCard featureData={cardOverlay.data} onClose={closeOverlays} handleGoogleNavigate={handleGoogleNavigate}/> 
                : cardOverlay.type === "reportinfo" ? <ReportInfoCard featureData={cardOverlay.data} 
                                                          onClose={closeOverlays} 
                                                          onDelete={getLayers} 
                                                          refreshLayers={getLayers}
                                                          handleGoogleNavigate={handleGoogleNavigate}
                                                          />
                : null
              ) : null}
            </PopupOverlay>
            <MeasureTooltipOverlay id="measure-overlay" feature={measuringFeature} position={undefined} />
          </Overlays>
          <Interactions>
            <DefaultInteractions />
            {drawType ? (
              <DrawInteraction
                source={drawingSource}
                type={drawType}
                style={measuringStyle}
                onChange={handleDrawMeasureChange}
                onDrawstart={handleDrawMeasureStart}
                onDrawend={handleDrawMeasureEnd}
              />
            ) : null}
          </Interactions>
        </Map>
      ) : null}
    </Fragment>
  );
}

export default MainMap;
