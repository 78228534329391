import * as React from "react";
import { FunctionComponent, useContext, useEffect, useRef, useState } from "react";

//OpenLayers
import OlOverlay from "ol/Overlay";
import OlGeomPolygon from "ol/geom/Polygon";
import OlGeomLineString from "ol/geom/LineString";
import {unByKey} from 'ol/Observable';

//Custom components
import MapContext from "@/components/Map/MapContext";
import { formatArea, formatLength, formatPoint, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IMeasureTooltipOverlay } from "@/@types/components/Map/Overlays";

const MeasureTooltipOverlay: FunctionComponent<IMeasureTooltipOverlay> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const overlayDiv = useRef(null);

  const [listener, setListener] = useState<any | null>(null);

  //@ts-ignore TODO:Variable 'overlay' implicitly has type 'any' in some locations where its type cannot be determined
  let overlay = undefined;

  const { feature } = props;

  const options = {
    id: undefined,
    element: undefined,
    offset: [0, -15],
    position: undefined,
    positioning: 'bottom-center',
    stopEvent: undefined,
    insertFirst: undefined,
    autoPan: true,
    autoPanAnimation: { duration: 100 },
    autoPanMargin: undefined,
    className: undefined
  };

  const events = {
    change: undefined,
    "change:element": undefined,
    "change:map": undefined,
    "change:offset": undefined,
    "change:position": undefined,
    "change:positioning": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    //@ts-ignore TODO: Property 'position' does not exist on type 'never'
    allOptions.position = undefined; //we need to set it after adding to map, otherwise autoPan won't work
    let definedOptions = getDefinedOptions(allOptions);

    //@ts-ignore TODO: Property 'element' does not exist on type 'object'
    definedOptions.element = overlayDiv.current;

    overlay = new OlOverlay(definedOptions);

    if (context.map) {
      const mapOverlay = context.map.getOverlayById(props.id);
      if (mapOverlay) {
        context.map.removeOverlay(mapOverlay);
      }
      context.map.addOverlay(overlay);
      if (props.position) {
        //@ts-ignore TODO: Type '"top"' is not assignable to type 'number[] | undefined'
        overlay.setPosition(props.position);
      }
    } else {
      context.initOptions.overlays.push(overlay);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      overlay.on(eventName, olEvents[eventName]);
    }
  }, [options, events]);

  useEffect(() => {

    if (feature) {
      let geom;
      //@ts-ignore TODO: Variable 'output' implicitly has type 'any' in some locations where its type cannot be determined.
      let output;
      //@ts-ignore TODO: Variable 'output' implicitly has type 'any' in some locations where its type cannot be determined.
      let tooltipCoord;
      //@ts-ignore TODO: object is possibly undefined
      if(feature.getGeometry().getType() === 'Point'){
        geom = feature.getGeometry();
        //@ts-ignore TODO: Argument of type 'Geometry | undefined' is not assignable to parameter of type 'Point'
        output = formatPoint(geom)
        //@ts-ignore TODO: Property 'getCoordinates' does not exist on type 'Geometry'
        tooltipCoord = geom.getCoordinates();

        // console.log(geom, tooltipCoord, output)

        if(overlayDiv && overlayDiv.current){
          //@ts-ignore TODO: innerHtml does not exist on type never
          overlayDiv.current.innerHTML = output;
          //@ts-ignore TODO: style does not exist on type never and probably display
          overlayDiv.current.style.display = "block";
        }

        // if (context.map) {
        //   const mapOverlay = context.map.getOverlayById(props.id);
        //   // console.log(mapOverlay)
        //   if (mapOverlay) {
        //     mapOverlay.setPosition(tooltipCoord);
        //   }
        // }
      } else {
        //@ts-ignore: TODO: object is possibly undefined
        const geomChangelistener = feature.getGeometry().on("change", function (evt) {
          geom = evt.target;
          if (geom instanceof OlGeomPolygon) {
            output = formatArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof OlGeomLineString) {
            output = formatLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          }

          // console.log(tooltipCoord)

          if(overlayDiv && overlayDiv.current){
            //@ts-ignore TODO: innerHtml does not exist on type never
            overlayDiv.current.innerHTML = output;
            //@ts-ignore TODO: style does not exist on type never and probably display
            overlayDiv.current.style.display = "block";
          }

          if (context.map) {
            const mapOverlay = context.map.getOverlayById(props.id);
            if (mapOverlay) {
              //@ts-ignore TODO: tooltipCoord implicitly has an any type
              mapOverlay.setPosition(tooltipCoord);
            }
          }
        });

        setListener(geomChangelistener);
      }
    } else {
      unByKey(listener);
      //@ts-ignore TODO: overlay implicitly has an any type
      overlay.setPosition(undefined);
    }
  }, [feature]);

  return (
    <div
      id={"overlay-" + props.id}
      ref={overlayDiv}
      className="ol-tooltip ol-tooltip-static"
      style={{ display: "block" }}
    >
    </div>
  );
};

//@ts-ignore TODO: isTouchDevice does not exist on MeasureTooltipOverlay
MeasureTooltipOverlay.isTouchDevice = function () {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};

export default MeasureTooltipOverlay;

