import React from 'react';
import { useNavigate } from "react-router-dom";

import {Button,Typography,Grid} from "@mui/material";

type INavButtonProps = {
    icon: JSX.Element;
    color: string;
    path: string;
}


function NavButtonHorizontal( props : INavButtonProps)
{
    const {icon, color, path} = props;
    const navigate = useNavigate();

    return (
        <Button  
        key="one"
        onClick={() => navigate(path)}
        sx={{ 
            border: 0,
            borderBottomColor: color,
            position: "relative",
            left:"0px",
            backgroundColor: color,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: {
                xs:"10px",
                sm:"20px",
                md:"20px",
            },
            borderRadius: "0px",
            "&:hover": {
                backgroundColor: color, 
                boxShadow: "none", 
                },
        }}>
     
            {icon}
        </Button>
    )
}

export default NavButtonHorizontal;
