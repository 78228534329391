import React, { useState, useEffect } from "react";
// MUI
import { Box } from "@mui/material";

// CSS
import "../../styles/landing-page.css";

// Custom components
import BlockFirst from "./BlockFirst";
import LandingPageNavBar from "./LandingPageNavBar";
import BlockLocation from "./BlockLocation";
import BlockOdvoz from "./BlockOdvoz";
import BlockNews from "./BlockNews";
import LandingPageFooter from "./LandingPageFooter";
import ScrollToTop from "./subcomponents/ScrollToTop";
import LandingPageVideo from "./LandingPageVideo";
import BlockGreenIslands from "./BlockGreenIslands";
import BlockDvorista from "./BlockDvorista";
import BlockRazvrstavanje from "./BlockRazvrstavanje";

// Graphics
import bgGif from "../../images/bggif.gif";
import homepagevid from "../../images/homepagevid.mp4";
import green from "../../images/green.png";

function LandingPage() {
  return (
    <Box width={"100%"} margin={0} fontFamily={"Roboto slab"}>
      <LandingPageVideo/>
        <BlockNews />
        <Box sx={{
          backgroundColor:"#FDFDFD"
        }}>
          <BlockOdvoz />
          <BlockLocation />
          <BlockGreenIslands />
          <BlockDvorista />
        </Box>
        <BlockRazvrstavanje />
        <ScrollToTop />
        <LandingPageNavBar />
    </Box>
  );
}

export default LandingPage;
