import React, { useState } from "react";
import {
  ButtonGroup,
  Button,
  Box,
  Typography,
  Hidden
} from "@mui/material";
import MapIcon from '@mui/icons-material/Map';
import LoginIcon from '@mui/icons-material/Login';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PinDropIcon from '@mui/icons-material/PinDrop';

import NavButtonVertical from "./subcomponents/NavButtonVertical";
import NavButtonHorizontal from "./subcomponents/NavButtonHorizontal";
import { transform } from "proj4";

function HomePageNavBar() {

  return (
    <>
      <Hidden mdDown>
        <Box
          className="navBarVertical"
          zIndex={5}
          position="fixed"
          right={-150}
          top={"30%"}
        >
          <ButtonGroup
            orientation="vertical"
            aria-label="vertical contained button group"
            variant="contained"
          >
            <NavButtonVertical
              text="Novosti"
              path="/novosti"
              icon={<NewspaperIcon sx={{ color: "black", transform: "scale(2)" }} />}
              color="#42E19E"
            />

            <NavButtonVertical
              text="Karta"
              path="/karta"
              icon={<MapIcon sx={{ color: "black", transform: "scale(2)" }} />}
              color="#55BEB8"
            />

            <NavButtonVertical
              text="Prijavi"
              path="/karta#prijava-divljeg-odlagalista"
              icon={<PinDropIcon sx={{ color: "black", transform: "scale(2)" }} />}
              color="#BCBE55"
            />

            <NavButtonVertical
              text="Login"
              path="/login"
              icon={
                <LoginIcon sx={{ color: "black", transform: "scale(2)" }} />
              }
              color="#83BE55"
            />
          </ButtonGroup>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box className="navBarHorizontal" position="fixed" bottom={0} width="100%" color="black"
        sx={{
          willChange: "transform",
          transform: "translateZ(0)",
        }}>
          <ButtonGroup
            fullWidth
            orientation="horizontal"
            aria-label="horizontal contained button group"
            variant="contained"
          >
            <NavButtonHorizontal
              path="/novosti"
              icon={
                <NewspaperIcon sx={{ color: "black", transform: {
                  xs: "scale(1.5)",
                  sm: "scale(2.5)",
                  } }} />
              }
              color="#42E19E"
            />
            <NavButtonHorizontal
              path="/karta"
              icon={
                <MapIcon sx={{ color: "black", transform: {
                  xs: "scale(1.5)",
                  sm: "scale(2.5)",
                  } }} />
              }
              color="#55BEB8"
            />
            <NavButtonHorizontal
              path="/karta#prijava-divljeg-odlagalista"
              icon={
                <PinDropIcon sx={{ color: "black", transform: {
                  xs: "scale(1.5)",
                  sm: "scale(2.5)",
                  } }} />
              }
              color="#BCBE55"
            /> 
            <NavButtonHorizontal
              path="/login"
              icon={
                <LoginIcon sx={{ color: "black", transform: {
                  xs: "scale(1.5)",
                  sm: "scale(2.5)",
                  }
                }} />
              }
              color="#83BE55"
            />


            
          </ButtonGroup>
        </Box>
      </Hidden>
    </>
  );
}

export default HomePageNavBar;
