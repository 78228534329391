import { IModel } from "../@types/models/model";
import { coreFields } from "./helpers/coreFields";

const Comment: IModel = {
  title: "Comments",
  apiPath: "data/poi/comments",
  forms : {
    "default": {
      fields: ["autor", "email", "biljeska"]
    }
  },
  listViews: {
    "default": {
      fields: ["autor", "biljeska"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Autor",
      source: "autor",
      ttoken: "comment.autor",
      type: "text",
      sort: true,
      filter: true,
      validation: {
        required: true,
        maxLength: 40
      }
    },
    {
      title: "Email",
      source: "email",
      ttoken: "comment.email",
      type: "text",
      sort: true,
      filter: true,
      validation: {
        required: true,
        maxLength:50
      }
    },
    {
      title: "Biljeska",
      source: "biljeska",
      ttoken: "comment.biljeska",
      type: "multiline",
      rows: 5,
      maxRows: 5,
      validation: {
        required: true,
        maxLength: 500
      }
    },
    // {
    //   title: "Photos",
    //   source: "photos",
    //   ttoken: "comment.photos",
    //   type: "images",
    //   maxFiles: 2
    // },
  ]
}

export default Comment;

