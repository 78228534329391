import React,{useState} from 'react'

import { Box, Typography,Tabs,Tab, ListItemText,List,ListItem,ListItemIcon} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import LandingPageFooter from "@/views/LandingPage/LandingPageFooter";

import razvrstavanjebg from "@/images/razvrstavanjebg.jpg";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <Box sx={{
           
        }}>
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (         
                    <>
                        {children}
                    </> 
                )}
            </div>
      </Box>
    );
  }

function BlockRazvrstanje()
{

    const [value, setvalue] = useState(0);

    const handleTabChange = (e : any, tabIndex : number) => {
      setvalue(tabIndex);
    };

    return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}
    sx={{
        backgroundImage: `url(${razvrstavanjebg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.8)",
        backgroundAttachment: "fixed",
    }}>
        <Box display={"flex"} justifyContent={"center"} sx={{
        }}>
            <Box textAlign={"left"} sx={{
                marginY: {
                    xs: 5,
                    sm: 5,
                    md: 10,
                    lg: 10,
                },
                width:{
                    xs:"95%",
                    sm:"95%",
                    md:"85%",
                    lg:"85%",
                },
         
                }}>
                    
                <Typography fontFamily={"Roboto slab"} color="white" fontWeight={700} fontSize={40} variant={"h3"}
                sx={{
                    fontSize: {
                        xs:"2rem",
                        sm:"2rem",
                        md:"2.3rem",
                        lg:"2.5rem",
                        xl:"2.8rem"
                    }
                
                }}
                >
                    Pravilno razvrstavanje otpada
                </Typography>
                <Box display={"column"} justifyContent={"center"} marginTop={5} sx={{
                    paddingX: {
                        xs:0,
                        sm:"1%",
                        md:"2%",
                        lg:"5%",
                        xl:"10%"
                    }
                }}>
                    <Box >
                        <Tabs  variant='scrollable' scrollButtons="auto" allowScrollButtonsMobile value={value} onChange={handleTabChange} 
                        TabIndicatorProps={{style: {background:"black",height:"7px"}}} 
                        sx={{marginTop: {
                            xs: 2,
                            sm: 2,
                            md: 5,
                            lg: 10,
                            xl: 10
                        },
                            '& .MuiSvgIcon-root': {
                                fill: 'white',
                                color: 'white',
                                transform: 'scale(2)',
                            },

                            '& .MuiTabs-flexContainer': {
                                justifyContent: 'flex-start',
                            },
                            '& .MuiTab-root': {
                                minWidth: 'auto',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                fontSize: {
                                    xs:"0.8rem",
                                    sm:"0.9rem",
                                    md:"1.1rem",
                                    lg:"1.2rem",
                                    xl:"1.4rem"
                                },
                                fontFamily: [
                                    'Roboto slab',
                                    'sans-serif',
                                ].join(','),
                            },
                        }}>
                        
                            <Tab label="Papir"
                            sx = {{
                                backgroundColor: "#5777E6",
                                '&.Mui-selected': {
                                    color: 'black',
                                },
                            }}
                            />
                            <Tab label="Plastika" sx = {{
                                backgroundColor: "#F8E74F",
                                '&.Mui-selected': {
                                    color: 'black',
                                },
                            }}
                            />
                            <Tab label="Staklo" sx = {{
                                backgroundColor: "#3EB751",
                                '&.Mui-selected': {
                                    color: 'black',
                                },
                            }}
                            />
                            <Tab label="Metal" sx = {{
                                backgroundColor: "#C5C5C5",
                                '&.Mui-selected': {
                                    color: 'black',
                                },
                            }}
                            />
                            <Tab label="Tekstil" sx = {{
                                backgroundColor: "#B16656",
                                '&.Mui-selected': {
                                    color: 'black',
                                },
                            }}
                            />
                            <Tab label="Reciklabilno" sx = {{
                                backgroundColor: "#F5C058",
                                '&.Mui-selected': {
                                    color: 'black',
                                },
                            }}
                            />
                            <Tab label="Krupni glomazni otpad" sx = {{
                                backgroundColor: "#AA7E4B",
                                '&.Mui-selected': {
                                    color: 'black',
                                },
                            }}
                            />
                             <Tab label="Reciklažno dvorište" sx = {{
                                backgroundColor: "#B6FF7D",
                                '&.Mui-selected': {
                                    color: 'black',
                                },
                            }}
                            />
                        </Tabs>
                    </Box>
                    <Box>
                        <CustomTabPanel value={value} index={0}>  {/*PAPIR*/}
                            <Box display={"flex"}  justifyContent={"flex-start"} sx={{backgroundColor:"rgb(201,211,244)", 
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.9rem",
                                md:"1.1rem",
                                lg:"1.2rem",
                                xl:"1.3rem"
                            },
                            padding: {
                                xs:2,
                                sm:2,
                                md:3,
                                lg:4,
                            },
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                            },
                            
                            }} >
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Spremnici za papirnati otpad namijenjeni su za:</b> </Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> novinski, uredski, pisaći i kompjuterski papir
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> papirnatu i kartonsku ambalažu
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> papirnate vrećice
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> knjige, bilježnice, časopise, kataloge
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> kartonske fascicle i kutije (bez ljepljive trake, stiropora, metalnih i plastičnih dodataka, i sl.)
                                        </ListItem>
                                    </List>
                                </Box>
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Zabranjeno je odlagati:</b></Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> tetrapak ambalažu → odložiti u spremnik plastike
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> plastificirani, metalizirani i/ili zauljeni papir (primjerice izrađene fotografije, uključujući foto-papir) → odložiti u spremnik miješanog komunalnog otpada
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}> {/*PLASTIKA*/}
                            <Box display={"flex"}  justifyContent={"flex-start"} sx={{backgroundColor:"#FAF5C7", 
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.9rem",
                                md:"1.1rem",
                                lg:"1.2rem",
                                xl:"1.3rem"
                            },
                            padding: {
                                xs:2,
                                sm:2,
                                md:3,
                                lg:4,
                            },
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                            },
                            
                            }} >
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Spremnici za plastični otpad namijenjeni su za:</b>  </Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> plastične vrećice
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> tetrapak ambalažu
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> plastične boce od pića i mliječnih proizvoda
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> plastičnu ambalažu šampona, kozmetike, deterdženata i sredstava za pranje
                                        </ListItem>
                                    </List>
                                </Box>
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Zabranjeno je odlagati:</b></Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> plastične igračke, tegle za cvijeće i vrtni namještaj → odložiti u reciklažno dvorište
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> plastiku koja sadrži elektronički otpad i/ili baterije → odložiti u reciklažno dvorište
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </CustomTabPanel> 
                        <CustomTabPanel value={value} index={2}> {/*STAKLO*/}
                            <Box display={"flex"}  justifyContent={"flex-start"} sx={{backgroundColor:"#C2E6C7", 
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.9rem",
                                md:"1.1rem",
                                lg:"1.2rem",
                                xl:"1.3rem"
                            },
                            padding: {
                                xs:2,
                                sm:2,
                                md:3,
                                lg:4,
                            },
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                            },
                            
                            }} >
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Spremnici za stakleni otpad namijenjeni su za:</b> </Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> staklene boce i staklenke svih boja
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> staklene čaše
                                        </ListItem>
                                    
                                    </List>
                                </Box>
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Zabranjeno je odlagati:</b></Typography> 
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> 
                                            neonske svjetiljke i žarulje → odložiti u reciklažno dvorište
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> 
                                            automobilska, prozorska, armirana i medicinska stakla → odložiti u reciklažno dvorište
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> 
                                            keramičko, kristalno i porculansko posuđe → odložiti u spremnik miješanog komunalnog otpada
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> 
                                            staklenu ambalažu zapaljivih tvari i kemikalija → odložiti u reciklažno dvorište
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}> {/*METAL*/}
                            <Box display={"flex"}  justifyContent={"flex-start"} sx={{backgroundColor:"#EAEAEA", 
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.9rem",
                                md:"1.1rem",
                                lg:"1.2rem",
                                xl:"1.3rem"
                            },
                            padding: {
                                xs:2,
                                sm:2,
                                md:3,
                                lg:4,
                            },
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                            },
                            
                            }} >
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Spremnici za metalni otpad namijenjeni su za:</b> </Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> konzerve
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> limenke
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> aluminijsku foliju
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> metalne poklopce i čepove
                                        </ListItem>
                                        
                                    </List>
                                </Box>
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Zabranjeno je odlagati:</b></Typography>
                                    <List>
                                        <ListItem  sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> 
                                            metalnu ambalažu boja, lakova, problematičnih i zapaljivih kemikalija, sprejeva i boca pod tlakom (primjerice sprej za čišćenje tipkovnica) → odložiti u reciklažno dvorište
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> 
                                            metalne cijevi i žice → odložiti u reciklažno dvorište
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> 
                                            metalni alati → odložiti u reciklažno dvorište
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}> {/*TEKSTIL*/}
                            <Box display={"flex"}  justifyContent={"flex-start"} sx={{backgroundColor:"#E4CEC9", 
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.9rem",
                                md:"1.1rem",
                                lg:"1.2rem",
                                xl:"1.3rem"
                            },
                            padding: {
                                xs:2,
                                sm:2,
                                md:3,
                                lg:4,
                            },
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                            },
                            
                            }} >
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Spremnici za tekstilni otpad namijenjeni su za:</b> </Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> ručnike, posteljinu, zavjese
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> odjeću, šešire, kape
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> torbe i krpene igračke
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> ostale (isključivo) tekstilne proizvode
                                        </ListItem>
                                    
                                    </List>
                                </Box>
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Zabranjeno je odlagati:</b></Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> kožne torbe i njima (po materijalima) slične proizvode
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> obuću
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> pernate jastuke i pokrivače
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={5}> {/*RECIKLABILNO*/}
                            <Box display={"flex"}  justifyContent={"flex-start"} sx={{backgroundColor:"#F9E9CA", 
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.9rem",
                                md:"1.1rem",
                                lg:"1.2rem",
                                xl:"1.3rem"
                            },
                            padding: {
                                xs:2,
                                sm:2,
                                md:3,
                                lg:4,
                            },
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                            },
                            
                            }} >
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Spremnici za reciklabilni otpad namijenjeni su za: </b></Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> otpadni papir, metal, plastiku, staklo i tekstil
                                        </ListItem>

                                    </List>
                                </Box>
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Zabranjeno je odlagati:</b></Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> problematični i opasni otpad
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> građevinski i glomazni otpad
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> elektronički otpad
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> tekući otpad
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={6}> {/*KRUPNI GLOMAZNI OTPAD*/}
                            <Box display={"flex"}  justifyContent={"flex-start"} sx={{backgroundColor:"#E2D5C6", 
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.9rem",
                                md:"1.1rem",
                                lg:"1.2rem",
                                xl:"1.3rem"
                            },
                            padding: {
                                xs:2,
                                sm:2,
                                md:3,
                                lg:4,
                            },
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "column",
                                lg: "column",
                            },
                            
                            }} >
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"100%",
                                        lg:"100%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Spremnici za krupni glomazni otpad namijenjeni su za:</b> </Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> kupaonska oprema
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> stvari za djecu
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> namještaj
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> kuhinjsku opremu
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> vrtnu opremu
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> ostali glomazni otpad - rolete, žaluzine, tende i sl., ljestve i samostojeće stepenice, zavjese i nosači zavjesa, vrata (npr. sobna, ulazna i dr.), staklo (okno), prozor, prozorski okvir i sl.)

                                        </ListItem>
                                    </List>
                                </Box>
                                {/*<Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'>Zabranjeno je odlagati:</Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> tetrapak ambalažu → odložiti u spremnik plastike
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> plastificirani, foto-papir, metalizirani i/ili zauljeni papir (primjerice izrađene fotografije) → odložiti u spremnik miješanog komunalnog otpada
                                        </ListItem>
                                    </List>
                                </Box>*/}
                            </Box>
                        </CustomTabPanel>
                         <CustomTabPanel value={value} index={7}> {/*RECIKLAZNO DVORISTE*/}
                            <Box display={"flex"}  justifyContent={"flex-start"} sx={{backgroundColor:"#E4FFCF", 
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.9rem",
                                md:"1.1rem",
                                lg:"1.2rem",
                                xl:"1.3rem"
                            },
                            padding: {
                                xs:2,
                                sm:2,
                                md:3,
                                lg:4,
                            },
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                            },
                            
                            }} >
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"100%", /*50%*/
                                        lg:"100%", /*50%*/
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Reciklažno dvorište namijenjeno je za: </b></Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> papir i karton, metali, organski otpad
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> stakleni ambalažni otpad, ravno prozorsko staklo
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> metalni ambalažni otpad (limenke), PET ambalaža, plastične gajbe od pića 
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> PE folija, stiropor, cijevi, građevinski otpad
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> stare baterije, bijela tehnika, otpadna elektro oprema, stari akumulatori, gume osobnih vozila
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> glomazni otpad iz kućanstava (namještaj)
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CheckIcon sx={{color:"green",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> stari lijekovi
                                        </ListItem>

                                    </List>
                                </Box>
                               {/*
                                <Box sx={{
                                    width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"50%",
                                        lg:"50%",
                                    }
                                }}>
                                    <Typography variant='inherit'><b>Zabranjeno je odlagati:</b></Typography>
                                    <List>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> problematični i opasni otpad
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> građevinski i glomazni otpad
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> električni i elektronički otpad
                                        </ListItem>
                                        <ListItem sx={{padding:0,display:"flex",alignItems:"flex-start"}}>
                                            <CloseIcon sx={{color:"red",paddingRight:1,paddingTop:{xs:0,sm:0,md:1}}}/> tekući otpad
                                        </ListItem>
                                    </List>
                            </Box> */} 
                            </Box>
                        </CustomTabPanel>
                    </Box>
                </Box>
            
            </Box>
        </Box>
        <LandingPageFooter/>
    </Box>
    )
};




export default BlockRazvrstanje;