import React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

//MUI
import Typography from "@mui/material/Typography";

//Custom components
import { formats } from "@/lib/formats";

//Types
import { IDialogToolbarCreationDetails } from "@/@types/ui/Dialog";

const DialogToolbarCreationDetails: FunctionComponent<IDialogToolbarCreationDetails> = (props) => {
  const { lowercase, record } = props;
  const { t } = useTranslation();

  if (!record) {
    return null;
  }

  const typogprahyStyle = {
    ml: 10,
    mr: 20
  };

  return (
    <div>
      {record.created_on ? (
        <Typography align="right" variant="caption" component="p" sx={typogprahyStyle}>
          {t("common.created_by")} {record.created_by} {t("common.on_date")}{" "}
          {moment.utc(record.created_on).local().format(formats.timedate)}
        </Typography>
      ) : null}
      {record.modified_on ? (
        <Typography align="right" variant="caption" component="p" sx={typogprahyStyle}>
          {t("common.modified_by")} {record.modified_by} {t("common.on_date")}{" "}
          {moment.utc(record.modified_on).local().format(formats.timedate)}
        </Typography>
      ) : null}
    </div>
  );
};

export default DialogToolbarCreationDetails;
