import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import PinDropIcon from '@mui/icons-material/PinDrop';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box,Typography,Grid,Button,Link } from '@mui/material';

import waste from "../../images/nezbrinuti-otpad.jpg"

function BlockLocation()   {
    const navigate = useNavigate()

    return (
    <Box display={"flex"} justifyContent={"center"}>
        <Box   sx={{
            marginTop: 5,
            width:{
                xs:"95%",
                sm:"95%",
                md:"80%",
                lg:"70%",
            },
        }}>
            <Typography fontFamily={"Roboto slab"} fontWeight={700}  sx={{
                fontSize: {
                    xs:"2rem",
                    sm:"2rem",
                    md:"2.3rem",
                    lg:"2.5rem",
                    xl:"2.8rem"
                }
            }} variant={"h3"} >Prijava nepropisno odbačenog otpada</Typography>
           <Grid container columnSpacing={5} marginTop={5} justifyContent={"center"}>
                <Grid item xs={12} sm={12} md={12} lg={5} order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }} sx={{
                    marginX: {
                        xs: 5,
                        sm: 10,
                        md: 20,
                        lg: 0,
                        xl: 0
                    }
                }}>
                        <img src={waste} style={{ boxShadow: "-10px 10px 0px rgba(222,224,103,0.2)" }} width={"100%"}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={7} order={{xs:1,sm:1,md:1,lg:2,xl:2}} sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    paddingY: 2,
                    pr: 2
                }}>
                    <Typography variant="inherit" mt={2}
                    sx={{
                        borderLeft: "5px solid #DEE067",
                        paddingLeft: 2,
                        paddingY: 1,
                    }}>
                        Lokacije <Box sx={{ display:"inline",fontWeight: 'bold' }}>nepropisno odbačenog otpada</Box> možete prijaviti putem aplikacije ili putem <Link sx={{"&:hover":{color:"#0004EE",cursor:"pointer",}}} color={"#0004EE"} href={"/docs/obrazac.pdf"} download>obrasca</Link> kojega je potrebno popuniti i isti dostaviti osobno ili putem pošte na adresu <Box sx={{ display:"inline",fontWeight: 'bold' }}>Općina Jakovlje, Ulica Adele Sixta 2, 10297 Jakovlje</Box> ili na e-mail: <Link sx={{"&:hover":{color:"#0004EE",cursor:"pointer",}}} color={"#0004EE"} href="mailto:opcina@jakovlje.hr">opcina@jakovlje.hr</Link>.
                    </Typography>
                        <Box display={"flex"}  width={"100%"} sx={{
                            justifyContent: {
                                xs: "center",
                                sm: "center",
                                md: "center",
                                lg: "right",
                                xl: "right",
                            },
                        }}>
                            <Button fullWidth variant="contained" 
                                onClick={() => navigate("/karta#prijava-divljeg-odlagalista")}
                                sx={{
                                backgroundColor: "#DEE067",
                                width: "fit-content",
                                borderRadius: "0px",
                                justifyContent: "space-between",
                                color: "black",
                                marginY:2,
                                fontSize: {
                                    xs:"0.8rem",
                                    sm:"0.8rem",
                                    md:"0.9rem",
                                    lg:"1rem",
                                    xl:"1.1rem"
                                },
                                
                                fontFamily:"Roboto slab",
                                "&:hover":{
                                    backgroundColor:"#DEE067",
                                    color:"black",
                                },
                                }}>
                                <PinDropIcon sx={{marginRight:2}} fontSize='large' />
                                <span>Prijavi nepropisno odbačen otpad</span>
                            </Button>
                        </Box>
                </Grid>
            </Grid>
        </Box>
    </Box>
    )
}

export default BlockLocation;