import LoginPage from "@/views/Auth/LoginPage";
import Registration from "@/views/Auth/RegistrationPage";
import PasswordResetRequestPage from "@/views/Auth/PasswordResetRequestPage";
import PasswordResetPage from "@/views/Auth/PasswordResetPage"

//Types
import { RouteType } from "@/@types/common";
import { MenuType } from "@/@types/common";

//Components
import AdminHomePage from "@/views/Administration/AdminHomePage";
import LogPage from "@/views/Administration/Log/LogPage";
import SettingsPage from "@/views/Administration/Settings/SettingsPage";
import UsersPage from "@/views/Administration/UsersPage/UsersPage";
import GISHomePage from "@/views/GIS/GISHomePage";
import MapPage from "@/views/GIS/MapPage/MapPage";
import GraphPage from "@/views/GraphPage/GraphPage";
import HomePage from "@/views/HomePage/HomePage";
import ProfilePage from "@/views/ProfilePage/ProfilePage";
import TestPage from "@/views/TestPage/TestPage";
import TestView from "@/views/TestPage/TestView";
import PublicMap from "@/views/HomePage/PublicMap"
import NewsPage from "@/views/NewsPage/NewsPage"
import NewsArticlePage from "@/views/NewsPage/NewsArticlePage"
import LandingPage from "@/views/LandingPage/LandingPage"

const publicRoutes: RouteType[] = [
  { path: "/", name: "Map", component: LandingPage },
	{ path: "/login", name: "Login", component: LoginPage },
	//{ path: "/register", name: "Register", component: Registration},
  { path: "/password-reset-request", name: "Password Reset Request", component: PasswordResetRequestPage },
  { path: "/password-reset", name: "Reset Password", component: PasswordResetPage },

  //ex-private routes
  // { path: "/profile", name: "Profile", component: ProfilePage},
  { path: "/karta", name: "Map", component: PublicMap },
  { path: "/novosti", name: "Novosti", component: NewsPage },
  { path: "/novosti/:id", name: "Novosti", component: NewsArticlePage },
  // { path: "/gis/map", name: "Map", component: MapPage },
  // { path: "/gis", name: "GIS Home Page", component: GISHomePage },
	// { path: "/admin/log", name: "Log", component: LogPage },
	// { path: "/admin/users", name: "Map", component: UsersPage },
  // { path: "/admin/settings", name: "Admin Settings Page", component: SettingsPage },
  // { path: "/admin", name: "Admin Home Page", component: AdminHomePage },
  // { path: "/test/:id", name: "Test Page Detail", component: TestView },
  // { path: "/test", name: "Test Page", component: TestPage },
  // { path: "/graphs", name: "Graph Page", component: GraphPage },

	// { path: "/", name: "Home", component: HomePage }
	// { path: "/", name: "Home", component: GISHomePage }
];

export const navbarRoutes: MenuType[] = [
	{ path: "/karta", name: "map", ttoken: "menu.map", icon: "map" },
	//{ path: "/admin/reports", name: "reports", ttoken: "menu.reports", icon: "delete", needPermission:[P.Administration]  },
	{ path: "/novosti", name: "news", ttoken: "menu.news", icon: "newspaper" }
];


export default publicRoutes
