import { MenuType } from "@/@types/common";
import { Permissions as P } from "@/lib/permissions";

const menuRoutes: MenuType[] = [
	// { path: "/", name: "Home", ttoken: "menu.home", icon: "home" },
	{ path: "/admin/map", name: "map", ttoken: "menu.map", icon: "map" },
	// { path: "/users", name: "users", ttoken: "menu.users", icon: "group", needPermission:[P.Administration] },
	// { path: "/grading", name: "grading", ttoken: "menu.grading", icon: "grade", needPermission:[P.Administration]  },
	{ path: "/admin/reports", name: "reports", ttoken: "menu.reports", icon: "delete", needPermission:[P.Administration]  },
	{ path: "/admin/novosti", name: "news", ttoken: "menu.admin_news", icon: "newspaper" },
	// { path: "/test", name: "test", ttoken: "menu.test", icon: "category" },
	// { path: "/graphs", name: "graphs", ttoken: "menu.graphs", icon: "equalizer" }
];


export default menuRoutes;
