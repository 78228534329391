import React from "react";
import {Button,Typography,Grid} from "@mui/material";
import { useNavigate } from "react-router-dom";

type INavButtonProps = {
    text: string;
    icon: JSX.Element;
    color: string;
    path: string;
    
}

function NavButtonVertical(props : INavButtonProps)   {

    const { text, icon, color, path } = props;
    const navigate = useNavigate();

    return (
        <Button  
            key="one"
            onClick={() => navigate(path)}
            sx={{ 
                border: 0,
                borderBottomColor: color,
                position: "relative",
                left:"0px",
                backgroundColor: color,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "18px",
                borderRadius: "0px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                    backgroundColor: color, 
                    boxShadow: "none", 
                    left : "-150px", 
                    },
            }}>
            <Grid container>
                <Grid item mr={6}>
                   {icon}
                </Grid>
                <Grid item>
                    <Typography textAlign={"left"} variant="h5" fontFamily={"Roboto Slab"} color={"black"}>{text}</Typography>
                </Grid>
               
            </Grid>
        </Button>
    );
}

export default NavButtonVertical;