import React from "react";
import { Box,Hidden } from '@mui/material';
import homepagevid from "../../images/homepagevid.mp4";
import homepagemobilevid from "../../images/mobilevid.mp4";
import homepagevidnotext from "../../images/homepagevidnotext.mp4";
import ScrollDownButton from "@/views/LandingPage/subcomponents/ScrollDownButton";


function LandingPageVideo() {
  return (
    <Box
      width={"100%"}
      height={"100vh"} // Set the height of the parent div to 100vh
      position={"relative"}
      sx={{
        position: "static",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        overflow: "hidden", // Hide any overflowing content (video) within the parent div
      }}
    >
      
        <Hidden mdDown>
          <video loop autoPlay muted style={{
          width: "100%",
          height: "100%", // Set the height of the video to 100% to fill the parent div
          objectFit: "cover", // Ensure the video scales to cover the entire parent container
          objectPosition: "left", // Center the video within the parent container
        }}>
            <source src={homepagevid} />
          </video>
        </Hidden>
        <Hidden mdUp>
          <video loop autoPlay muted style={{
          width: "100%",
          height: "100%", // Set the height of the video to 100% to fill the parent div
          objectFit: "cover", // Ensure the video scales to cover the entire parent container
          objectPosition: "center", // Center the video within the parent container
        }}>
            <source src={homepagemobilevid} />
          </video>
        </Hidden>
        
      
      <ScrollDownButton first={true}/>
    </Box>
  );
}

export default LandingPageVideo;
