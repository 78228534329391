import React from "react";
import { useNavigate } from "react-router-dom";

// MUI
import { Box, Typography,Card,CardContent,CardMedia,CardActions,Button } from '@mui/material';

// Custom components
import HtmlParser from "@/components/HtmlParser/HtmlParser"

type INewsRecord = {
    id: string
    photos: any[]
    title: string
    content: string
}

type ICarouselNewsBox = {
    record: INewsRecord
}

function CarouselNewsBox(props : ICarouselNewsBox){
    const { record } = props
    const navigate = useNavigate();

    const image = record.photos.length > 0 ? record.photos[0].content : null

    return (
        <Card  className="unselectable" sx={{
            display:"flex",
            flexDirection: {
                lg: "row",
                sm: "column",
                xs: "column"
            },
            minWidth:"100%"
        }}>
            
            <CardMedia 
                component="img"
                src={`data:image/jpeg;base64, ${image ? image : ""}`}
                alt="news image"
                sx={{
                    objectFit: "cover",
                    width: {
                        xs: "100%",
                        sm: "100%",
                        lg: "50%",
                    },
                    height: {
                        xs:"40%",
                        sm:"40%",
                        lg:"100%",
                    },
                }}
            />
            <CardContent sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: {
                    xs: "100%",
                    sm: "100%",
                    lg: "50%",
                },
                height: {
                    xs: "60%",
                    sm: "60%",
                    lg: "100%",
                },
                }}>
                <Box>
                    <Typography fontFamily={"Roboto slab"} gutterBottom variant="h5" component="div"
                    sx={{
                        fontSize: {
                        xs: "1.1rem",
                        sm: "1.2rem",
                        md: "1.3rem",
                        }
                    }}
                    >
                    <HtmlParser htmlString={record.title} title={true} />
                    </Typography>
                    <Typography fontFamily={"Roboto slab"} variant="body2" color="text.secondary"
                    sx={{
                        fontSize: {
                        xs: "0.9rem",
                        sm: "1.1rem",
                        md: "1.2rem",
                        },
                    }}
                    >
                    {record.content ? record.content.replace("&nbsp;", ' ').replace(/<(".*?"|'.*?'|[^'">])*?>/g, ' ').substring(0, 250) + "..." : ""}
                    </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                    <Button
                        size="small"
                        onClick={() => navigate("/novosti/" + record.id)}
                        sx={{
                            color: "gray",
                            fontFamily: "Roboto slab",
                            textDecoration: "underline",
                            textTransform: "none",
                            marginBottom:2
                        }}
                    >
                        Pročitaj više
                    </Button>
                </Box>
            </CardContent>

        </Card>
    )
}

export default CarouselNewsBox;