import React from 'react';

import Box from '@mui/material/Box';

import AppPage from "@/ui/AppPage/AppPage";
import NewsTable from "./NewsTable";

function NewsAdminPage() {
  return (
      <AppPage>
        <Box m={2}>
          <NewsTable/>
        </Box>
      </AppPage>
  );
}

export default NewsAdminPage;
