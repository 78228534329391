import React from 'react'
import  {Box,Typography,Grid,Paper,List,ListItem,ListItemText} from '@mui/material'

// Custom components
import Carousel from "./subcomponents/Carousel"
import CarouselBox from './subcomponents/CarouselLocationBox'
import BinTextBubble from './subcomponents/BinTextBubble'
import ScrollDownButton from './subcomponents/ScrollDownButton'


// Images
import map from "../../images/map.png"
import kantazelena from "../../images/kantazelena.png"
import kantanarancasta from "../../images/kantanarancasta.png"
import kraljevvrh from "@/images/kraljevvrh.jpg"
import mobilnodvoriste from "@/images/mobilnodvoriste.jpg"

function BlockDvorista() {
    
    return (
    <Box display={"flex"} justifyContent={"center"} position={"relative"}>
        <Box textAlign={"left"} sx={{
            marginTop: {
                xs: 10,
                sm: 10,
                md: 10,
                lg: 15,
            },
            marginBottom: {
                xs: 5,
                sm: 5,
                md: 10,
                lg: 10,
            },
            width:{
                xs:"95%",
                sm:"95%",
                md:"80%",
                lg:"70%",
            },
            }}>
                
            <Typography fontFamily={"Roboto slab"} fontWeight={700} fontSize={40} variant={"h3"}
            sx={{
                fontSize: {
                    xs:"2rem",
                    sm:"2rem",
                    md:"2.3rem",
                    lg:"2.5rem",
                    xl:"2.8rem"
                }
            
            }}
            >
                Informacije o reciklažnom i mobilnom reciklažnom dvorištu
            </Typography>

            <Box display={"flex"}  sx={{
                flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                },
                justifyContent: "space-around",
                alignItems: {
                    xs: "center",
                    sm: "center",
                    md: "flex-start",
                    lg: "flex-start",
                },
                marginY: {
                    xs: 5,
                    sm: 5,
                    md: 5,
                    lg: 5,
                },
                }}>
                    
                <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} color={"white"}  sx={{
                    padding: 3,
                    width:{
                        xs:"80%",
                        sm:"70%",
                        md:"40%",
                    },
                    height:"100%",
                    marginBottom: {
                        xs: 5,
                        sm: 5,
                        md: 0,
                        },
                        fontSize: {
                            xs:"0.9rem",
                            sm:"1rem",
                            md:"1.2rem",
                            lg:"1.2rem",
                        },
                    backgroundColor: "#83BE55",
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                    }}>
                        <Typography fontFamily={"Roboto slab"} variant='h5' sx={{
                            fontSize: {
                                xs:"1.2rem",
                                sm:"1.2rem",
                                md:"1.3rem",
                                lg:"1.5rem",
                                xl:"1.5rem"
                                }
                        }}><b>Kraljev Vrh</b> </Typography>
                        <Box width={"100%"} marginY={2}>
                             <img width={"100%"} src={kraljevvrh} style={
                                {
                                    aspectRatio: "16/9",
                                    objectFit:"cover",
                                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.4)",
                                }} ></img>
                        </Box>
                        Reciklažno dvorište „Kraljev Vrh“ nalazi se na adresi: <br/><u>Josipa Grilla 9c, Kraljev Vrh, Jakovlje</u>
                        <Box paddingTop={3}><b>Radno vrijeme:</b></Box>
                        <List>
                            <ListItem disablePadding>
                                pon:&nbsp;<Typography variant='inherit' fontWeight={"bold"}>od 08:00 do 16:00 sati</Typography>
                            </ListItem>
                            <ListItem disablePadding>
                                sri:&nbsp;<Typography variant='inherit' fontWeight={"bold"}>od 10:00 do 18:00 sati</Typography>
                            </ListItem>
                            <ListItem disablePadding>
                                sub:&nbsp;<Typography variant='inherit' fontWeight={"bold"}>od 08:00 do 14:00 sati</Typography>
                            </ListItem>
                        </List>
                    {/*<img  src={kantanarancasta}></img>
                    <BinTextBubble 
                    color={"#FFCF95"}
                    >
                        <Typography variant='inherit'>Reciklažno dvorište „Kraljev Vrh“ nalazi se na adresi: <b>Josipa Grilla 9c, Kraljev Vrh, Jakovlje</b>.</Typography>
                        
                        <Typography variant='inherit'>Radno vrijeme:</Typography>
                        <List sx={{
                        paddingLeft: {
                            xs: 2,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 4,
                        },
                      
                       }}>
                            <ListItem sx={{
                                listStyleType: 'disc',
                                display: 'list-item'
                            }}>
                                ponedjeljkom <b>od 08:00 do 16:00 sati</b>
                            </ListItem>
                            <ListItem sx={{
                                listStyleType: 'disc',
                                display: 'list-item'
                            }}>
                                srijedom <b>od 10:00 do 18:00 sati</b>
                            </ListItem>
                            <ListItem sx={{
                                listStyleType: 'disc',
                                display: 'list-item'
                            }}>
                                subotom <b>od 08:00 do 14:00 sati</b>
                            </ListItem>
                       </List>
                        </BinTextBubble> */}


                </Box>
        
                <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} color={"white"}   sx={{
                    padding: 3,
                    width:{
                        xs:"80%",
                        sm:"70%",
                        md:"40%",
                    },
                    height:"100%",
                    marginBottom: {
                        xs: 5,
                        sm: 5,
                        md: 0,
                        },
                        
                    fontSize: {
                        xs:"0.9rem",
                        sm:"1rem",
                        md:"1.2rem",
                        lg:"1.2rem",
                    },
                    backgroundColor: "#83BE55",
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                    }}>
                       <Typography fontFamily={"Roboto slab"} variant='h5' sx={{
                            fontSize: {
                                xs:"1.2rem",
                                sm:"1.2rem",
                                md:"1.3rem",
                                lg:"1.5rem",
                                xl:"1.5rem"
                                }
                        }}><b>Mobilna dvorišta</b></Typography>
                        <Box marginY={2} padding={0}>
                             <img width={"100%"} src={mobilnodvoriste} style={{
                                aspectRatio: "16/9",
                                objectFit:"cover",
                                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.4)",
                             }}
                             ></img>
                        </Box>
                        Mobilno reciklažno dvorište jednom svaka tri mjeseca postavlja se na javnim površinama u naseljima:
                        <List
                        sx = {{
                        listStyleType: 'disc',
                        pl: 2,
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        },
                        }}>
                            <ListItem disablePadding>
                               u naselju Jakovlje, na javnoj površini na adresi Dvorska 1a, Jakovlje
                            </ListItem>
                            <ListItem disablePadding >
                                u naselju Igrišće, na javnoj površini na adresi Gajeva 81, Igrišće
                            </ListItem>
                            <ListItem disablePadding>
                                u naselju Kraljev Vrh, na javnoj površini na adresi Duga ulica 2, Kraljev Vrh
                            </ListItem>
                        </List>
                    {/*<img src={kantazelena}></img>
                    <BinTextBubble 
                    color={"#6BB97D"}>
                       <Typography variant='inherit'>Mobilno reciklažno dvorište jednom svaka tri mjeseca postavlja se na javnim površinama u naseljima:</Typography>
                       <List sx={{
                        paddingLeft: {
                            xs: 2,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 4,
                        },
                       }}>
                            <ListItem sx={{
                                    listStyleType: 'disc',
                                    display: 'list-item'
                                }}>
                               u naselju Jakovlje, na javnoj površini na adresi <b>Dvorska 1a, Jakovlje</b>
                            </ListItem>
                            <ListItem sx={{
                                listStyleType: 'disc',
                                display: 'list-item'
                            }}>
                                u naselju Igrišće, na javnoj površini na adresi <b>Gajeva 81, Igrišće</b>
                            </ListItem>
                            <ListItem sx={{
                                listStyleType: 'disc',
                                display: 'list-item'
                            }}>
                                u naselju Kraljev Vrh, na javnoj površini na adresi <b>Duga ulica 2, Kraljev Vrh</b>
                            </ListItem>
                       </List>
                        </BinTextBubble>*/}

                </Box>
            </Box>
          
        </Box>
        <ScrollDownButton first={false}/>
    </Box>
    )
};

export default BlockDvorista