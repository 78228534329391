import { IModel } from "@/@types/models/model";
import { buttonFields } from "./helpers/buttonFields";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { addressFields } from "./helpers/addressFields";
import { alarmFields } from "./helpers/alarmFields";
import { userFields } from "./helpers/userFields";

const News: IModel = {
  title: "Zeleni otoci",
  exportFileName: "zeleni_otoci",
  apiPath: "zeleni-otoci/",
  forms: {
    default: {
      fields: [
        "title",
      ]
    },
    "infoCard": {
      fields: ["lokacija"],
    },
  },
  listViews: {
    default: {
      fields: [
        "id",
      ]
    }
  },
  fields: [
    coreFields.id,
    {
        title: "Lokacija",
        source: "lokacija",
        ttoken: "reciklazna_dvorista.lokacija",
        type: "text",
    },
    {
      title: "Tip",
      source: "tip",
      ttoken: "reciklazna_dvorista.tip",
      type: "text",
  },
  {
    title: "geometry",
    source: "geom",
    ttoken: "common.objectid",
    type: "wkt",
    readonly: true
  },
  {
    title: "Tipovi otpada",
    source: "tipovi_otpada",
    ttoken: "zeleni_otoci.tipovi_otpada",
    type: "text",
    readonly: true
  },
  ]
};

export default News;
