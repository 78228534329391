import * as React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//MUI
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// MUI Icons
import LoginIcon from "@mui/icons-material/Login";

//Custom Components
import { appHeaderTheme } from "@/components/ThemeContext/ThemeProvider";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import ThemeSwitch from "@/ui/Header/ThemeSwitch";
// import LanguageSelector from "@/ui/Header/LanguageSelector";
import AccessibilityMenu from "@/ui/Header/AccessibilityMenu";
import HeaderIconButton from "@/ui/Header/HeaderIconButton";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import {navbarRoutes} from "@/routes/public"

const PublicHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  

  const toolbarStyle = {
    "& > button": {
      mx: 1
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: "primary.main"
  };
  
  const isHome = location.pathname == '/'

  return (
    <div id="header" style={{ flexGrow: 1 }}>
      <MuiThemeProvider
        theme={(outerTheme) => ({
          ...outerTheme,
          palette: {
            ...outerTheme.palette,
            secondary: appHeaderTheme.palette.secondary
          }
        })}
      >
        <AppBar position="fixed" color="secondary">
          <Toolbar variant="dense" sx={toolbarStyle}>
            <ToolbarFillContent>
              <Box sx={{padding: 1, display: "flex", flexDirection: "row", justifyContent: "start", width:"100%"}}>
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ color: "#fff", fontSize: "1.3rem", flexGrow: 1, textAlign: "center", justifyContent: "center" }}
                >
                  <NavLink
                    to="/"
                    style={{
                      color: "#fff",
                      textDecoration: "none"
                    }}
                  >
                    {isSmallScreen ? t("app_title") : t("app_title_mobile")}
                  </NavLink>
                </Typography> 
                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "end", gap: isSmallScreen ? 5:1, marginRight: isSmallScreen ? "10%" : "5%", flexGrow: "4", alignItems: "center"}}>
                  {
                    navbarRoutes.map((route, index) =>{
                      return (
                        <NavLink 
                          key={"navbarItem-" + index} 
                          style={{
                            color: "#fff", 
                            fontSize: "1.15rem",
                            textAlign: "center",
                            textDecoration: "none" 
                          }}
                          to={route.path}
                        > 
                        { t(route.ttoken) }
                        </NavLink>)
                    })
                  }
                </Box>
              </Box>
            </ToolbarFillContent>
            <AccessibilityMenu />
            {/* <ThemeSwitch /> */}
            {/* <LanguageSelector /> */}
            <HeaderIconButton
              onClick={() => {
                navigate("/login");
              }}
              desc="login"
            >
              <LoginIcon />
            </HeaderIconButton>
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    </div>
  );
};

export default PublicHeader;
