import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

// MUI
import { Box, Typography,Button,IconButton } from "@mui/material";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Contexts
import LoaderContext from "@/components/LoaderContext/LoaderContext";

// Custom components
import dataController from "@/lib/dataController";
import Carousel from "./subcomponents/Carousel";
import CarouselNewsBox from "./subcomponents/CarouselNewsBox";
import ScrollDownButton from "@/views/LandingPage/subcomponents/ScrollDownButton";
// Models
import modelNews from "@/models/news";

// Types
import { DataResponse, DCRecord } from "@/@types/lib/dataController";

// Graphics

import newsbg from "@/images/newsbg.jpg";
import { url } from 'inspector';
import { Moment } from 'moment';

function BlockNews() {
    const navigate = useNavigate()
    const loaderContext = useContext(LoaderContext);
    const dc = new dataController(modelNews);

    // STATES
    //---------------------------------------------------------------------------------------------------

    const [records, setRecords] = useState<any>([]);

    // FUNCTIONS
    //---------------------------------------------------------------------------------------------------

    const refreshRecords = () => {
        loaderContext?.toggleLoading(true);
        dc.GetData("news/?limit=3")
          .then((resp: DataResponse) => {
            if (resp.success && resp.data && Array.isArray(resp.data)) {
              const publishedNews = resp.data.filter(news => (news.date_time as Moment).toDate() < new Date() )
              setRecords(publishedNews);
            }
          })
          .finally(() => {
            loaderContext?.toggleLoading(false);
          });
      };

    // EFFECTS
    //---------------------------------------------------------------------------------------------------

    useEffect(() => {
        refreshRecords();
      }, []);

    //RETURN
    //---------------------------------------------------------------------------------------------------

    return (
        <Box width={"100%"} minHeight={"100vh"} display={"flex"} justifyContent={"center"} position={"relative"}
        sx={{
            backgroundImage: `url(${newsbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.8)",
            alignItems:"center",
             }}>
            <Box  textAlign={"left"} sx={{
                marginTop:5,
                marginBottom:12,
                width:{
                    xs:"75%",
                    sm:"65%",
                    md:"60%",
                    lg:"70%",
                },
                height: "100%",
            }}>
                <Typography fontFamily={"Roboto slab"} color={"white"} fontWeight={700} fontSize={40} variant={"h3"}
                sx={{
                    fontSize: {
                        xs:"2rem",
                        sm:"2rem",
                        md:"2.3rem",
                        lg:"2.5rem",
                        xl:"2.8rem"
                    }
                }}>Novosti</Typography>
                <Box  sx={{
                    textAlign:"center",
                }}>
                    <Carousel progressColor="rgb(114,241,188)" snap={true}>
                        { records ? records.map((record: any) => {
                            if (!record.active) return null
                            return (
                                <CarouselNewsBox
                                    key={record.id}
                                    record={record}
                                />
                            )
                        })
                        : null }
                        
                    </Carousel>
                </Box>
                <Box sx={{
                    textAlign:"center",
                }}>
                    <Button  variant="contained"
                        onClick={() => navigate("/novosti")}
                        sx={{
                            backgroundColor:"#72F1BC",
                            color:"black",
                            marginTop:10,
                            "&:hover":{
                                backgroundColor:"#72F1BC",
                                color:"black",
                            },
                            width:"fit-content",
                            fontSize: {
                                xs:"0.8rem",
                                sm:"0.8rem",
                                md:"0.9rem",
                                lg:"1rem",
                                xl:"1.1rem"
                            },
                            fontFamily:"Roboto slab",
                        }}>
                        <NewspaperIcon sx={{marginRight:2}} fontSize='large'   ></NewspaperIcon>
                        Vidi sve novosti
                    </Button>
                </Box>
            </Box>
 
           <ScrollDownButton first={false}/>
        </Box>
    )
}
export default BlockNews;