import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

function ScrollToTop() {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <>
      {showScroll && (
        <Button
          className={`scrollTop ${showScroll ? "popup-animation" : ""}`} // Add the popup-animation class conditionally
          onClick={scrollToTop}
          variant="contained"
          sx={{
            position: "fixed",
            bottom: {
              xs:50,
              sm:80,
              md:10,
            },
            right: 10,
            width: {
              xs: 50,
              sm: 50,
              md: 50,
              xl: 65
            },
            minWidth: {
              xs: 50,
              sm: 50,
              md: 50,
              xl: 65
            },
            height: {
              xs: 50,
              sm: 50,
              md: 50,
              xl: 65
            },
            borderRadius: "50%",
            backgroundColor: "#568632",
            color: "white",
            "&:hover": {
              backgroundColor: "#568632",
            },
            padding: 0
          }}
        >
          <ArrowCircleUpIcon  fontSize="large" sx={{
            transform: {
              xs: "scale(1)",
              sm: "scale(1)",
              md: "scale(1)",
              xl: "scale(1.5)"
            }
            }} />
        </Button>
      )}
    </>
  );
}

export default ScrollToTop;
