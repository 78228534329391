import React, { useContext, Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//MUI
import { Box, Typography, Button, Divider, Checkbox, FormControlLabel, IconButton} from "@mui/material"

// MUI Icons
import InfoIcon from '@mui/icons-material/Info';

import ButtonControl from "@/components/Map/Controls/OpenLayersExt/ButtonControl";
import GridContainer from "@/ui/Grid/GridContainer";
import FormContent from "@/components/FormContent";
import dataController from "@/lib/dataController";
import modelDumpsiteReport from "@/models/dumpsiteReport"
import useFormController from "@/components/useFormController";
import TermsDialog from "@/views/GIS/MapPage/TermsDialog"
import DialogContext from "@/ui/DialogContext/DialogContext";

import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import { useNavigate } from "react-router";

const MODE = 'insert'
const FORM = "default"

const DumpsiteReportPane: FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { isReporting , setIsReporting, changeDrawType, feature, refreshLayers} = props

  const [termsAccepted, setTermsAccepted] = useState(false)

  const dc = new dataController(modelDumpsiteReport)
  const navigate = useNavigate();

  const { record, validation, fields, onFieldChange, doDelete, doValidate } = useFormController({
    mode: MODE,
    form: FORM,
    dc: dc,
    onClose: close,
  });

  // FUNCTIONS
  //---------------------------------------------------------------------------------------------------
  
  function handleClick() {

    setIsReporting((prev: boolean) => !prev);
    changeDrawType("Point");
    if(window.innerWidth < 768) navigate("/karta");
  }

  function handleFieldChange(value: any, source: string) {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  const handleInsert = () => {
    const extent = "{" + feature.getGeometry()?.getExtent().toString() + "}"

    const isValid = doValidate();
    if(isValid){
        
        dc.InsertRecord({
            author: record.author,
            email: record.email ? record.email : null,
            note: record.note,
            geom: feature,
            extent: extent,
            photos: record.photos
        }, "maps/1/divlja-odlagalista").then((result) => {
            // @ts-ignore
            if (result.success) {
                refreshLayers();
                snackbarContext.showNotification(t("dump_report.report_success"), "success");
            } else {
                snackbarContext.showNotification(t("dump_report.report_fail"), "error");
            }
        }
        )
    }else {
        snackbarContext.showNotification(t("dump_report.report_fail"), "error");
    }
  }

  function showTerms() {
    dialogContext.showDialog(TermsDialog, {
      type: "info",
      mode: "insert",
      form: "default",
      onClose: dialogContext.hideDialog
    });
  }


  // EFFECTS
  //---------------------------------------------------------------------------------------------------

  useEffect(() => {
    const coords = feature ? feature.getGeometry().getCoordinates() : null;
    if (!coords) return

    console.log('IS RED', isReporting)

    setIsReporting(false)
    changeDrawType(undefined)

    const coordsString = `${Math.round(coords[0])}, ${Math.round(coords[1])}`

    if (onFieldChange) {
      onFieldChange(coordsString, "coords");
    }

  }, [feature])

  function acceptTermLabel() {

    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography>Prihvaćam uvjete korištenja</Typography>
        <IconButton>
          <InfoIcon onClick={showTerms} sx={{ color: '#187bcd'}}/>
        </IconButton>
      </Box>
    )
  }

  //RETURN
  //---------------------------------------------------------------------------------------------------
  
  return (
    <Fragment>
      {/* <Typography variant={headingVariant}
                  gutterBottom={true}>
        {t("map:dump_report.explain_report_header")}
      </Typography>
      <Typography variant={textVariant}
                  gutterBottom={true}>
        {t("map:dump_report.explain_report")}
      </Typography> */}
      <Box m={2}>
        <GridContainer>
          <Typography sx={{ fontSize: "0.8rem", textAlign: 'justify', ml: 2, mt:2 }}>
            Molimo vas da ispunite formu ispod sa svojim imenom, prezimenom i email adresom. Vaša email adresa nam je neophodna kako bismo mogli komunicirati s vama o detaljima vaše prijave. Vaša informacija je sigurna s nama i koristit će se samo u svrhu koordinacije vaše prijave.
          </Typography>
          <FormContent
              fieldNames={dc.getFormFieldsNames("default")}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode={MODE}
              columns={1} 
          />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Button
              value="point"
              color="primary"
              onClick={handleClick}
              variant={ isReporting ? "text" : "outlined" }
              startIcon={<i className="fas fa-map-pin" />}
            >
              {t("map:dump_report.pick_location_button")}
            </Button>
          </Box>
        </GridContainer>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
        <FormControlLabel sx={{mb: 1}} control={<Checkbox checked={termsAccepted} onChange={() => setTermsAccepted(prevState => !prevState)}/>} label={acceptTermLabel()}/>
        <Button
          value="point"
          color="primary"
          disabled={!feature || !record.author || !record.note || !termsAccepted}
          onClick={handleInsert}
          variant="contained"
          sx={{alignSelf: 'stretch'}}
          startIcon={<i className="fas fa-exclamation-circle" />}
        >
          {t("map:dump_report.report_location_button")}
        </Button>
      </Box>
    </Fragment>
  );
};

export default DumpsiteReportPane;

