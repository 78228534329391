import React, { useEffect, useState, useContext, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//MUI
import { Box, Button} from "@mui/material";

//MUI Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";
import AppPage from "@/ui/AppPage/AppPage";
import PhotoCentricTable from "./PhotoCentricTable";
import PhotoCentricMiniMap from "./PhotoCentricMiniMap";
import PhotoCentricThumbs from "./PhotoCentricThumbs";
import useWindowSize from "@/components/useWindowSize";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import dataController from "@/lib/dataController";
import DialogContext from "@/ui/DialogContext/DialogContext";
import DumpsiteReportDialog from "@/views/GIS/MapPage/DumpsiteReportDialog"

//Types
import { LoaderContextType } from "@/@types/context/LoaderContext";
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";
import { IDataController, DCRecord } from "@/@types/lib/dataController";
import { Size } from "@/components/useWindowSize";
import { DoDeleteResponse } from "@/@types/components/formController";
import { ConfirmResult } from "@/@types/lib/confirmDialog";

import { IModel } from "@/@types/models/model";
import { Permission } from "@/@types/common";
import { RowActionFn } from "@/@types/ui/Table";

type PhotoCentricPageParams = {
  needPermission?: [Permission]
  titleToken: string,
  model: IModel,
  mapId: number
  onCustomTableAction?: RowActionFn,
  viewName?: string
}

function PhotoCentricPage(props: PhotoCentricPageParams) {
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const [selectedRecord, setSelectedRecord] = useState<DCRecord | null>(null);
  const [showImages, setShowImages] = useState(false);
  const [imgData, setImgData] = useState<any>(null);
  const [objExtent, setObjExtent] = useState<number[]>([]);

  const [records, setRecords] = useState<DCRecord[]>([]);

  const size: Size = useWindowSize();

  const { needPermission, titleToken, model, mapId, onCustomTableAction, viewName } = props;

  const [searchParams] = useSearchParams();

  const dc: IDataController = new dataController(model);

  const rec_id = searchParams.get("rec_id");
  const int_rec_id = rec_id ? parseInt(rec_id, 10) : undefined;

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success && Array.isArray(resp.data)) {
        setRecords(resp.data)
        // if (onLoad) {
        //   onLoad(resp.data);
        // }
        if (resp.data.length === 0) {
          snackbarContext.showNotification("messages.nodata", "warning");
        }
        if (int_rec_id !== undefined) {
          const rec = resp.data.find(x => x.id === int_rec_id);
          if (rec) {
            setSelectedRecord(rec);
          }
        }
      };
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const doDelete = (): Promise<DoDeleteResponse> => {
    const id = selectedRecord?.id as number

    if (id === undefined || id === null) {
      return Promise.reject({success: false, error: "Unknonw id"});
    }

    const confirmDelete = (): Promise<ConfirmResult> => {
      const title = t("cdialogs.are_you_sure");
      const text = t("cdialogs.once_deleted")
      const confirmButtonText = t("cdialogs.yes_delete")
      const cancelButtonText = t("cdialogs.no_cancel")
  
      return dialogContext.showConfirmDialog({title, text, confirmButtonText, cancelButtonText })
    }

    return confirmDelete()
      .then(result => {
        if (result.canceled) {
          return Promise.reject({ success: false, canceled: true });
        }
        if (result.confirmed) {
          return dc.DeleteRecord(id)
            .then(response => {
              // if (onDataUpdate) {
              //   onDataUpdate();
              // }
              return Promise.resolve({ success: true });
            })
            .catch(response => {
              return Promise.reject({ success: false, error: response.error ? response.error : "Greška prilikom brisanja podataka" });
            })

        }
        else {
          return Promise.reject({ success: false });
        }
      })

  }

  function handleEdit(evt: any) {
    const recordId = selectedRecord?.id

    dialogContext.showDialog(DumpsiteReportDialog, {
      form: "edit",
      mode: "update",
      dataId: recordId,
      onClose: refreshRecords
    });
  }

  function handleDelete() {
    doDelete()
    .then((result: any) => {
        if (result.success) {
          snackbarContext.showNotification(t("dump_report.report_delete_success"), "success");
          refreshRecords();
          // handleCommentDialogClose({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          snackbarContext.showNotification(t("dump_report.report_delete_error"), "error");
        }
    })
  }

  const handleRecordSelect = (id: number, record: DCRecord | null) => {
    
    if (selectedRecord !== null && id === selectedRecord.id) {
      setSelectedRecord(null);
    } else {
      const rec = records.find(x => x.id === id);
      
      if (rec) {
        setSelectedRecord(rec);
      }

      //setSelectedRecord(record);
    }
    // if (!showImages && id) {
    //   setShowImages(true);
    // }
  };

  useEffect(() => {
    setShowImages(selectedRecord !== null);
  }, [selectedRecord]);

  useEffect(() => {
    
    if (int_rec_id === undefined) {
      setSelectedRecord(null);
    } else {
      const rec = records.find(x => x.id === int_rec_id);
      
      if (rec) {
        setSelectedRecord(rec);
      }
    }
  }, [int_rec_id])

  const firstRowHeight = size.height ? `${(size.height - 50) * 0.6}px` : "60%";
  const secondRowHeight = size.height ? `${(size.height - 50) * 0.4}px` : "40%";
  // const mapWidth = secondRowHeight;

  return (
    <Fragment>

    
      <Box m={0} style={{ height: firstRowHeight }}>
        <PhotoCentricTable
          records={records}
          onRowSelect={handleRecordSelect}
          onRowAction={onCustomTableAction}
          rowSelectedCustom={selectedRecord ? selectedRecord.id as number : undefined}

          titleToken={titleToken}
          dc={dc}
          viewName={viewName}
        />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingX: 3, marginTop: "-40px", gap: "20px" }}> {/* If you are fixing this, I am really sorry */}
          <Button variant="outlined" disabled={!selectedRecord} startIcon={<EditIcon />} onClick={handleEdit}>{t("buttons.edit")}</Button>
          <Button variant="outlined" disabled={!selectedRecord} startIcon={<DeleteIcon />} onClick={handleDelete}>{t("buttons.delete")}</Button>
        </Box>
      </Box>
      <GridContainer sx={{ height: secondRowHeight }}>
        <GridItem xs={3}>
          <PhotoCentricMiniMap
            onObjektSelect={handleRecordSelect}
            selectedObjekt={selectedRecord}
            _setObjExtent={setObjExtent}

            mapId={mapId}
          />
        </GridItem>
        <GridItem xs={9} m={0} p={0}>
          <PhotoCentricThumbs
            baseRecordPath={model.apiPath}
            recordId={selectedRecord ? selectedRecord.id as number : null}
          />
        </GridItem>
      </GridContainer>
    </Fragment>
  );
}

export default PhotoCentricPage;
