import React from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import DraggableDialog from "../../../ui/Dialog/DraggableDialog";
import DialogHeader from "../../../ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";

import { IDialogProps } from "@/@types/ui/DialogContext";
import { Typography } from "@mui/material";

function TermsDialog(props: IDialogProps) {
    const { onClose } = props;

    function handleClose() {
        onClose()
    }

    return (
        <DraggableDialog open={true} maxWidth={"sm"} onClose={handleClose}>
            <DialogHeader>
                <Toolbar variant="dense" disableGutters={true}>
                    <DialogToolbarHeading>Uvjeti korištenja</DialogToolbarHeading>
                    <ToolbarFillContent />
                    <DialogToolbarButtonClose onClick={handleClose} />
                </Toolbar>
            </DialogHeader>
            <DialogBody>
                <Box sx={{m: 2, display: 'flex', flexDirection: 'column', gap: "12px"}}>
                   <Typography sx={{fontSize: "0.9rem"}}>
                        Općina Jakovlje kao voditelj obrade osobne podatke obrađuje isključivo u svrhu uspostave sustava za zaprimanje obavijesti o nepropisno odbačenom otpadu i evidentiranje lokacija nepropisno odbačenog otpada, uz napomenu kako je takva obrada nužna zbog zakonitog provođenja predmetnog postupka. 
                   </Typography>
                   <Typography sx={{fontSize: "0.9rem"}}>
                        Pravna osnova za obavljanje djelatnosti, a time i obradu osobnih podataka svojih korisnika, proizlazi iz Zakona o lokalnoj i područnoj (regionalnoj) samoupravi (NN 33/01, 60/01, 129/05, 109/07, 125/08, 36/09, 150/11, 144/12, 19/13, 137/15, 123/17).
                   </Typography>
                   <Typography sx={{fontSize: "0.9rem"}}>
                        Predmetna dokumentacija koja sadrži osobne podatke prijavitelja, obzirom da je Općina Jakovlje tijelo javne vlasti u smislu Zakona o arhivskom gradivu i arhivima (NN 61/18), pohranjuje se u skladu s odredbama toga Zakona.
                        Podnositelj prijave, budući da je obrada podataka nužna za provođenje zadaće koja se obavlja zbog javnog interesa, odnosno pri izvršavanju službene ovlasti voditelja obrade, može u smislu članka 21. stavka 6. Opće uredbe o zaštiti podataka podnijeti prigovor.  
                   </Typography>
                   <Typography sx={{fontSize: "0.9rem"}}>
                        Podnositelj prijave ima pravo od voditelja obrade zatražiti ispravak, brisanje i ograničenje obrade osobnih podataka, a voditelj obrade će postupiti po takvom zahtjevu ako se time ne narušavaju njegove pravne obveze. Budući da je obrada podataka nužna za obavljanje zadaća od javnog interesa, na istu se ne primjenjuje pravo na prenosivost podataka.
                        Osim u svrhu izvršavanja svojih javnopravnih i službenih ovlasti te zakonom propisanih obveza, osobni podaci koje voditelj obrade prikuplja ne čine se dostupnim trećim privatnim i pravnim subjektima te trećim zemljama.
                   </Typography>
                </Box>
            </DialogBody>
        </DraggableDialog>
    )
}

export default TermsDialog
