import React, { useEffect, useState, useContext, useRef, useTransition } from "react";

// MUI
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import { ImageList, ImageListItem, ImageListItemBar, Skeleton, Typography } from "@mui/material";

// MUI Icons
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from '@mui/icons-material/StarBorder';

// Custom Components

import modelImage from "@/models/images";
import DumpsiteReport from "@/models/dumpsiteReport";
import dataController from "@/lib/dataController";
import UserContext from "@/components/UserContext/UserContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import NavDrawerContext from "@/components/NavDrawerContext/NavDrawerContext";
import DialogContext from "@/ui/DialogContext/DialogContext";
import ImageDialog from "./PhotoCentricImageDialog";
import imgService from "@/services/imgService";
import useWindowSize from "@/components/useWindowSize";
import PhotoCentricThumbsFilter from './PhotoCentricThumbsFilter';

//Types
import { DialogContextType } from "@/@types/ui/DialogContext";
import { LoaderContextType } from "@/@types/context/LoaderContext";
import { DCRecord } from "@/@types/lib/dataController";
import { Size } from "@/components/useWindowSize";
import ThumbnailImageSlider from "@/components/ThumbnailImageSlider";
import { useTranslation } from "react-i18next";


const THUMB_WIDTH = 300;

export type ThumbFilters = {
  textFilter: string;
  selectedFilter: boolean;
}

type PhotoCentricThumbsProps = {
  baseRecordPath: string,
  recordId: number | null;
}

type IThumbsDictionary = {
  [key: number]: string
}

const PERCENTAGE = 5;

const initialFilterObject: ThumbFilters = {
  textFilter: "",
  selectedFilter: false
}

function PhotoCentricThumbs(props: PhotoCentricThumbsProps) {
  const dialogContext = useContext(DialogContext) as DialogContextType;
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const navDrawerContext = useContext(NavDrawerContext);

  const [filteredRecords, setFilteredRecords] = useState<DCRecord[]>([]);
  const [filterObject, setFilterObject] = useState<ThumbFilters>(initialFilterObject)
  const [records, setRecords] = useState<any[]>([]);
  const [thumbs, setThumbs] = useState<IThumbsDictionary>({});
  const size: Size = useWindowSize();
  const {t} = useTranslation();
  const imageListRef = useRef(null);

  const { baseRecordPath, recordId } = props;

  const dc = new dataController(DumpsiteReport);

  useEffect(() => {
    if (recordId) {
      loadThumbnails();
    }
  }, [recordId])

  const loadThumbnails = () => {

    loaderContext.toggleLoading(true);
    const endsWithSlash = baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1 ? true : false;
    dc.GetData(`${baseRecordPath}${endsWithSlash ? "" : "/"}${recordId}`).then((resp) => {
      if (resp.success) {
        const data = resp.data;
        if(data){
          const photos = (data as any).photos;
          setRecords(photos);
        }
      }
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  }




  let dravWidth = 240
  {
    navDrawerContext?.sidebarOpen ?
      dravWidth = 240 :
      dravWidth = 60
  }

  const navWidth = (window.outerWidth - (dravWidth + 300))

  return (
    
    <ImageList
        cols={filteredRecords.length}
        ref={imageListRef}
        sx={{
          width: "inherit"
          , overflowX: "auto"
          , overflowY: "hidden"
          , display: "flex"
          , height: "100%"
          , whiteSpace: "nowrap"
          , justifyContent: "start"
          , alignItems: "start"
        }}>
        {records && records.length > 0 ? (
          <ThumbnailImageSlider images={records} />
        ) : (
        <Box sx = {
          {
            width: "100%",
            height: "100%",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }
        }>
          <Typography fontSize={"1.2rem"}>
           {t("common.no_photos")}
          </Typography>
        </Box>
        )}
    </ImageList>
 )

}

export default PhotoCentricThumbs