import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";

//Material-UI Core Components
import { Typography, Collapse, Accordion, AccordionSummary, AccordionDetails, AccordionActions, Button, Divider, Box } from "@mui/material"

//Material-UI Icons
import DirectionsIcon from "@mui/icons-material/Directions";
import AddCommentIcon from '@mui/icons-material/AddComment';
import CommentIcon from '@mui/icons-material/Comment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';

//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import { formats } from "@/lib/formats";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import ModelTable from "@/ui/Table/ModelTable";
// import { dataService } from "@/services/dataService";
import FormContent from "@/components/FormContent";
import UserContext from "@/components/UserContext/UserContext";

import GradingDialog from "@/views/GIS/MapPage/GradingDialog";

import otok1 from "@/images/zeleni_otoci/otok1.jpg";
import otok2 from "@/images/zeleni_otoci/otok2.jpg";
import otok3 from "@/images/zeleni_otoci/otok3.jpg";
import otok4 from "@/images/zeleni_otoci/otok4.jpg";
import otok5 from "@/images/zeleni_otoci/otok5.jpg";
import otok6 from "@/images/zeleni_otoci/otok6.jpg";

// import ImageSlider from "@/components/ImageSlider";

// import modelCvjetnjaci from "Models/cvjetnjaci";
// import modelGrmovi from "Models/grmovi";
// import modelKlupe from "Models/klupe";
// import modelKosevi from "Models/kosevi";
// import modelPutevi from "Models/putevi";
// import modelStabla from "Models/stabla";
// import modelSumskeSastojine from "Models/sumske-sastojine";
// import modelTravnjaci from "Models/travnjaci";
// import modelPhotos360 from "Models/photos360";
import modelComments from "@/models/comments";
import modelReciklaznaDvorista from "@/models/reciklaznaDvorista";
import modelGrading from "@/models/grading";

// import DataDialog from "Views/MapPage/DataDialog";
// import InspectionDialog from "Views/MapPage/InspectionDialog";
import CommentDialog from "@/views/GIS/MapPage/CommentDialog"

//Types
import { RdInfoCardProps } from "@/@types/views/GIS/map";
import { number } from "prop-types";

function RdInfoCard(props: RdInfoCardProps) {
  const { t } = useTranslation();

  const initialRecod = props.featureData.record ? props.featureData.record : props.featureData.feature.properties;

  const [record, setRecord] = useState(initialRecod);
  // const [refreshCounter, setRefreshCounter] = useState(0);
  // const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  // const [imageLoadStatus, setImageLoadStatus] = useState({ imageLoading: true });
  const [expanded, setExpanded] = useState(false);
  const [comments, setComments] = useState<any>(null);

  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { featureData, onClose, handleGoogleNavigate } = props;

  const { type } = featureData;

  const getModel = () => {
    switch (type) {
      // case "stabla":
      //   return modelStabla;
      default:
        return modelReciklaznaDvorista;
    }
  };

  const selectImage = (id : number) => {
    switch (id) {
      case 1:
        return otok1;
      case 2:
        return otok2;
      case 3:
        return otok3;
      case 4:
        return otok4;
      case 5:
        return otok5;
      case 6:
        return otok6;
    }
  }

  const refreshComments = () => {
    const dc = new dataController(modelComments, `data/poi/${record.id}/comments`)
    if (record) {
      dc.GetData().then((resp) => {
        if(resp && resp.data) {
          setComments(resp.data)
        } else {
          setComments(null)
        }
      })
    }
  }

  useEffect(() => {
    if (userContext?.userType == 1) {
      refreshComments()
    }
  }, [record])

  const model = getModel();

  const dc = new dataController(model);

  const faIconClass = "fas fa-trash ";

  // const handleRefresh = function () {
  //   setRefreshCounter(refreshCounter + 1);

  //   setLoadStatus({ isLoading: true, isLoaded: false });
  // };

  const handleDetailCollapse = function () {
    setExpanded(!expanded);
  };

  const handleCommentDialogClose = (result: any) => {
    if (result.dataChanged) {
      refreshComments()
    }
  };

  const handleAddComment = () => {
    const dc = new dataController(modelComments, `data/poi/${record.id}/comments`)

    dialogContext.showDialog(CommentDialog, {
      dc: dc,
      mode: "insert",
      form: "default",
      dataId: record.id,
      onClose: handleCommentDialogClose
    });
  };

  const handleDeleteComment = (evt: any, comment: any) => {
    const dc = new dataController(modelComments, `data/poi/${record.id}/comments`)

    dc.DeleteRecord(comment.id)
    .then((result) => {
      refreshComments()
    })
  }

 const handleDataDialogClose = (dialogResult: any, recordId: number) => {
  if (dialogResult.dataChanged) {
    // dataService.getData(recordId).then((result) => {
    //   if (result.success) {
    //     setRecord(result.data);
    //   }
    // });
  }
};

  useEffect(() => {
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.feature) {
        setRecord(featureData.feature.properties);
      }
    }
    setRecord;
  }, [featureData]);

  const form = "infoCard";
  const title = record.tip ? record.tip as string : type ? t("layer." + type) : "Info";

  return (
    <Fragment>
      <MapCard
        sx={{
          backgroundColor: "background.default",
          minWidth: {
            xs: "200px",
            md: "350px"
          }
        }}
      >
        <MapCardHeader
          title={title}
          // subheader={t("common.id") + ": " + record.id}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
              {/* <Form dc={dc} mode="view" form={form} record={record}> */}
                <GridContainer spacing={0}>
                <FormContent
                  // dc={dc}
                  // form={form}
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={record}
                  
                />
                { record.tip == "Reciklažno dvorište"  ?
                  <Box>
                    <Typography sx={{ fontFamily: "Roboto", fontSize: "0.75rem"}}>Radno vrijeme:</Typography>
                    <Typography sx={{ fontFamily: "Roboto", fontSize: "0.75rem"}}> &nbsp;&nbsp;&nbsp;pon: od 08:00 do 16:00 sati</Typography>
                    <Typography sx={{ fontFamily: "Roboto", fontSize: "0.75rem"}}> &nbsp;&nbsp;&nbsp;sri: od 10:00 do 18:00 sati</Typography>
                    <Typography sx={{ fontFamily: "Roboto", fontSize: "0.75rem"}}> &nbsp;&nbsp;&nbsp;sub: od 08:00 do 14:00 sati</Typography>
                  </Box>
                : null}
                { record.tipovi_otpada ?
                  <Typography sx={{ fontFamily: "Roboto", fontSize: "0.75rem"}}>{`Tipovi otpada: ${record.tipovi_otpada}`}</Typography>
                : null}
                </GridContainer>
              {/* </Form> */}
              {
                !(record.tip == "Reciklažno dvorište" || record.tip == "Mobilno reciklažno dvorište") ?
                <Box width={"100%"}>
                  <img width={"100%"} src={
                    selectImage(record.id)
                  }></img>
                </Box>
                : null  

              }
            </GridItem>
            {/* <GridItem xs={6}>Image Placeholder</GridItem> */}
          </GridContainer>
        </MapCardContent>
        <MapCardActions>
          <Box sx={{
            display: "flex",
            width: "100%",
            gap: "5px",
            flexDirection: {
              xs: "column",
              md: "row"
            },
            alignItems: {
              xs: "flex-end",
              md: "normal"
            }
          }}>
          <MapCardActionButton onClick={() => handleGoogleNavigate(featureData.position)} startIcon={<DirectionsIcon/>}>
            {t("buttons.google_map")}
          </MapCardActionButton>
          { userContext?.userType == 1 ?
            <MapCardActionButton
            startIcon={<CommentIcon />}
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={handleDetailCollapse}
            aria-expanded={expanded}
            aria-label="expand"
            disabled={comments === null}
            stickyRight={true}
            >
                {t("buttons.comments_expand")}
              </MapCardActionButton>
            :
            <MapCardActionButton onClick={handleAddComment} startIcon={<AddCommentIcon/>} stickyRight={true}>
                {t("buttons.add_comment")}
              </MapCardActionButton>
          }
          </Box>
        </MapCardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {comments && expanded ? (
            <MapCardContent>
              <GridContainer spacing={0} style={{ display: "flex", flexDirection: "column" }}>
                {comments.map((comment: any, index: any) => {
                  return (
                    <Accordion key={"accordion-" + index} style={{ width: "100%" }}>
                      <AccordionSummary
                        key={"accordion-" + index}
                        expandIcon={<ExpandMoreIcon />}
                        id={"panel-" + index}
                        aria-controls="filter-time-content"
                      >
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                          <Typography variant="caption">
                            <b>{t("comment.autor")}</b>: {comment.autor}
                          </Typography>
                          <Typography variant="caption">
                            <b>{t("comment.email")}</b>: {comment.email}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="caption">
                          {comment.biljeska}
                        </Typography>
                      </AccordionDetails>
                      <Divider />
                      <AccordionActions>
                        <Button
                          size="small"
                          startIcon={<DeleteIcon />}
                          onClick={(evt) => handleDeleteComment(evt, comment)}
                        >
                          {t("buttons.delete")}
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  )
                })}

              </GridContainer>
            </MapCardContent>
          ) : null}
        </Collapse>
      </MapCard>
    </Fragment>
  );
}

export default RdInfoCard;
