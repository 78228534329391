import React, { useContext, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

//MUI
import { Box, Typography, IconButton, DialogActions, Toolbar, Checkbox, FormControlLabel } from "@mui/material";

// MUI Icons
import InfoIcon from '@mui/icons-material/Info';

//Custom Components
import DraggableDialog from "../../../ui/Dialog/DraggableDialog";
import DialogHeader from "../../../ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonConfirm from "@/ui/Dialog/ActionButtons/DialogActionButtonConfirm";
import DialogActionButtonCancel from "@/ui/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogActionButtonDelete from "@/ui/Dialog/ActionButtons/DialogActionButtonDelete";
import useFormController from "@/components/useFormController";
import GridContainer from "@/ui/Grid/GridContainer";
import FormContent from "@/components/FormContent";
import TermsDialog from "@/views/GIS/MapPage/TermsDialog"

import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import dataController from "@/lib/dataController";
import modelComments from "@/models/comments"
import { IDialogProps } from "@/@types/ui/DialogContext";

function CommentDialog(props: IDialogProps) {
    const { t } = useTranslation();
    const dialogContext = useContext(DialogContext);
    const snackbarContext = useContext(SnackbarContext);

    const [termsAccepted, setTermsAccepted] = useState(false)

    const form = "default"

    const { feature, mode, dataId, onClose } = props;

    const dc = new dataController(modelComments, `data/poi/${dataId}/comments`)

    const close = (result: any) => {
        if (onClose) {
            onClose(result);
        }
    
        dialogContext.hideDialog();
    };

    const { record, validation, fields, onFieldChange, doDelete, doInsert } = useFormController({
        mode: mode,
        recordId: dataId,
        form: form,
        dc: dc,
        onClose: close,
    });
    

    const handleFieldChange = (value: any, source: string) => {

        if (onFieldChange) {
          onFieldChange(value, source);
        }
    };
    

    const handleClose = (evt: SyntheticEvent) => {
        close({ success: false });
    }


    const handleInsert = () => {
        if (doInsert) {
            doInsert().then((result) => {
                if (result.success) {
                close({ dataChanged: true, action: "insert" });
                } else if (result.error) {
                const msg = t("error.addRecord") + result.error;
                snackbarContext.showNotification(msg, "error");
                } else if (result.validationPass === false) {
                //do nothing
                }
            });
        }
    }

    const handleUpdate = () => {
        console.log("Feature that updates:", feature)
        // const extent = "{" + feature.getGeometry()?.getExtent().toString() + "}"
    
        // dc.InsertRecord({
        //     id: feature.getId() as number,
        //     note: record.note,
        //     geom: feature,
        //     extent: extent
        // }).then((result) => {
        //     // TODO error handling
        //     if (result.success) {
        //         refreshLayers();
        //         close({ dataChanged: true, action: "update" });
        //         snackbarContext.showNotification("update", "success");
        //     } 
        //     }
        // )
    }

    const handleDelete = () => {
        doDelete()
        .then((result) => {
            if (result.success) {
            snackbarContext.showNotification("delete", "success");
            close({ dataChanged: true, action: "delete" });
            } else if (result.error) {
            const msg = t("error.delete") + result.error;
            snackbarContext.showNotification(msg, "error");
            }
        })
    }

    function acceptTermLabel() {

        return (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Typography>Prihvaćam uvjete korištenja</Typography>
            <IconButton>
              <InfoIcon onClick={showTerms} sx={{ color: '#187bcd'}}/>
            </IconButton>
          </Box>
        )
      }

      function showTerms() {
        dialogContext.showDialog(TermsDialog, {
          type: "info",
          mode: "insert",
          form: "default",
          onClose: () => dialogContext.hideDialog(2)
        }, 2);
      }

    const isFormValid = record.autor && record.email && record.biljeska && termsAccepted

    return (
        <DraggableDialog open={true} maxWidth={"sm"} onClose={handleClose}>
            <DialogHeader>
                <Toolbar variant="dense" disableGutters={true}>
                    <DialogToolbarHeading>{mode=="insert" ? t("titles.add_comment") : mode=="update" ? t("titles.edit_comment") : null}</DialogToolbarHeading>
                    <ToolbarFillContent />
                    <DialogToolbarButtonClose onClick={handleClose} />
                </Toolbar>
            </DialogHeader>
            <DialogBody>
                <Box m={2}>
                    <GridContainer>
                        <Typography sx={{ fontSize: "0.8rem", textAlign: 'justify', ml: 2, mt:2 }}>
                            Molimo vas da ispunite formu ispod sa svojim imenom, prezimenom i email adresom. Vaša email adresa nam je neophodna kako bismo mogli komunicirati s vama o detaljima vaše prijave. Vaša informacija je sigurna s nama i koristit će se samo u svrhu koordinacije vaše prijave.
                        </Typography>
                        <FormContent
                            fieldNames={dc.getFormFieldsNames("default")}
                            record={record}
                            validation={validation}
                            fields={fields}
                            onFieldChange={handleFieldChange}
                            mode={mode}
                            columns={1} 
                        />
                    </GridContainer>
                <FormControlLabel sx={{mb: 1}} control={<Checkbox checked={termsAccepted} onChange={() => setTermsAccepted(prevState => !prevState)}/>} label={acceptTermLabel()}/>
                </Box>
            </DialogBody>
            <DialogActions>
                <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
                <DialogActionButtonConfirm variant="contained" disabled={!isFormValid} onClick={mode == "insert" ? handleInsert : handleUpdate} />
                { mode == "update" ? <DialogActionButtonDelete variant="contained" onClick={handleDelete} /> : null }
            </DialogActions>
        </DraggableDialog>
    )
}

export default CommentDialog
