import React, { useState, useContext, FunctionComponent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

//Material-UI Icons
import PublicIcon from "@mui/icons-material/Public";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

//Custom Components
import useFormController from "@/components/useFormController";
import Icon from "@mui/material/Icon";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import FormAvatar from "@/ui/Form/FormAvatar";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogToolbarMenuButton from "@/ui/Dialog/DialogToolbarMenuButton";
import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import { GridContainer, GridItem} from "@/ui/Grid";
import FormContent from "@/components/FormContent";
import FormTabs from "@/ui/Form/FormTabs";
import { FormTab, FormTabPanel } from "@/ui/Form";
import DialogActionButtonClose from "@/ui/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "@/ui/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "@/ui/Dialog/ActionButtons/DialogActionButtonSave";
import DialogActionButtonCancel from "@/ui/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogActionButtonConfirm from "@/ui/Dialog/ActionButtons/DialogActionButtonUpload";
import DialogSubHeading from "@/ui/Dialog/DialogSubHeading";
import DialogToolbarMenuItemActivate from "@/ui/Dialog/ToolbarMenuItems/DialogToolbarMenuItemActivate";
import DialogToolbarMenuItemCopy from "@/ui/Dialog/ToolbarMenuItems/DialogToolbarMenuItemCopy";
import DialogToolbarMenuItemDraft from "@/ui/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDraft";
import DialogToolbarCreationDetails from "@/ui/Dialog/DialogToolbarCreationDetails";
import DynamicDialog from "@/ui/Dialog/DynamicDialog";

//Types
import { FormControllerProps, ClosingDetails } from "@/@types/components/formController";
import { IDialogProps } from "@/@types/ui/DialogContext";
import { FormContentColumns } from "@/@types/components/formContent";
import DialogActionButtonDelete from "@/ui/Dialog/ActionButtons/DialogActionButtonDelete";

const NewsDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const {t} = useTranslation();

  // const [currentTab, setCurrentTab] = useState(1);

  const {dc, form, mode, onClose} = props;
  const {record, validation, fields, subModels, doInsert, doUpdate, doClose, onFieldChange, dataChanged, doDelete} = useFormController(props as FormControllerProps);

  // const handleFieldChange = (value: any, source: string) => {
  //   if (onFieldChange) {
  //     onFieldChange(value, source);
  //   }
  // };

  // const handleTabChange = (value: number) => {
  //   setCurrentTab(value);
  // };

  const handleInsert = (evt: SyntheticEvent) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({dataChanged: true, action: "insert"});
        } else if (result.error) {
          const msg = "Greška prilikom dodavanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleUpdate = (evt: SyntheticEvent) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({dataChanged: true, action: "update"});
        } else if (result.error) {
          const msg = "Greška prilikom ažuriranja podataka! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt: SyntheticEvent) => {
    if (doClose) {
      doClose().then((result) => {
        close({dataChanged: false, action: "cancel"});
      })
      .catch(result => {
        if (result.shouldSave) {
          handleUpdate(evt);
        } else if (result.canceled) {
          //do nothing
        }
      });
    }
  };

  const handleDelete = () => {
    doDelete()
    .then((result) => {
        if (result.success) {
        snackbarContext.showNotification(t("dump_report.report_delete_success"), "success");
        close({ dataChanged: true, action: "delete" });
        } else if (result.error) {
        snackbarContext.showNotification(t("dump_report.report_delete_error"), "error");
        }
    })
}

  // const handleActivate = (evt: SyntheticEvent) => {
  // };

  // const handleDeactivate = (evt: SyntheticEvent) => {
  // };

  // const handleAnonymize = (evt: SyntheticEvent) => {
  // };

  const commonProps = {
    fieldNames: dc.getFormFieldsNames(form),
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: onFieldChange,
    mode: mode,
    columns: 1 as FormContentColumns,
    subModels: subModels
  };

  const close = (result: ClosingDetails) => {
    if (result.dataChanged || dataChanged) {
      onClose({dataChanged: true, action: result.action});
    }

    dialogContext.hideDialog();
  };

  return (
      <DynamicDialog open={true} maxWidth={"lg"} onClose={handleClose}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <FormAvatar iconWord="adb" ariaLabel="adb" />
            <DialogToolbarHeading header={t("titles.news_dialog")} subheader={t("titles.news_form")} />
            <ToolbarFillContent/>
            <DialogToolbarCreationDetails record={record} />
           
            {
            //   <DialogToolbarMenuButton>
            //   <DialogToolbarMenuItemActivate onClick={handleClose}/>
            //   <DialogToolbarMenuItemCopy onClick={handleClose}/>
            //   <DialogToolbarMenuItemDraft onClick={handleClose}/>
            // </DialogToolbarMenuButton>
            // <DialogToolbarButtonClose onClick={handleClose}/>
            }
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <GridContainer padding={2}>
            <FormContent
                //title={t("test_page.forms.sample_form_1.sections.extra")}
                {...commonProps}
            />
          </GridContainer>
        </DialogBody>
        <DialogActions>
          <DialogActionButtonClose variant="outlined" onClick={handleClose}/>
          {
            mode == "insert" ? 
            <DialogActionButtonAdd variant="contained" onClick={handleInsert}/>
            :
            <>
              <DialogActionButtonDelete variant="contained" onClick={handleDelete}/>
              <DialogActionButtonSave variant="contained" onClick={handleUpdate}/>
            </>
          }
          {

            // <DialogActionButtonCancel variant="contained" onClick={handleClose}/>
            // <DialogActionButtonConfirm variant="contained" onClick={handleClose}/>
            
          }
        </DialogActions>
      </DynamicDialog>
  );
}

export default NewsDialog;
