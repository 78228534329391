import React, { useContext, FunctionComponent, ComponentClass } from "react";
import { Navigate } from "react-router-dom";

import UserContext from "@/components/UserContext/UserContext";
import Loader from "@/ui/Loader/Loader";
import { authService } from "@/services/authService";

const AUTH_TOKEN = "auth_token";
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY

type PrivatePageProps = {
  children: ComponentClass<any,any> | FunctionComponent<any>
}

const PublicPrivatePage = (props: PrivatePageProps) => {
  const userContext = useContext(UserContext);

  const accessToken = localStorage.getItem(AUTH_TOKEN);
  const hasAccessToken = accessToken !== null && accessToken.length > 0 ? true : false;

  const knowsWhoAmI = userContext && userContext.username && userContext.username.length > 0 ? true : false;
  const isAuthenticated = hasAccessToken && knowsWhoAmI;

  if(!PUBLIC_KEY) {
    console.error('App could not find public key in environment variables')
    return null
  }

  if (hasAccessToken && !knowsWhoAmI) {
    authService
      .whoAmI()
      .then((user) => {
        if (user === undefined) {
          // localStorage.removeItem(AUTH_TOKEN);
          localStorage.setItem(AUTH_TOKEN,  PUBLIC_KEY)
          window.location.reload();
        } else {
          if (userContext !== null) {
            userContext.setUserData(user);
          }
        }
      })
      .catch((err) => {
        console.warn(err);
        // localStorage.removeItem(AUTH_TOKEN);
        localStorage.setItem(AUTH_TOKEN,  PUBLIC_KEY)
        window.location.reload();
      })
  } else if (!hasAccessToken && !knowsWhoAmI) {
    localStorage.setItem(AUTH_TOKEN,  PUBLIC_KEY)
    window.location.reload()
  }

  if (isAuthenticated && userContext?.userType === 1) {
    return <Navigate to={{ pathname: "/admin/map" }} />;
  }

  if (isAuthenticated) {
    return React.createElement(props.children);
  } else if (hasAccessToken && !knowsWhoAmI) {
    return <Loader open={true} />;
  } else {
    return <Navigate to={{ pathname: "/" }} />;
  }
};

export default PublicPrivatePage;
