import React, { useState, useEffect, useContext } from 'react'
import  {Box,Typography,Grid, List, ListItem,Link} from '@mui/material'

// Custom components
import Carousel from "./subcomponents/Carousel"
import CarouselBox from './subcomponents/CarouselLocationBox'
import OtpadCard from './subcomponents/OtpadCard'

// Images
import map from "../../images/map.png"
import jakovljesmece from "@/images/jakovlje-smece.jpg"

const rasporedUrl = "https://www.eko-flor.hr/images/kalendar_odvoza/2023/Op%C4%87ina_Jakovlje.pdf"


function BlockMap() {
    
    return (
        <Box display={"flex"} justifyContent={"center"} /*bgcolor={"rgba(222,224,103,0.3)"}*/>
            <Box textAlign={"left"} sx={{
                marginTop: {
                    xs: 5,
                    sm: 5,
                    md: 10,
                    lg: 10,
                },
                width:{
                    xs:"95%",
                    sm:"95%",
                    md:"80%",
                    lg:"70%",
                },
            }}>
                <Typography fontFamily={"Roboto slab"} fontWeight={700} fontSize={40} variant={"h3"}
                sx={{
                    fontSize: {
                        xs:"2rem",
                        sm:"2rem",
                        md:"2.3rem",
                        lg:"2.5rem",
                        xl:"2.8rem"
                    }
                }}
                >
                Odvoz otpada
                </Typography>
                <Box display={"flex"} mt={5} sx={{
                    flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "column",
                        lg: "row",
                        xl: "row",
                    },
                    justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "center",
                        lg: "space-between",
                        xl: "space-between",
                    }
                }}>
                    <Box sx={{
                        borderLeft: "5px solid #DEE067",
                        paddingLeft: 2,
                        paddingY: 1,
                        marginRight: 5,
                        fontSize: {
                            xs:"0.9rem",
                            sm:"0.9rem",
                            md:"1rem",
                            lg:"1.1rem",
                            xl:"1.1rem"
                        },
                        display:"flex",
                        flexDirection:"column",
                        height: "fit-content",
                    }}>
                        <List disablePadding>
                            <ListItem disablePadding sx={{
                                listStyleType: 'disc',
                                display: 'list-item',
                                marginLeft: 3
                            }}>
                                Odvoz <Box sx={{ display:"inline",fontWeight: 'bold' }}>miješanog otpada</Box> za sve korisnike usluga obavlja se <Box sx={{ display:"inline",fontWeight: 'bold' }}>četiri (4) puta mjesečno</Box>. 
                            </ListItem>
                            <ListItem disablePadding sx={{
                                listStyleType: 'disc',
                                display: 'list-item',
                                marginLeft: 3
                            }}>
                                Odvoz <Box sx={{ display:"inline",fontWeight: 'bold' }}>biorazgradivog komunalnog otpada</Box> za sve korisnike usluga koji ne kompostiraju u vlastitom kućanstvu, obavlja se <Box sx={{ display:"inline",fontWeight: 'bold' }}>jednom (1) tjedno</Box>. 
                            </ListItem>
                            <ListItem disablePadding  sx={{
                                listStyleType: 'disc',
                                display: 'list-item',
                                marginLeft: 3
                            }}>
                                Odvoz <Box sx={{ display:"inline",fontWeight: 'bold' }}>otpadni papir i karton iz biorazgradivog komunalnog otpada i reciklabilnog otpada</Box> za sve korisnike usluga obavlja se <Box sx={{ display:"inline",fontWeight: 'bold' }}>jednom (1) mjesečno</Box>.
                            </ListItem>
                        </List>
                        <Typography mt={3} variant='inherit'>Davatelj javne usluge u okviru javne usluge jednom u kalendarskoj godini preuzima <Box sx={{ display:"inline",fontWeight: 'bold' }}>krupni (glomazni) otpad</Box> od korisnika usluge na obračunskom mjestu korisnika usluge <Box sx={{ display:"inline",fontWeight: 'bold' }}>bez naknade</Box> u količini <Box sx={{ display:"inline",fontWeight: 'bold' }}>do najviše 5 m3 po korisniku.</Box></Typography>
                        <Typography variant='inherit' sx={{ mt: 2 }}>
                            Raspored odvoza otpada za područje općine Jakovlje možete preuzeti na  <Link sx={{"&:hover":{color:"#0004EE",cursor:"pointer",}}} color={"#0004EE"} href={rasporedUrl} target="_blank" rel="noreferrer">poveznici</Link>.
                        </Typography>
                    </Box>
                    <Box sx={{
                        marginTop:{
                            xs: 5,
                            sm: 5,
                            md: 5,
                            lg: 0,
                            xl: 0
                        },
                        display: "block",
                        marginX: {
                            xs: 5,
                            sm: 10,
                            md: 20,
                            lg: 0,
                            xl: 0
                        }
                    }}>
                        {<img src={jakovljesmece} width={"100%"} style={{aspectRatio:"4/3",
                            boxShadow: "10px 10px 0px rgba(222,224,103,0.2)"
                        }}></img> }
                    </Box>
                </Box>
                <Box marginX={"auto"} textAlign={"justify"} marginTop={5} borderRadius={"5px"} bgcolor={"rgba(222,224,103,0.6)"} width={"95%"}  padding={2} boxShadow={
                    "0 0 20px rgb(0 0 0 / 25%)"

                } sx={{
                    fontSize: {
                        xs:"0.9rem",
                        sm:"0.9rem",
                        md:"1rem",
                        lg:"1.1rem",
                        xl:"1.1rem"
                    }
                }}>
                    <Typography variant='inherit' sx={{ fontWeight: "bold" }}>NAPOMENA:</Typography>
                    <Typography variant='inherit' sx={{ mt: 1 }}>Za odvoz krupnog (glomaznog) otpada potrebno je <b>izvršiti prijavu najkasnije tri (3) radna dana</b> prije naprijed navedenog datuma na broj telefona 049/587-837 ili putem e-mail adrese: <Link sx={{"&:hover":{color:"#0004EE",cursor:"pointer",}}} color={"#0004EE"} href="mailto:glomazni@eko-flor.hr">glomazni@eko-flor.hr</Link>. Maksimalna količina glomaznog otpada koja se može preuzeti od korisnika je <b>5m3 po odvozu</b>, gdje <b>pravo na besplatni odvoz glomaznog otpada</b> imaju samo <b>korisnici iz kategorije kućanstva.</b></Typography>
                </Box>
            </Box>
        </Box>

    )
}


export default BlockMap