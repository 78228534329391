import React, { useState, useEffect, useContext } from 'react'
import  {Box,Typography} from '@mui/material'

// Contexts
import LoaderContext from "@/components/LoaderContext/LoaderContext";

// Custom components
import dataController from "@/lib/dataController";
import Carousel from "./subcomponents/Carousel"
import CarouselBox from './subcomponents/CarouselLocationBox'

// Models
import modelZeleniOtoci from "@/models/zeleniOtoci";

// Images
import image1 from "@/images/zeleni_otoci/otok1.jpg"
import image2 from "@/images/zeleni_otoci/otok2.jpg"
import image3 from "@/images/zeleni_otoci/otok3.jpg"
import image4 from "@/images/zeleni_otoci/otok4.jpg"
import image5 from "@/images/zeleni_otoci/otok5.jpg"
import image6 from "@/images/zeleni_otoci/otok6.jpg"

const images = [image1, image2, image3, image4, image5, image6];

// Types
import { DataResponse, DCRecord } from "@/@types/lib/dataController";


function BlockGreenIslands() {
    const dc = new dataController(modelZeleniOtoci);
    const loaderContext = useContext(LoaderContext);

    // STATES
    //---------------------------------------------------------------------------------------------------

    const [records, setRecords] = useState<any>([]);

    // FUNCTIONS
    //---------------------------------------------------------------------------------------------------

    const refreshRecords = () => {
        loaderContext?.toggleLoading(true);
        dc.GetData()
          .then((resp: DataResponse) => {
            if (resp.success && resp.data && Array.isArray(resp.data)) {
              setRecords(resp.data);
            }
          })
          .finally(() => {
            loaderContext?.toggleLoading(false);
          });
      };

    // EFFECTS
    //---------------------------------------------------------------------------------------------------

    useEffect(() => {
        refreshRecords();
      }, []);

    //RETURN
    //---------------------------------------------------------------------------------------------------

    return (
    <Box display={"flex"} justifyContent={"center"}>
        <Box textAlign={"left"} sx={{
            marginTop: {
                xs: 10,
                sm: 15,
                md: 20,
                lg: 20,
            },
            width:{
                xs:"95%",
                sm:"95%",
                md:"80%",
                lg:"70%",
            },
        }}>
            <Typography fontFamily={"Roboto slab"} fontWeight={700} fontSize={40} variant={"h3"}
            sx={{
                fontSize: {
                    xs:"2rem",
                    sm:"2rem",
                    md:"2.3rem",
                    lg:"2.5rem",
                    xl:"2.8rem"
                }
            
        }}
            >
                Informacije o zelenim otocima
            </Typography>
            <Carousel progressColor="rgb(99,208,202)" snap={false}>
                { records ? records.map((record: any, i: number) => {
                    const image = images[i % images.length];
                    return (
                        <CarouselBox
                            key={record.id}
                            record={record}
                            image={image}
                        />
                    )
                })
                : null }
            </Carousel>          
        </Box>
    </Box>
    )
}


export default BlockGreenIslands