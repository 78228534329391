import React, { useContext, SyntheticEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import DraggableDialog from "../../../ui/Dialog/DraggableDialog";
import DialogHeader from "../../../ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonConfirm from "@/ui/Dialog/ActionButtons/DialogActionButtonConfirm";
import DialogActionButtonCancel from "@/ui/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogActionButtonDelete from "@/ui/Dialog/ActionButtons/DialogActionButtonDelete";
import DialogActionButtonView from "@/ui/Dialog/ActionButtons/DialogActionButtonView";
import useFormController from "@/components/useFormController";
import GridContainer from "@/ui/Grid/GridContainer";
import FormContent from "@/components/FormContent";

import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import dataController from "@/lib/dataController";
import modelDumpsiteReport from "@/models/dumpsiteReport"
import { IDialogProps } from "@/@types/ui/DialogContext";
import { Typography } from "@mui/material";

function DumpsiteReportDialog(props: IDialogProps) {
    const { t } = useTranslation();
    const dialogContext = useContext(DialogContext);
    const snackbarContext = useContext(SnackbarContext);

    const { feature, mode, refreshLayers, dataId, onClose, form } = props;


    const dc = new dataController(modelDumpsiteReport)

    const close = (result: any) => {
        if (onClose) {
            onClose(result);
        }
    
        dialogContext.hideDialog();
    };

    const { record, validation, fields, onFieldChange, doDelete, doValidate } = useFormController({
        mode: mode,
        recordId: dataId,
        form: form,
        dc: dc,
        onClose: close,
    });

    const handleFieldChange = (value: any, source: string) => {

        if (onFieldChange) {
          onFieldChange(value, source);
        }
    };
    

    const handleClose = (evt: SyntheticEvent) => {
        close({ success: false });
    }

    const handleUpdate = () => {
        const recordId = record.id as number
        
        dc.UpdateRecord(recordId, record)
        .then((result) => {
            // @ts-ignore
            if (result.success) {
                snackbarContext.showNotification(t("dump_report.report_update_success"), "success");
                close({ success: true });
            } 
        })
    }

    const handleDelete = () => {
        doDelete()
        .then((result) => {
            if (result.success) {
            snackbarContext.showNotification(t("dump_report.report_delete_success"), "success");
            close({ dataChanged: true, action: "delete" });
            } else if (result.error) {
            snackbarContext.showNotification(t("dump_report.report_delete_error"), "error");
            }
        })
    }

    return (
        <DraggableDialog open={true} maxWidth={"sm"} onClose={handleClose}>
            <DialogHeader>
                <Toolbar variant="dense" disableGutters={true}>
                    <DialogToolbarHeading>{mode === 'insert' ? t("titles.add_dump_report"): t("titles.inspect_dump_report")}</DialogToolbarHeading>
                    <ToolbarFillContent />
                    <DialogToolbarButtonClose onClick={handleClose} />
                </Toolbar>
            </DialogHeader>
            <DialogBody>
                <Box m={2}>
                    <GridContainer>
                        <FormContent
                            fieldNames={dc.getFormFieldsNames(form)}
                            record={record}
                            validation={validation}
                            fields={fields}
                            onFieldChange={handleFieldChange}
                            mode={mode}
                            columns={1} 
                        />
                    </GridContainer>
                </Box>
                { mode === 'insert' ?
                    <Box m={2}>
                        <Typography fontSize={12} color={"#8B0000"}>
                            Molimo vas ukratko opišite lokaciju divljeg odlagališta otpada koje želite prijaviti.
                            Vaša email adresa biti će korištena samo kako bi vas mogli kontaktirati te neće biti dijeljena, prodana ili korištena u druge svrhe.
                        </Typography>
                    </Box>
                : null}
            </DialogBody>
            <DialogActions>
                { mode == "update" ? <DialogActionButtonDelete variant="contained" onClick={handleDelete} /> : null }
                { mode == "update" ? <DialogActionButtonConfirm variant="contained" onClick={handleUpdate} /> : null }
            </DialogActions>
        </DraggableDialog>
    )
}

export default DumpsiteReportDialog
