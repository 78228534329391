import React, { useContext } from "react";
import { useNavigate } from "react-router";


import AppPage from "@/ui/AppPage/AppPage";
import Box from '@mui/material/Box';
//Custom Components
import PhotoCentricPage from "@/views/PhotoCentric/PhotoCentricPage";
import modelObjekti from "@/models/dumpsiteReport";
import { Permissions as P } from "@/lib/permissions";
import UserContext from "@/components/UserContext/UserContext";

import { transform } from "ol/proj";

type ReportPageParams = {}

function ReportsPhotoCentricPage(props: ReportPageParams) {
  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  const handleCustomAction = (action: string, id: number, record?: { [key: string]: any }) => {
    let locationId: number | null = null;
    if (record && 'lokacija_id' in record) {
      locationId = record['lokacija_id'] as number;
    }

    switch (action) {
      case "show_object":
        if (id) {
          const locId = locationId === null ? '' : `&loc_id=${locationId}`;
          const url = location.origin + "/komunalne-obveze/?obj_id=" + id.toString() + "&open_first=true" + locId;
          window.open(url, "_blank");
        }
        break;
      case "show_3d":
        if (id) {
          const [cx, cy] = transform([record?.cx, record?.cy], "EPSG:3765", "EPSG:4326").map(x => x * Math.PI / 180)
          navigate("/3d", { state: { cx: cx, cy: cy, cz: record?.cz}})
        }
        break;
      case "show_photos":
        if (id) {
          const url = location.origin + "/fotodokumentacija-objekata/?rec_id=" + id.toString();
          window.open(url, "_blank");
        }
    }
  };

  return (
    <AppPage needPermission={[P.Administration]}>
      <Box m={0} style={{ height: "100%" }}>  
        <PhotoCentricPage
            titleToken={"titles.dump_reports_table"}
            model={modelObjekti}
            mapId={3}
            onCustomTableAction={handleCustomAction}
            viewName={"processing"}
            />
      </Box>
    </AppPage>
  );
}

export default ReportsPhotoCentricPage;
