import React, { useState, useEffect, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Map from "@/components/Map/Map";

// OpenLayers
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection, Projection } from "ol/proj";
import OlMapBrowserEvent from "ol/MapBrowserEvent";
import OlBaseLayer from "ol/layer/Base";
import OlLayerGroup from "ol/layer/Group";
import OlCollection from "ol/Collection";
import OlSourceVector from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import OlPolygon from "ol/geom/Polygon";
import OlPoint from "ol/geom/Point";
import OlFormatWKT from "ol/format/WKT";
import OlFeature from "ol/Feature";

// Custom
import {
  ButtonControl,
  Controls,
  RotateControl,
  ScaleLineControl,
  ZoomControl,
  ZoomToExtentControl
} from "@/components/Map/Controls";

import { Layers, TileLayer, VectorLayer, GroupLayer } from "@/components/Map/Layers";

import GeoBaseLayerSwitcher from "@/components/Map/Controls/GEO/GeoBaseLayerSwitcher";
import GeoPortalGSLayer from "@/views/GIS/MapPage/GeoPortalGSLayer";
import UserContext from "@/components/UserContext/UserContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import { padExtent } from "@/lib/olHelpers";
import { flattenLayers } from "@/lib/olHelpers";

//Services
import mapService from "@/services/mapService";
import gsService from "@/services/gsService";
//import objektiService from "@/services/objektiService";

import { recordStyle, selectedRecordStyle, hoveringRecordStyle } from "@/components/Map/mapStyles";
import { DCRecord } from "@/@types/lib/dataController";

//Types
import { UserContextType } from "@/@types/context/UserContext";
import { LoaderContextType } from "@/@types/context/LoaderContext";

type PhotoCentricMiniMapProps = {
  selectedObjekt: DCRecord | null;
  onObjektSelect: Function;
  _setObjExtent: (objExtent: number[]) => void;

  mapId: number
}

function PhotoCentricMiniMap(props: PhotoCentricMiniMapProps) {
  const userContext = useContext(UserContext) as UserContextType;
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const { t } = useTranslation();

  const [mapInitialized, setMapInitialized] = useState(false);
  
  //@ts-ignore
  const { selectedObjekt, onObjektSelect, _setObjExtent } = props;
  const { mapId } = props;
  
  const [defaultCoordinates, setDefaultCoordinates] = useState<null | number[]>(null)
  const [defaultExtent, setDefaultExtent] = useState(padExtent(userContext?.mapSettings?.default_extent
    ? userContext.mapSettings.default_extent
    : [1755716, 5767026, 1774061, 5775883]));

  const [selectedExtent, setSelectedExtent] = useState(padExtent(defaultExtent));
  const [objExtent, setObjExtent] = useState<number[]>([]);
  const [viewOptions, setViewOptions] = useState({
    center: userContext?.mapSettings ? userContext.mapSettings.initial_view_center : [1764889, 5771455] as [number, number],
    zoom: userContext?.mapSettings ? userContext.mapSettings.initial_view_zoom : 14,
    extent: userContext?.mapSettings ? userContext.mapSettings.max_extent : undefined,
    //projection: wgs84PM,
    minZoom: 8,
    maxZoom: 20,
    constrainResolution: true
  });
  const [layersCollection, setLayersCollection] = useState<OlCollection<OlBaseLayer> | undefined>(undefined);
  const [objektiSource, setObjektiSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [selectedSource, setSelectedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [highlightedSource, setHighlightedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [showDOFLayer, setShowDOFLayer] = useState(true);

  let highlightedFeature: OlFeature<Geometry> | null = null;

  //define proj
  proj4.defs(
    "EPSG:3765",
    "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
  proj4.defs(
    "EPSG:31276",
    "+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs"
  );
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );
  OlRegister(proj4);

  const htrs96:Projection = OlGetProjection("EPSG:3765") as Projection;
  const wgs84:Projection = OlGetProjection("EPSG:4326") as Projection;
  const wgs84PM:Projection = OlGetProjection("EPSG:3857") as Projection;

  const wkt = new OlFormatWKT();

  useEffect(() => {
    // getDefaultData();
    getLayers();
  }, []);

  useEffect(() => {

    // setObjektiSource(new OlSourceVector({}));
    setObjExtent(defaultExtent);
    setViewOptions(prevState => {
      return {
        ...prevState,
        center: userContext?.mapSettings?.initial_view_center || [1764889, 5771455],
        zoom: userContext?.mapSettings ? userContext.mapSettings.initial_view_zoom : 8,
      }
    });

    setSelectedSource(new OlSourceVector({}));
    // setHighlightedSource(new OlSourceVector({}));
  }, []);

  useEffect(() => {
    const src = new OlSourceVector({});
    if (selectedObjekt != null && selectedObjekt.wkt !== undefined) {
      let ft = wkt.readFeature(selectedObjekt.wkt, {
        dataProjection: htrs96,
        featureProjection: wgs84PM
      })
      if (ft) {
        ft.setId(selectedObjekt.id as number);
        src.addFeature(ft);
      }
    }
    setSelectedSource(src);
    
    setSelectedExtent(padExtent(src.getExtent()));
    if (src && selectedObjekt && selectedObjekt.wkt) {
      setObjExtent(padExtent(src.getExtent()));
      _setObjExtent(src.getExtent());
    }else if(selectedObjekt?.extent){
      //@ts-ignore
      setObjExtent(padExtent(selectedObjekt.extent));
    } 
  }, [selectedObjekt]);


  const getLayers = () => {
    mapService.getLayers().then((coll) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  // const getDefaultData = () => {
  //   mapService.getDefaultData().then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       setDefaultExtent(viewData.default_extent);
  //       setSelectedExtent(viewData.default_extent);
  //       setViewOptions((prevViewOptions) => ({
  //         ...prevViewOptions,
  //         ...{
  //           zoom: 14,
  //           center: viewData.initial_view_center
  //         }
  //       }));
  //       // this.setState((prevState) => {
  //       //   return {
  //       //     ...prevState,
  //       //     viewOptions: {
  //       //       ...prevState.viewOptions,
  //       //       zoom: viewData.initial_view_zoom,
  //       //       center: viewData.initial_view_center
  //       //     },
  //       //     zoomToExtent: viewData.default_extent,
  //       //     defaultExtent: viewData.default_extent
  //       //   };
  //       // });
  //     }
  //   });
  // };

  const handleClick = (evt: OlMapBrowserEvent<any>) => {

    loaderContext.toggleLoading(true);
    const allLayers = layersCollection
      ? flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup))
      : [];
    const GSLayerNames = allLayers.filter((x) => x.get("query") === true).map((x) => x.get("layer"));

    // ["v_objekti_projekt"] this was here before GSLayerNames - why? dunno..
    gsService.getFeatureInfo(evt.map, evt.pixel, GSLayerNames).then((resp) => {
      if (resp && Object.keys(resp).length != 0) {
        const layerKeys = Object.keys(resp);
        const features = resp[layerKeys[0]];
        if (Array.isArray(features) && features.length > 0) {
          const feat = features[0];
          const id = feat.properties.id;
          const rec = feat.properties as DCRecord;
          let geom;
          if (feat.geometry.type === "Point") {
            geom = new OlPoint(feat.geometry.coordinates as number[]);
          } else {
            geom = new OlPolygon(feat.geometry.coordinates as number[]);
          }
          const w = wkt.writeGeometry(geom, {
            dataProjection: htrs96,
            featureProjection: wgs84PM
          })
          rec.wkt = w;

          if (id ) {
            if (onObjektSelect) {
              onObjektSelect(id, rec);
            }
          } else {
            if (onObjektSelect) {
              onObjektSelect(null, null);
            }
          }
        }
      }
    })
    .finally(() => {
      loaderContext.toggleLoading(false);
    })
  };

  const handlePointerMove = (evt: OlMapBrowserEvent<any>) => {
    if (evt.dragging) {
      return;
    }
    var pixel = evt.map.getEventPixel(evt.originalEvent);
    var feature = evt.map.forEachFeatureAtPixel(
      pixel,
      function (feature: any) {
        return feature;
      },
      {
        layerFilter: (layer) => {
          const layerId = layer.get("id");
          return layerId !== null && layerId !== undefined && layerId.startsWith("objekti");
        }
      }
    ) as unknown as OlFeature<Geometry>;
    highlightFeature(feature);
  };

  const handleLayerSwitcherDOFClick = () => {
    setShowDOFLayer((prevState) => {
      return !prevState
    });
  };

  const highlightFeature = (feature: OlFeature<Geometry>) => {
    const olFeature = feature;
    if (olFeature !== highlightedFeature) {
      if (highlightedSource) {
        if (highlightedFeature) {
          highlightedSource.removeFeature(highlightedFeature);
        }
        if (olFeature) {
          highlightedSource.addFeature(olFeature);
        }
      }
      highlightedFeature = olFeature;
    }
  };

  return (
    <Fragment>
      {layersCollection ? (
        <Map
          isIdentifying={false}
          defaultCoordinates={defaultCoordinates}
          height="500px"
          view={viewOptions}
          onClick={handleClick}
          //onPointermove={handlePointerMove}
          className="map"
          id="mini-map"
          zoomToExtent={objExtent.length ? objExtent : selectedExtent}
          zoomToExtentPadding={[50, 50, 50, 50]}
          //disableExtentPadding={true}
          initialized={mapInitialized}
          
        >
          <Controls>
            <ZoomControl zoomInTipLabel={t("map:controls.zoom_in")} zoomOutTipLabel={t("map:controls.zoom_out")} />
            <RotateControl autoHide={false} />
            <ScaleLineControl />
            <GeoBaseLayerSwitcher />
            <ZoomToExtentControl
              id="zoom-extent-default"
              extent={defaultExtent}
              tipLabel={t("map:controls.zoom_to_extent")}
              className="ol-control ol-zoom-extent"
            />
            {/* <ZoomToExtentControl
              id="zoom-extent-selected"
              extent={selectedExtent}
              tipLabel={t("map:controls.zoom_to_selected")}
              className="ol-control ol-zoom-selected"
              //labelClass="fas fa-bullseye"
            /> */}
            {/* <ButtonControl
              id="layerswitcher-dof"
              className={`ol-control ol-layerswitcher-dof ${showDOFLayer ? "ol-active" : ""}`}
              html="<i class='fas fa-camera'></i>"
              handleClick={handleLayerSwitcherDOFClick}
              title={t("map:layerswitcher.toggle_dof")}
            /> */}
          </Controls>
          <Layers>
            {layersCollection ? (
              <>
                {layersCollection.getArray().map((layer: OlBaseLayer, i: number) => {

                  const id = layer.get("id");
                  const visible = id === "app-dof" ? showDOFLayer : true;

                  return (
                    <GeoPortalGSLayer
                      key={i}
                      id={id}
                      title={t(layer.get("title"))}
                      layer={layer}
                      query={layer.get("query")}
                      zIndex={layer.get("zIndex")}
                      visible={visible}
                    />
                  );
                })}
              </>
            ) : null}
            <VectorLayer id="selected" source={selectedSource} style={selectedRecordStyle} zIndex={950} />
            {/* <VectorLayer id="highlighted" source={highlightedSource} style={hoveringRecordStyle} zIndex={960} /> */}
          </Layers>
        </Map>
      ) : null}
    </Fragment>
  );
}

export default PhotoCentricMiniMap;
