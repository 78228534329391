import React, { Fragment } from 'react';
import { withTranslation } from "react-i18next";

import { Box, Typography } from '@mui/material';

import "./lists.css"

let i = 0;

let ind1 = 0;
let ind2 = 0;

const l1 = 'abcdefghijklmnopqrstuvwxyz'.split('');
const l2 = ["i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix", "x", "xi", "xii", "xiii", "xiv", "xv", "xvi", "xvii", "xviii", "xix", "xx", "xxi", "xxii", "xxiii", "xxiv", "xxv", "xxvi"];

class HtmlParser extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			renderDOM: null,
			lng: 'hr'
		}
	}

	componentDidMount() {
		const { htmlString } = this.props;
    this.setState({
    	renderDOM: this.parseHtml(htmlString)
    })
	}

  componentDidUpdate(prevProps, prevState) {
    const { i18n, htmlString } = this.props;
    if (prevState.lng !== i18n.language || prevProps.htmlString !== htmlString) {
    	this.setState({
        lng: i18n.language,
        renderDOM: this.parseHtml(htmlString)
      });
    }
  }

	parseHtml(htmlString, parent = null) {
		if (htmlString === undefined || htmlString === null) {
			return <Fragment key={++i} />
		}

    const regStrong = /<strong[^>]*>.*?<\/strong>/gi;
    const regSpan = 	/<span[^>]*>.*?<\/span>/gi;
    const regBQ = 		/<blockquote[^>]*>.*?<\/blockquote>/gi;
    const regBr = 		/<br>/gi;
    const regEm = 		/<em[^>]*>.*?<\/em>/gi;
    const regLi = 		/<li[^>]*>.*?<\/li>/gi;
    const regOl = 		/<ol[^>]*>.*?<\/ol>/gi;
    const regUl = 		/<ul[^>]*>.*?<\/ul>/gi;
    const regA = 			/<a[^>]*>.*?<\/a>/gi;
    const regP = 			/<p[^>]*>.*?<\/p>/gi;
    const regU = 			/<u[^>]*>.*?<\/u>/gi;

    const regSplit = /<p[^>]*>.*?<\/p>|<blockquote[^>]*>.*?<\/blockquote>|<ul[^>]*>.*?<\/ul>|<ol[^>]*>.*?<\/ol>|<li[^>]*>.*?<\/li>|.*?<strong[^>]*>.*?<\/strong>[^<]*|.*?<em[^>]*>.*?<\/em>[^<]*|.*?<u[^>]*>.*?<\/u>[^<]*|<br[^>]*>.*?<\/br>|<br[^>]*>|<span[^>]*>.*?<\/span>|.*?<a[^>]*>.*?<\/a>[^<]*/gi;
		if (!htmlString.match(regSplit)) {
			return(
				<Fragment key={++i}>
					{htmlString}
				</Fragment>
			)
		}
    return [...htmlString.match(regSplit)].map((row, i) => {
    	if (row.match(regP)) {
				return this.parseP(row);
			} else if (row.match(regBQ)) {
				return this.parseBQ(row);
			} else if (row.match(regUl)) {
				return this.parseUl(row);
			} else if (row.match(regOl)) {
				return this.parseOl(row);
			} else if (row.match(regLi)) {
				return this.parseLi(row,parent);
			} else if (row.match(regStrong)) {
				return this.parseStrong(row);
			} else if (row.match(regEm)) {
				return this.parseEm(row);
			} else if (row.match(regU)) {
				return this.parseU(row);
			} else if (row.match(regBr)) {
				return this.parseBr(row)
				return this.parseBr(row);
			} else if (row.match(regSpan)) {
				return this.parseSpan(row);
			} else if (row.match(regA)) {
				return this.parseA(row);
			} else {
				return(
					<Fragment key={++i}>
						{row}
					</Fragment>
				)
			}
    })


	}

	parseP (string) {
    const { title, table, pureParagraph } = this.props;
    const regBr = 		/<br>/gi;
    if (string.match(regBr)) {
    	return (
    		<br key={i++}/>
    	)
    }
		const replP = /<p[^>]*>|<\/p>/gi;
		const stringNew = string.split(replP);

    const variant = table ? "body2" : "body1";
		return(
			pureParagraph ? 
				<p>{this.parseHtml(stringNew[1])}</p>
			: title ?
				this.parseHtml(stringNew[1])
			:
				<Typography key={i++} component="p" paragraph={true} variant={variant}>
					{this.parseHtml(stringNew[1])}
				</Typography>
		)
			// 	<h2>
			// 	</h2>
			// :
			// titleH4 ?
			// 	<h4>
			// 	</h4>
	}

	parseBQ (string) {
		const replBQ = /<blockquote[^>]*>|<\/blockquote>/gi;
		const stringNew = string.split(replBQ);
		return(
			<Fragment key={i++}>
				{stringNew[0]}
				<blockquote>
					{this.parseHtml(stringNew[1])}
				</blockquote>
				{stringNew[2]}
			</Fragment>
		)
	}

	parseUl (string) {
		const replUl = /<ul[^>]*>|<\/ul>/gi;
		const stringNew = string.split(replUl);
		return(
			<Fragment key={i++}>
				{stringNew[0]}
				<ul>
					{this.parseHtml(stringNew[1], 'ul')}
				</ul>
				{stringNew[2]}
			</Fragment>
		)
	}

	parseOl (string) {
		const replOl = /<ol[^>]*>|<\/ol>/gi;
		const stringNew = string.split(replOl);
		ind1 = 0;
		ind2 = 0;
		return(
			<Fragment key={i++}>
				{stringNew[0]}
				<ol className="r">
					{this.parseHtml(stringNew[1], 'ol')}
				</ol>
				{stringNew[2]}
			</Fragment>
		)
	}

	parseLi (string, parent) {
		const regLiCls = /<li\s*class="ql-indent-|".*?>.*?<\s*\/.*?>/gi;
		const liClass = string.match(regLiCls) ? string.replace(regLiCls, '') : null;

		const replLi = /<li[^>]*>|<\/li>/gi;
		const stringNew = string.split(replLi);

		ind1 = liClass ? ind1 : 0;
		ind2 = liClass && liClass[0] === '1' ? 0 : ind2;
		return(
			<Fragment key={i++}>
				{stringNew[0]}
					{
						liClass ?
							parent === 'ul' ?
								<ul>
									{
										liClass[0] === '1' ?
											<li>
												{this.parseHtml(stringNew[1], 'ul')}
											</li>
										:
											<ul>
												<li>
													{this.parseHtml(stringNew[1], 'ul')}
												</li>
											</ul>
									}
								</ul>
							:
							parent === 'ol' ?
								<ol className="sub" key={i++}>
								{
									liClass[0] === '1' ?
											<li>
												{l1[ind1++]}
												)&nbsp;{this.parseHtml(stringNew[1], 'ol')}
											</li>
									:
										<ol className="sub">
											<li>
												{l2[ind2++]}
												)&nbsp;{this.parseHtml(stringNew[1], 'ol')}
											</li>
										</ol>
								}
								</ol>
							: null

						:
						<li key={i++}>
							{this.parseHtml(stringNew[1])}
						</li>
					}
				{stringNew[2]}
			</Fragment>
		)
	}

	parseStrong (string) {
		const replStrong = /<strong[^>]*>|<\/strong>/gi;
		const stringNew = string.split(replStrong);
		return(
			<Fragment key={i++}>
				{this.parseHtml(stringNew[0])}
				<strong>
					{this.parseHtml(stringNew[1])}
				</strong>
				{this.parseHtml(stringNew[2])}
			</Fragment>
		)
	}

	parseEm (string) {
		const { title } = this.props;
		const replEm = /<em[^>]*>|<\/em>/gi;
		const stringNew = string.split(replEm);
		return(
			<Fragment key={i++}>
        {this.parseHtml(stringNew[0])}
        <em>
          {this.parseHtml(stringNew[1])}
        </em>
        {this.parseHtml(stringNew[2])}
			</Fragment>
		)
	}

	parseU (string) {
		const replU = /<u[^>]*>|<\/u>/gi;
		const stringNew = string.split(replU);
		return(
			<Fragment key={i++}>
				{this.parseHtml(stringNew[0])}
				<u>
					{this.parseHtml(stringNew[1])}
				</u>
				{this.parseHtml(stringNew[2])}
			</Fragment>
		)
	}

	parseBr (string) {
		return(
			<br key={i++}></br>
		)
	}

	parseSpan (string) {
		const regSpanStyle = /<span\s*style="color:|;".*?>.*?<\s*\/.*?>/gi;
		const style = string.match(regSpanStyle) ? string.replace(regSpanStyle, '') : null;

		const replSpan = /<span[^>]*>|<\/span>/gi;
		const stringNew = string.split(replSpan);
		return(
			<Fragment key={i++}>
				{stringNew[0]}
				<span style={{color: style}}>
					{this.parseHtml(stringNew[1])}
				</span>
				{stringNew[2]}
			</Fragment>
		)
	}

	parseA (string) {
		const regAHref = /.*?<a\s*href="|".*/gi;
		let href = string.match(regAHref) ? string.replace(regAHref, '') : null;
		if (!href.match(/https?:\/\//gi)) {
			href = "https://" + href;
		}

		const replA = /<a[^>]*>|<\/a>/gi;
		const stringNew = string.split(replA);
		return(
			<Fragment key={i++}>
				{this.parseHtml(stringNew[0])}
				<a href={href} rel="noopener noreferrer" target="_blank">
					{this.parseHtml(stringNew[1])}
				</a>
				{this.parseHtml(stringNew[2])}
			</Fragment>
		)
	}

	render() {
		const { renderDOM } = this.state;
		const { wrapper } = this.props;
		const title = true;

		const WrapperEl = wrapper === "light" ? Fragment : Box

		return(
			<WrapperEl key={i++}>
				{ renderDOM }
			</WrapperEl>
		)
	}
}

export default withTranslation()(HtmlParser);