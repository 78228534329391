import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import GridContainer from "UI/Grid/GridContainer";

import DialogContext from "UI/DialogContext/DialogContext";
import ImageDialog from "@/components/ImageDialog";
import { Typography, Card } from '@mui/material';

const imageStyles = {
  width: "300px",
  height: "300px",
  border: "1px solid black",
  borderRadius: "5px",
  cursor: "pointer",
  padding: "10px",
  margin: "20px",
}

function ThumbnailImageSlider(props) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const [index, setIndex] = useState(1);

  const {images} = props;

  const handleOpenImageDialog = (image) => {

    dialogContext.showDialog(ImageDialog, {
      image: image,
      onClose: handleDialogClose
    })
  }

  const handleDialogClose = () => {
    dialogContext.hideDialog();
  }

  return (
    <Fragment>

      {
      
      images?.map((image, index) => (
        <img
        key={"image-" + index}
        src={`data:image/jpeg;base64, ${image.content}`}
        style={imageStyles}
        onClick={() => handleOpenImageDialog(image)}
      />

      ))}
    </Fragment>
  )

  // return (
  //   <Card sx={{ p: 4, mt: 2, bgcolor: "#ececec", width: "100%", height: "100%" }}>
  //     <GridContainer
  //       style={{
  //         display: "flex",
  //         flexDirection: "row",
  //         justifyContent: "start",
  //         alignItems: "center",
  //         flexWrap: "nowrap",

  //       }}
  //     >
  //       {images?.map((image, index) => (
  //         <img
  //           key={"image-" + index}
  //           src={`data:image/jpeg;base64, ${image.content}`}
  //           style={imageStyles}
  //           onClick={() => handleOpenImageDialog(image)}
  //         />
  //       ))}
  //     </GridContainer>
  //   </Card>
  // );
}

export default ThumbnailImageSlider;
