import React,{useState} from "react";

// MUI
import { Box,IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface ScrollDownButtonProps {
    first: boolean;
}

function ScrollDownButton(props :  ScrollDownButtonProps)
{ 
    const scrollDownHanlder = () =>
    {
        const currentScroll = window.scrollY;
        const windowHeight = window.innerHeight;

        const offsetFromSection = (currentScroll % windowHeight) ;

        const newScroll = scrollY + windowHeight;

        window.scrollTo({
            top: newScroll,
            behavior: "smooth", 
        })
    }

    return (
        <Box sx={{
            position:"absolute",
            bottom:{
                xs: props.first ? 60 : 15,
                sm: props.first ? 75 : 15,
                md:15,
            },
            left:{
                xs:"45%",
                sm:"47%",
                md:"50%",
            },
            textAlign:"center",
            
            }}>
            <IconButton 
            onClick={scrollDownHanlder}
            >
                <KeyboardArrowDownIcon sx={{
                    transform: "scale(5)",
                    color: "#DED857",
                }}/>
            </IconButton>
        </Box>
    )


}

export default ScrollDownButton;