import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, Divider, Button } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Contexts
import LoaderContext from "@/components/LoaderContext/LoaderContext";

// Custom components
import dataController from "@/lib/dataController";
import HtmlParser from "@/components/HtmlParser/HtmlParser"
import PublicPage from "@/ui/PublicPage/PublicPage";

// Models
import modelNews from "@/models/news";

// Types
import { DataResponse, DCRecord } from "@/@types/lib/dataController";

function NewsArticlePage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loaderContext = useContext(LoaderContext);
  const dc = new dataController(modelNews);

  // STATES
  //---------------------------------------------------------------------------------------------------

  const [record, setRecord] = useState<any>(null);
  const [image, setImage] = useState(null);

  // FUNCTIONS
  //---------------------------------------------------------------------------------------------------

  const refreshRecord = () => {
    loaderContext?.toggleLoading(true);
    dc.GetDataSingle(Number(id)).then((resp) => {
      if(resp && resp.data) {
        // @ts-ignore
        const record = resp.data as any
        const image = record.photos.length != 0 ? record.photos[0].content : null
        setRecord(record)
        setImage(image)
        
      } else {
        // setComments(null)
      }
    })
    .finally(() => {
      loaderContext?.toggleLoading(false);
    })
  }

  // EFFECTS
  //---------------------------------------------------------------------------------------------------

  useEffect(() => {
    refreshRecord();
  }, []);

  //RETURN
  //---------------------------------------------------------------------------------------------------

  return (
    <PublicPage contained>
      {record ? (
        <Box sx={{maxWidth: "1280px", mx: { xs: 3, sm: 0 }}}>
          <Button startIcon={<ArrowBackIcon />} onClick={() => navigate("/novosti")}>
            {t("buttons.news_back")}
          </Button>
          <Typography 
            variant="h2" 
            component="h1" 
            sx={{
              fontWeight: 700, 
              color: "#4B4C4D", 
              margin: "16px 0px",
              fontSize: {
                xs:"1.4rem",
                sm:"1.5rem",
                md:"1.6rem",
                lg:"2rem",
                xl:"2rem"
              }
            }}>
            <HtmlParser htmlString={record.title} title={true} />
          </Typography>
          <Divider />
          {image ? (
            <div style={{
              width: "fit-content",
              paddingBottom: "40px"
            }}>
            <figure
              style={{
                textAlign: "center",
                margin: 0
              }}
            >
              <img
                src={`data:image/jpeg;base64, ${image}`}
                alt={record.photos[0].name}
                style={{maxWidth: "100%"}}
                // className={classes.image}
              />
              {/* <figcaption>{article.caption}</figcaption> */}
            </figure>
            </div>
          ) : null}
          <Typography 
            variant="body1"  
            sx={{
              fontWeight: 700, 
              color: "#4B4C4D", 
              margin: "16px 0px",
              fontSize: {
                xs:"1.2rem",
                sm:"1.3rem",
                md:"1.4rem",
                lg:"1.8rem",
                xl:"1.8rem"
              }
            }}>
            <HtmlParser htmlString={record.content} />
          </Typography>
          {/* <GridContainer>
            <GridItem xs={6} style={{ textAlign: "end" }}>
              <Typography component="p">{moment(article.datum_i_vrijeme).format("DD.MM.YYYY.")}</Typography>
            </GridItem>
          </GridContainer> */}

          {/* {article.video_url ? (
            <iframe width={frameWidth} height={frameHeight} src={getValidHref(article.video_url)} />
          ) : null} */}
        </Box>
      ) : (
        null
      )}
    </PublicPage>
  )

}

export default NewsArticlePage