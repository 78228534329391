import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import GridContainer from "UI/Grid/GridContainer";

import DialogContext from "UI/DialogContext/DialogContext";
import ImageDialog from "@/components/ImageDialog";
import { Typography, Card } from '@mui/material';

const imageStyles = {
  width: "100px",
  height: "100px",
  borderRadius: "5px",
  cursor: "pointer"
}

function ImageSlider(props) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const [index, setIndex] = useState(1);

  const {images} = props;

  const handleOpenImageDialog = (image) => {

    dialogContext.showDialog(ImageDialog, {
      image: image,
      onClose: handleDialogClose
    })
  }

  const handleDialogClose = () => {
    dialogContext.hideDialog();
  }


  return (
    <Card sx={{ p: 4, mt: 2, bgcolor: "#ececec", width: "100%" }}>
      {/* <Typography sx={{ textAlign: 'center'}}>{t("dump_report.photos")}</Typography> */}
      <GridContainer
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "8px"
        }}
      >
        {images.map((image, index) => (
          <img
            key={"image-" + index}
            src={`data:image/jpeg;base64, ${image.content}`}
            style={imageStyles}
            onClick={() => handleOpenImageDialog(image)}
          />
        ))}
      </GridContainer>
    </Card>
  );
}

export default ImageSlider;
