import React from "react";

//Custom Components
import PublicPage from '@/ui/PublicPage/PublicPage'
import MainMap from "@/views/GIS/MapPage/MainMap";


function PublicMap() {

  return (
    <PublicPage>
        <MainMap/>
    </PublicPage>
  );
}

export default PublicMap;